import { IconProps } from '@/app/components/Icon';

export function UserCircleIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <circle
            cx='10'
            cy='10'
            r='7.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <circle cx='10' cy='8.5' r='1.75' />
          <path d='M14.8415 15.3966C13.8189 13.8047 13.0326 12.75 10 12.75C6.96738 12.75 6.1811 13.8047 5.15854 15.3966' />
        </>
      ) : (
        <path d='M11.1786 12.1788C10.4001 11.3023 9.26453 10.75 8 10.75C6.73547 10.75 5.59993 11.3023 4.82141 12.1788M11.1786 12.1788C12.4375 11.2197 13.25 9.70474 13.25 8C13.25 5.10051 10.8995 2.75 8 2.75C5.10051 2.75 2.75 5.10051 2.75 8C2.75 9.70474 3.56251 11.2197 4.82141 12.1788M11.1786 12.1788C10.2963 12.8509 9.19476 13.25 8 13.25C6.80524 13.25 5.7037 12.8509 4.82141 12.1788M9.25 7C9.25 7.69036 8.69036 8.25 8 8.25C7.30964 8.25 6.75 7.69036 6.75 7C6.75 6.30964 7.30964 5.75 8 5.75C8.69036 5.75 9.25 6.30964 9.25 7Z' />
      )}
    </>
  );
}
