import { IconProps } from '@/app/components/Icon';

export function NotionIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5376 0.553764L2.13149 1.32889C1.29241 1.40239 1 1.95603 1 2.61987V14.1322C1 14.6493 1.18227 15.0914 1.62063 15.6823L4.06682 18.8925C4.46858 19.4093 4.83376 19.52 5.60102 19.4829L17.6855 18.7448C18.7076 18.6714 19 18.1914 19 17.3796V4.42805C19 4.00844 18.8358 3.88727 18.352 3.52997C18.3253 3.51028 18.2977 3.48992 18.2691 3.46876L14.9474 1.1073C14.1442 0.517173 13.8154 0.442941 12.5376 0.553764ZM5.875 4.2156C4.88836 4.283 4.66419 4.29828 4.10388 3.83792L2.67909 2.6941C2.53378 2.54616 2.60665 2.36169 2.97151 2.32509L12.9758 1.58702C13.8153 1.51306 14.2534 1.80878 14.5822 2.0669L16.2981 3.32161C16.371 3.35835 16.5533 3.57942 16.3342 3.57942L6.00236 4.20693L5.875 4.2156ZM4.72419 17.2687V6.27329C4.72419 5.79373 4.87024 5.57219 5.30813 5.53496L17.1738 4.83412C17.5763 4.79721 17.7586 5.05566 17.7586 5.53496V16.4566C17.7586 16.9367 17.6854 17.3431 17.0279 17.3796L5.6733 18.0442C5.01619 18.0807 4.72419 17.8597 4.72419 17.2687ZM15.9335 6.86311C16.0063 7.19558 15.9335 7.52768 15.6043 7.56501L15.0571 7.6753V15.7928C14.5822 16.051 14.144 16.1987 13.7791 16.1987C13.1947 16.1987 13.0484 16.014 12.6106 15.4609L9.03206 9.77852V15.2764L10.1644 15.5348C10.1644 15.5348 10.1644 16.1987 9.25082 16.1987L6.73224 16.3464C6.65906 16.1987 6.73224 15.83 6.9877 15.7561L7.64491 15.5719V8.30276L6.73234 8.22879C6.65922 7.89637 6.84144 7.41711 7.35297 7.3799L10.0548 7.19569L13.7791 12.952V7.85978L12.8296 7.74954C12.7567 7.34315 13.0484 7.04812 13.4135 7.01147L15.9335 6.86311Z'
          fill='black'
          stroke='none'
        />
      ) : (
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.97367 0.652914L1.88005 1.25579C1.22743 1.31296 1 1.74356 1 2.25988V11.2139C1 11.6161 1.14177 11.96 1.48271 12.4195L3.3853 14.9163C3.69779 15.3184 3.98181 15.4044 4.57857 15.3756L13.9776 14.8015C14.7726 14.7444 15 14.3711 15 13.7397V3.66624C15 3.33988 14.8723 3.24564 14.496 2.96774C14.4752 2.95243 14.4538 2.93659 14.4315 2.92013L11.848 1.08344C11.2233 0.624454 10.9676 0.566718 9.97367 0.652914ZM4.79167 3.50101C4.02428 3.55343 3.84993 3.56531 3.41413 3.20726L2.30596 2.31762C2.19294 2.20256 2.24961 2.05907 2.53339 2.03061L10.3145 1.45656C10.9675 1.39903 11.3082 1.62904 11.5639 1.8298L12.8985 2.80568C12.9553 2.83426 13.097 3.0062 12.9266 3.0062L4.89072 3.49427L4.79167 3.50101ZM3.89659 13.6534V5.10144C3.89659 4.72845 4.01019 4.55614 4.35077 4.52718L13.5797 3.98208C13.8927 3.95338 14.0345 4.15439 14.0345 4.52718V13.0218C14.0345 13.3952 13.9776 13.7113 13.4661 13.7397L4.63479 14.2566C4.12371 14.285 3.89659 14.1131 3.89659 13.6534ZM12.6149 5.56018C12.6715 5.81877 12.6149 6.07707 12.3589 6.1061L11.9333 6.19189V12.5055C11.5639 12.7063 11.2231 12.8212 10.9393 12.8212C10.4848 12.8212 10.371 12.6776 10.0305 12.2473L7.24716 7.82773V12.1039L8.1279 12.3048C8.1279 12.3048 8.1279 12.8212 7.41731 12.8212L5.45841 12.9361C5.40149 12.8212 5.45841 12.5344 5.6571 12.4769L6.16826 12.3337V6.67991L5.45849 6.62238C5.40161 6.36383 5.54334 5.99108 5.9412 5.96213L8.04266 5.81885L10.9393 10.296V6.33537L10.2008 6.24963C10.1441 5.93355 10.371 5.70408 10.655 5.67557L12.6149 5.56018Z'
          fill='black'
          stroke='none'
        />
      )}
    </>
  );
}
