'use client';

import { Icon } from '@/app/components/Icon';
import { cva, cx } from 'cva';
import { motion } from 'framer-motion';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

const bannerVars = cva({
  variants: {
    type: {
      error: [
        '[--banner-bg-color:theme(colors.red.100)]',
        '[--banner-action-text-color:theme(colors.red.900)]',
        '[--banner-bars-color:var(--red-300)]',
      ],
      info: [
        '[--banner-bg-color:theme(colors.blue.100)]',
        '[--banner-action-text-color:theme(colors.blue.900)]',
        '[--banner-bars-color:var(--blue-300)]',
      ],
      notice: [
        '[--banner-bg-color:theme(colors.gray.1200)]',
        '[--banner-action-text-color:theme(colors.gray.400)]',
        '[--banner-text-color:theme(colors.gray.100)]',
        '[--banner-bars-color:var(--gray-1100)]',
      ],
      warning: [
        '[--banner-bg-color:theme(colors.orange.100)]',
        '[--banner-action-text-color:theme(colors.orange.900)]',
        '[--banner-bars-color:var(--orange-300)]',
      ],
    },
  },
});

export function Banner({
  type,
  message,
  actions,
}: {
  type: 'info' | 'warning' | 'error' | 'notice';
  message: React.ReactNode;
  actions: React.ReactNode;
}) {
  const scrollWidthRef = useRef<HTMLDivElement>(null);
  const [hiddenWidth, setHiddenWidth] = useState(0);

  const calculateHiddenWidth = useCallback(() => {
    if (scrollWidthRef.current) {
      const element = scrollWidthRef.current;
      const hiddenWidth = element.scrollWidth - element.offsetWidth;
      setHiddenWidth(hiddenWidth ? ((hiddenWidth + 8) / 16) * -1 : 0);
    }
  }, []);

  useLayoutEffect(() => {
    calculateHiddenWidth();
    window.addEventListener('resize', calculateHiddenWidth);

    return () => {
      window.removeEventListener('resize', calculateHiddenWidth);
    };
  }, [calculateHiddenWidth]);

  const iconName = useMemo(() => {
    switch (type) {
      case 'error':
      case 'info':
        return 'exclamation-circle';
      case 'warning':
      case 'notice':
        return 'exclamation-triangle';
    }
  }, [type]);

  return (
    <div
      className={cx(
        'relative flex h-10 items-center justify-center bg-[--banner-bg-color] pr-4 text-sm font-medium group/banner',
        bannerVars({ type }),
      )}
    >
      {/* Background and border bottom styling */}
      <div
        aria-hidden
        className='pointer-events-none absolute inset-0 opacity-50 [background:repeating-linear-gradient(-45deg,var(--banner-bars-color),var(--banner-bars-color)_2px,transparent_2px,transparent_4px)] [mask:linear-gradient(90deg,transparent,black,transparent)]'
      />
      <div
        aria-hidden
        className='absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-[--banner-action-text-color] to-transparent'
      />
      {/**/}

      <div className='relative mr-2 shrink-0 text-[--banner-action-text-color] max-md:hidden'>
        <Icon name={iconName} />
      </div>

      <div className='relative flex items-center gap-2 md:gap-1'>
        <div
          ref={scrollWidthRef}
          className={cx('flex-1 overflow-hidden', {
            '[mask:linear-gradient(90deg,black_calc(100%-theme(spacing.2)),transparent)]':
              hiddenWidth < 0,
          })}
        >
          <motion.div
            animate={{
              transform: ['translateX(0rem)', `translateX(${hiddenWidth}rem)`],
            }}
            transition={{
              duration: 5,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatType: 'reverse',
            }}
            className='whitespace-nowrap max-md:pl-4 text-[--banner-text-color] transition-opacity group-has-[.group\/action:hover]/banner:opacity-80'
          >
            {message}
          </motion.div>
        </div>
        <div className='group/action'>{actions}</div>
      </div>
    </div>
  );
}
