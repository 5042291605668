import { IconProps } from '@/app/components/Icon';

export function CodeBracketSquareIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <rect
            x='2'
            y='2'
            width='16'
            height='16'
            rx='3'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M9.25 2.75H4.75C3.64543 2.75 2.75 3.64543 2.75 4.75V15.25C2.75 16.3546 3.64543 17.25 4.75 17.25H15.25C16.3546 17.25 17.25 16.3546 17.25 15.25V10.75' />
          <path d='M8.25 7.75L5.75 10L8.25 12.25' />
          <path d='M11.75 7.75L14.25 10L11.75 12.25' />
          <circle fill='currentColor' stroke='none' cx='16' cy='4' r='2' />
        </>
      ) : (
        <>
          <path d='M6.75 6.75L5.25 8L6.75 9.25' />
          <path d='M9.25 6.75L10.75 8L9.25 9.25' />
          <path d='M2.75 4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H11.25C12.3546 2.75 13.25 3.64543 13.25 4.75V11.25C13.25 12.3546 12.3546 13.25 11.25 13.25H4.75C3.64543 13.25 2.75 12.3546 2.75 11.25V4.75Z' />
        </>
      )}
    </>
  );
}
