import { IconProps } from '@/app/components/Icon';

export function AppleIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          className='fill-black dark:fill-white'
          d='M16.5528 7.13681C16.4531 7.21782 14.6921 8.25653 14.6921 10.5662C14.6921 13.2377 16.9329 14.1827 17 14.2061C16.9897 14.2637 16.644 15.5004 15.8185 16.7606C15.0825 17.8695 14.3139 18.9766 13.1444 18.9766C11.975 18.9766 11.6741 18.2655 10.3241 18.2655C9.0086 18.2655 8.54085 19 7.4712 19C6.40155 19 5.6552 17.9739 4.79707 16.7137C3.80309 15.234 3 12.9352 3 10.7534C3 7.25383 5.17369 5.39784 7.31299 5.39784C8.4497 5.39784 9.39725 6.17912 10.1109 6.17912C10.7901 6.17912 11.8495 5.35103 13.1427 5.35103C13.6328 5.35103 15.3938 5.39784 16.5528 7.13681ZM12.5288 3.86949C13.0636 3.20522 13.442 2.28353 13.442 1.36184C13.442 1.23402 13.4317 1.10441 13.4093 1C12.5391 1.0342 11.5038 1.60666 10.8796 2.36453C10.3895 2.9478 9.93208 3.86949 9.93208 4.80378C9.93208 4.9442 9.95443 5.0846 9.96475 5.12961C10.0198 5.14041 10.1092 5.15301 10.1986 5.15301C10.9794 5.15301 11.9613 4.60576 12.5288 3.86949Z'
          stroke='none'
        />
      ) : (
        <>
          <path
            className='fill-black dark:fill-white'
            d='M13.1487 5.77308C13.0703 5.83608 11.6867 6.64397 11.6867 8.44037C11.6867 10.5182 13.4473 11.2532 13.5 11.2714C13.4919 11.3162 13.2203 12.2781 12.5717 13.2583C11.9934 14.1207 11.3895 14.9818 10.4706 14.9818C9.55182 14.9818 9.31539 14.4287 8.25468 14.4287C7.22104 14.4287 6.85352 15 6.01309 15C5.17264 15 4.58623 14.2019 3.91198 13.2218C3.131 12.0709 2.5 10.2829 2.5 8.58597C2.5 5.86409 4.2079 4.42054 5.88878 4.42054C6.78191 4.42054 7.52641 5.02821 8.08715 5.02821C8.62081 5.02821 9.4532 4.38414 10.4693 4.38414C10.8544 4.38414 12.238 4.42054 13.1487 5.77308ZM9.98693 3.23182C10.4071 2.71517 10.7044 1.9983 10.7044 1.28143C10.7044 1.18202 10.6963 1.08121 10.6787 1C9.99503 1.0266 9.18157 1.47185 8.69113 2.0613C8.30602 2.51495 7.94663 3.23182 7.94663 3.95849C7.94663 4.06771 7.96419 4.17691 7.97231 4.21192C8.01554 4.22032 8.0858 4.23012 8.15605 4.23012C8.7695 4.23012 9.54101 3.80448 9.98693 3.23182Z'
            stroke='none'
          />
        </>
      )}
    </>
  );
}
