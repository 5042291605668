import { IconProps } from '@/app/components/Icon';

export function MinusIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path d='M6.75 10H13.25' />
      ) : (
        <path d='M5.75 8H10.25' />
      )}
    </>
  );
}
