import { IconProps } from '@/app/components/Icon';

export function RocketIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M14.0769 11.504C14.1357 11.0188 14.1667 10.5157 14.1667 10C14.1667 5.99594 11.5625 2.75 10 2.75C8.4375 2.75 5.83333 5.99594 5.83333 10C5.83333 10.5157 5.86427 11.0188 5.9231 11.504C4.59247 12.6436 3.75 14.3311 3.75 16.2143V17.25H4.77732C5.62363 17.25 6.35257 16.6978 6.80938 15.9854C6.93746 15.7856 7.07672 15.5936 7.22627 15.4102C7.96294 16.5545 8.93479 17.25 10 17.25C11.0652 17.25 12.0371 16.5545 12.7737 15.4102C12.9233 15.5936 13.0625 15.7856 13.1906 15.9854C13.6474 16.6978 14.3764 17.25 15.2227 17.25H16.25V16.2143C16.25 14.3311 15.4075 12.6436 14.0769 11.504Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <circle cx='10' cy='9' r='1.25' />
        </>
      ) : (
        <>
          <path d='M11.424 9.08867C11.4734 8.73744 11.4994 8.37327 11.4994 8C11.4994 5.10051 9.31187 2.75 7.99937 2.75C6.68687 2.75 4.49937 5.10051 4.49937 8C4.49937 8.37357 4.52538 8.73802 4.57484 9.08951C3.45743 9.91474 2.75 11.1366 2.75 12.5V13.25H3.46008C4.27016 13.25 4.97243 12.7319 5.49529 12.1131C5.55139 12.0467 5.60956 11.9817 5.66971 11.9181C6.28849 12.7465 7.10473 13.25 7.99937 13.25C8.89428 13.25 9.71074 12.7462 10.3296 11.9174C10.39 11.9812 10.4484 12.0465 10.5047 12.1131C11.0276 12.7319 11.7298 13.25 12.5399 13.25H13.25V12.5C13.25 11.1361 12.5421 9.91391 11.424 9.08867Z' />
          <circle cx='8' cy='7' r='1' fill='currentColor' stroke='none' />
        </>
      )}
    </>
  );
}
