import {
  Instance,
  InstanceKey,
  OrganizationSettings,
  OrganizationSettingsProps,
} from '@/app/api/types/instances';
import {
  CreateMembershipParams,
  ListOrganizationMembershipResponse,
} from '@/app/api/types/organizationMemberships';
import { ListOrganizationResponse } from '@/app/api/types/organizations';
import { Template } from '@/app/api/types/templates';
import {
  PartialUserSettings,
  UserSettings,
} from '@/app/api/types/userSettings';
import {
  EmailAddress,
  ListUsersBffResponse,
  PhoneNumber,
  User,
  UserUpdateParams,
} from '@/app/api/types/users';
import { request } from '@/app/utils/http';
import { ListOrganizationRolesResponse } from './types/organizationRoles';
import { GetTokenOptions, Options } from './types/utils';
import { queryOptions } from '@tanstack/react-query';
import {
  LatestActivityResponse,
  MonthlyMetricsResponse,
  UserActivityResponse,
} from '@/app/api/types/analytics';
import { DomainStatusResponse } from '@/app/api/types/domains';
import { UserSessionsResponse } from '@/app/api/types/Session';
import { ApiKeysResponse } from '@/app/api/types/apiKeys';
import { OrganizationInvitationListResponse } from '@/app/api/types/organizationsInvitations';

export const getInstance = async (instanceId: string, { token }: Options) =>
  await request<Instance>({ path: `/v1/instances/${instanceId}`, token });
export const instanceQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () => {
      return getInstance(instanceId, { token: await getToken() });
    },
    queryKey: ['instance', instanceId],
  });
};

export const getUsers = async (
  instanceId: string,
  {
    // Page is 1-indexed as is our UI
    page,
    limit = 10,
    query = '',
    token,
  }: Options<{ page: number; limit?: number; query?: string }>,
) => {
  const searchParams = new URLSearchParams();
  searchParams.set('limit', String(limit));
  searchParams.set('offset', String((page - 1) * limit));
  if (query) {
    searchParams.set('query', query);
  }
  return request<ListUsersBffResponse>({
    path: `/v1/instances/${instanceId}/bff/users?${searchParams}`,
    token,
  });
};
export const usersQuery = (
  instanceId: string,
  {
    page,
    limit = 10,
    query = '',
    getToken,
  }: GetTokenOptions<{ page: number; limit?: number; query?: string }>,
) => {
  return queryOptions({
    queryFn: async () => {
      return getUsers(instanceId, {
        limit,
        page,
        query,
        token: await getToken(),
      });
    },
    queryKey: ['users', instanceId, page, limit, query],
  });
};

export const getOrganizationSettings = async (
  instanceId: string,
  { token }: Options,
) =>
  await request<OrganizationSettings>({
    path: `/v1/instances/${instanceId}/organization_settings`,
    token,
  });
export const organizationSettingsQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () => {
      return getOrganizationSettings(instanceId, { token: await getToken() });
    },
    queryKey: ['organizationSettings', instanceId],
  });
};

export const patchOrganizationSettings = async (
  instanceId: string,
  data: Partial<OrganizationSettingsProps>,
  { token }: Options,
) =>
  await request<OrganizationSettings>({
    data,
    method: 'PATCH',
    path: `/v1/instances/${instanceId}/organization_settings`,
    token,
  });

export const getOrganizationRoles = async (
  instanceId: string,
  { limit = 50, token }: Options<{ limit?: number }>,
) =>
  await request<ListOrganizationRolesResponse>({
    path: `/v1/instances/${instanceId}/organization_roles?limit=${limit}`,
    token,
  });
export const organizationRolesQuery = (
  instanceId: string,
  { limit, getToken }: GetTokenOptions<{ limit?: number }>,
) => {
  return queryOptions({
    queryFn: async () => {
      return getOrganizationRoles(instanceId, {
        token: await getToken(),
        limit,
      });
    },
    queryKey: ['organizationRoles', instanceId],
  });
};

export const specificUserUnaffiliatedOrganizationsQuery = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () => {
      return request<ListOrganizationResponse>({
        path: `/v1/instances/${instanceId}/organizations?query=&user_id=-${userId}&offset=0`,
        token: await getToken(),
      });
    },
    queryKey: ['userSpecificUnaffiliatedOrganizations', instanceId, userId],
  });
};

export const getUserSettings = async (instanceId: string, { token }: Options) =>
  await request<UserSettings>({
    path: `/v1/instances/${instanceId}/user_settings`,
    token,
  });
export const userSettingsQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () => {
      return getUserSettings(instanceId, { token: await getToken() });
    },
    queryKey: ['userSettings', instanceId],
  });
};

export const patchUserSettings = async (
  instanceId: string,
  data: Partial<PartialUserSettings>,
  { token }: Options,
) =>
  await request<UserSettings>({
    data,
    method: 'PATCH',
    path: `/v1/instances/${instanceId}/user_settings`,
    token,
  });

export const smsTemplatesQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () =>
      request<Template[]>({
        path: `/v1/instances/${instanceId}/templates/sms/all`,
        token: await getToken(),
      }),
    queryKey: ['smsTemplates', instanceId],
  });
};

export const createNewOrganizationMembership = async (
  instanceId: string,
  organizationId: string,
  data: {
    role: string;
    user_Id: string;
  },
  { getToken }: GetTokenOptions,
) =>
  await request<CreateMembershipParams>({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/organizations/${organizationId}/memberships`,
    token: await getToken(),
  });

export const postSMSTemplate = async (
  instanceId: string,
  data: Partial<Template>,
  { token }: Options,
) =>
  await request<Template>({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/templates/sms/${data.slug}/${data.enabled ? 'enable' : 'disable'}`,
    token,
  });

export const patchCommunication = async (
  instanceId: string,
  data: { blocked_country_codes: string[] },
  { token }: Options,
) =>
  await request<object>({
    data,
    method: 'PATCH',
    path: `/v1/instances/${instanceId}/communication`,
    token,
  });

export const userCountQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: async () =>
      request<{ object: string; total_count: number }>({
        method: 'GET',
        path: `/v1/instances/${instanceId}/users/count`,
        token: await getToken(),
      }),
    queryKey: ['userCount', instanceId],
    refetchInterval,
  });
};

export const userActivityQuery = (
  instanceId: string,
  activeMetric: string,
  interval: string,
  since: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () =>
      request<UserActivityResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/analytics/user_activity/${activeMetric}?since=${since}&interval=${interval}`,
        token: await getToken(),
      }),
    queryKey: ['userActivity', instanceId, activeMetric, interval, since],
  });
};

export const monthlyMetricsQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: async () =>
      request<MonthlyMetricsResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/analytics/monthly_metrics`,
        token: await getToken(),
      }),
    queryKey: ['monthlyMetrics', instanceId],
    refetchInterval,
  });
};

export const latestActivityQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: async () =>
      request<LatestActivityResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/analytics/latest_activity`,
        token: await getToken(),
      }),
    queryKey: ['latestActivity', instanceId],
    refetchInterval,
  });
};

export const domainStatusQuery = (
  instanceId: string,
  domainId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: async () =>
      request<DomainStatusResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/domains/${domainId}/status`,
        token: await getToken(),
      }),
    queryKey: ['domainStatus', instanceId, domainId],
    refetchInterval,
  });
};

export const instanceKeysQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: async () =>
      request<InstanceKey[]>({
        method: 'GET',
        path: `v1/instances/${instanceId}/instance_keys`,
        token: await getToken(),
      }),
    queryKey: ['instanceKeys', instanceId],
    refetchInterval,
  });
};

export const createInstanceKey = (
  instanceId: string,
  name: string,
  { token }: Options,
) => {
  return request<InstanceKey>({
    method: 'POST',
    path: `v1/instances/${instanceId}/instance_keys`,
    token,
    data: { name },
  });
};

export const deleteInstanceKey = (
  instanceId: string,
  id: string,
  { token }: Options,
) => {
  return request<InstanceKey>({
    method: 'DELETE',
    path: `v1/instances/${instanceId}/instance_keys/${id}`,
    token,
  });
};

export const apiKeysQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () =>
      request<ApiKeysResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/bff/api_keys`,
        token: await getToken(),
      }),
    queryKey: ['apiKeys', instanceId],
  });
};

/* Specific user CRUD operations
  ============================================ */

export const specificUserSettingsQuery = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () =>
      request<User>({
        path: `/v1/instances/${instanceId}/users/${userId}`,
        token: await getToken(),
      }),
    queryKey: ['userSpecificSettings', instanceId, userId],
  });
};

export const patchSpecificUserSettings = async (
  instanceId: string,
  userId: string,
  data: UserUpdateParams,
  { getToken }: GetTokenOptions,
) =>
  await request<User>({
    data,
    method: 'PATCH',
    path: `/v1/instances/${instanceId}/users/${userId}`,
    token: await getToken(),
  });

export const impersonateSpecificUser = async (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<{ location: string }>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/impersonate`,
    token: await getToken(),
  });

export const unlockSpecificUser = async (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<{ location: string }>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/unlock`,
    token: await getToken(),
  });

export const lockSpecificUser = async (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<{ location: string }>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/lock`,
    token: await getToken(),
  });

export const banSpecificUser = async (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<User>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/ban`,
    token: await getToken(),
  });

export const unbanSpecificUser = async (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<User>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/unban`,
    token: await getToken(),
  });

export const deleteSpecificUser = async (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<User>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}`,
    token: await getToken(),
  });

export const specificUserOrganizationMembershipsQuery = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () => {
      return request<ListOrganizationMembershipResponse>({
        path: `/v1/instances/${instanceId}/users/${userId}/organization_memberships?limit=${8000}`,
        token: await getToken(),
      });
    },
    queryKey: ['userSpecificOrganizationMemberships', instanceId, userId],
  });
};

export const deleteSpecificUserOrganizationMembership = async (
  instanceId: string,
  userId: string,
  organizationId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/organizations/${organizationId}/memberships/${userId}`,
    token: await getToken(),
  });

export const postSpecificUserEmail = async (
  instanceId: string,
  userId: string,
  data: {
    user_id: string;
    email_address: string;
    primary?: boolean;
    verified?: boolean;
    reserved_for_second_factor?: boolean;
  },
  { getToken }: GetTokenOptions,
) =>
  await request<Partial<EmailAddress>>({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/email_addresses`,
    token: await getToken(),
  });

export const patchSpecificUserEmail = async (
  instanceId: string,
  userId: string,
  emailAddressId: string,
  data: {
    primary?: boolean | null;
    verified?: boolean;
  },
  { getToken }: GetTokenOptions,
) =>
  await request<Partial<EmailAddress>>({
    data,
    method: 'PATCH',
    path: `/instances/${instanceId}/users/${userId}/email_addresses/${emailAddressId}`,
    token: await getToken(),
  });

export const deleteSpecificUserEmail = async (
  instanceId: string,
  userId: string,
  emailAddressId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/email_addresses/${emailAddressId}`,
    token: await getToken(),
  });

export const deleteSpecificUserProfileImage = async (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/profile_image`,
    token: await getToken(),
  });

export const deleteSpecificUserPasskey = async (
  instanceId: string,
  userId: string,
  passkeyIdentId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/passkeys/${passkeyIdentId}`,
    token: await getToken(),
  });

export const postSpecificUserPhoneNumber = async (
  instanceId: string,
  userId: string,
  data: {
    user_id: string;
    phone_number: string;
    primary?: boolean;
    verified?: boolean;
    reserved_for_second_factor?: boolean;
  },
  { getToken }: GetTokenOptions,
) =>
  await request<Partial<PhoneNumber>>({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/phone_numbers`,
    token: await getToken(),
  });

export const patchSpecificUserPhoneNumber = async (
  instanceId: string,
  userId: string,
  phoneNumberId: string,
  data: {
    primary?: boolean | null;
    verified?: boolean;
    reserved_for_second_factor?: boolean;
  },
  { getToken }: GetTokenOptions,
) =>
  await request<Partial<PhoneNumber>>({
    data,
    method: 'PATCH',
    path: `/instances/${instanceId}/users/${userId}/phone_numbers/${phoneNumberId}`,
    token: await getToken(),
  });

export const deleteSpecificUserPhoneNumber = async (
  instanceId: string,
  userId: string,
  phoneNumberId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/phone_numbers/${phoneNumberId}`,
    token: await getToken(),
  });
export const deleteUserWeb3Wallet = async (
  instanceId: string,
  userId: string,
  web3IdentId: string,
  { getToken }: GetTokenOptions,
) =>
  await request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/web3_wallets/${web3IdentId}`,
    token: await getToken(),
  });

export const userSessionsQuery = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: async () =>
      request<UserSessionsResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/users/${userId}/sessions?status=active`,
        token: await getToken(),
      }),
    queryKey: ['userSessions', instanceId, userId],
    refetchInterval,
  });
};

export const postRevokeUserSessions = async (
  instanceId: string,
  userId: string,
  sessionIds: string[],
  { getToken }: GetTokenOptions,
) =>
  await request({
    method: 'POST',
    // This call can take a single session or multiple
    path: `/v1/instances/${instanceId}/users/${userId}/sessions/revoke?${sessionIds.map((id, index) => `${index === 0 ? '' : '&'}id=${id}`).join('')}`,
    token: await getToken(),
  });

export const deleteUserTotp = async (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  await request({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/totp`,
    token: await getToken(),
  });

export const uploadUserProfileImage = async (
  instanceId: string,
  userId: string,
  file: File,
  { getToken }: GetTokenOptions,
) => {
  const formData = new FormData();
  formData.append('file', file);

  return await request<User>({
    data: formData,
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/profile_image`,
    token: await getToken(),
  });
};

export const listOrganizationInvitationsQuery = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
  pagination: { limit?: number; page?: number } = {},
) => {
  return queryOptions({
    queryFn: async () => {
      const queryParams = new URLSearchParams();
      if (pagination.limit) {
        queryParams.append('limit', pagination.limit.toString());
      }
      if (pagination.page) {
        queryParams.append(
          'offset',
          ((pagination.page - 1) * (pagination.limit || 10)).toString(),
        );
      }

      return request<OrganizationInvitationListResponse>({
        method: 'GET',
        path: `/v1/instances/${instanceId}/users/${userId}/organization_invitations?${queryParams.toString()}`,
        token: await getToken(),
      });
    },
    queryKey: ['organizationInvitations', instanceId, userId, pagination],
  });
};
