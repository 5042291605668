import { IconProps } from '@/app/components/Icon';

export function HubspotIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          d='M14.6069 6.92514V4.78254C15.1844 4.5116 15.5552 3.93405 15.5587 3.29591V3.246C15.5587 2.33334 14.8173 1.59538 13.9082 1.59538H13.8582C12.9456 1.59538 12.2076 2.33334 12.2076 3.246V3.29591C12.2112 3.93405 12.5819 4.5116 13.1595 4.78254V6.92514C12.343 7.04991 11.5766 7.3886 10.9349 7.90553L5.05611 3.32443C5.09889 3.1747 5.12027 3.01783 5.12385 2.86097C5.12385 1.83423 4.29318 1.00001 3.26644 1.00001C2.2397 0.996447 1.40548 1.82711 1.40192 2.85384C1.40192 3.88415 2.23257 4.71837 3.25931 4.71837C3.58374 4.71837 3.89745 4.62925 4.17553 4.46881L9.9652 8.97506C8.90281 10.5829 8.93133 12.6756 10.0365 14.2549L8.27535 16.0161C8.13632 15.9697 7.98659 15.9483 7.83685 15.9448C6.9955 15.9448 6.31101 16.6293 6.31101 17.4742C6.31101 18.3155 6.9955 19 7.83685 19C8.68177 19 9.36626 18.3155 9.36626 17.4742C9.3627 17.3245 9.33774 17.1747 9.29496 17.0321L11.0347 15.2924C12.5926 16.4902 14.7281 16.5935 16.393 15.5491C18.0578 14.508 18.8993 12.5402 18.5 10.615C18.1007 8.69341 16.5463 7.22104 14.6069 6.92514ZM13.8832 13.9768C13.0062 13.9982 12.1862 13.5454 11.7442 12.7896C11.2985 12.0338 11.2985 11.0998 11.7442 10.344C12.1862 9.58824 13.0062 9.13548 13.8832 9.15687C15.1809 9.20322 16.2076 10.2692 16.2076 11.5668C16.2111 12.8609 15.1844 13.9269 13.8867 13.9733'
          fill='#FF7A59'
          stroke='none'
        />
      ) : (
        <path
          d='M11.5831 5.60844V3.94197C12.0323 3.73124 12.3207 3.28204 12.3234 2.78571V2.74689C12.3234 2.03704 11.7467 1.46307 11.0397 1.46307H11.0008C10.291 1.46307 9.71696 2.03704 9.71696 2.74689V2.78571C9.71977 3.28204 10.0081 3.73124 10.4574 3.94197V5.60844C9.82233 5.70549 9.2262 5.96891 8.72709 6.37097L4.15471 2.80789C4.18798 2.69143 4.20462 2.56942 4.20739 2.44742C4.20739 1.64885 3.56132 1.00001 2.76275 1.00001C1.96417 0.997236 1.31533 1.6433 1.31256 2.44187C1.31256 3.24322 1.95863 3.89207 2.7572 3.89207C3.00953 3.89207 3.25354 3.82275 3.46982 3.69796L7.97289 7.20282C7.14659 8.45337 7.16877 10.081 8.02834 11.3094L6.65857 12.6792C6.55043 12.6431 6.43397 12.6264 6.31751 12.6237C5.66312 12.6237 5.13074 13.1561 5.13074 13.8133C5.13074 14.4676 5.66312 15 6.31751 15C6.97467 15 7.50705 14.4676 7.50705 13.8133C7.50428 13.6968 7.48487 13.5803 7.4516 13.4694L8.80473 12.1163C10.0165 13.0479 11.6774 13.1283 12.9723 12.3159C14.2672 11.5062 14.9216 9.97567 14.611 8.47832C14.3005 6.98376 13.0915 5.83858 11.5831 5.60844ZM11.0202 11.0931C10.3381 11.1097 9.70035 10.7576 9.35653 10.1697C9.00993 9.58188 9.00993 8.85542 9.35653 8.26759C9.70035 7.67974 10.3381 7.32759 11.0202 7.34423C12.0295 7.38028 12.8281 8.20936 12.8281 9.21866C12.8308 10.2252 12.0323 11.0542 11.023 11.0904'
          fill='#FF7A59'
          stroke='none'
        />
      )}
    </>
  );
}
