import React, { lazy, Suspense } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useInstance } from '@hooks/useInstance';
import { useUnsafeMetadata } from '@hooks/useUnsafeMetadata';
import type { ModalData } from './types';
import { PaymentRequiredContext } from '@context/PaymentRequired/PaymentRequiredContext';

const BillingModal = lazy(() =>
  import('@components/common/BillingModal').then(m => ({
    default: m.BillingModal,
  })),
);

const InfoBillingModal = lazy(() =>
  import('@components/common/InfoBillingModal').then(m => ({
    default: m.InfoBillingModal,
  })),
);

export function PaymentRequiredProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onUpgradeModalOpen,
    onClose: onUpgradeModalClose,
  } = useDisclosure();
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  const [modalInfo, setModalInfo] = React.useState<ModalData>(null);
  const { instance } = useInstance();
  const { shouldShowInfoModal } = useUnsafeMetadata();

  const isDevInstance = instance?.environment_type === 'development';

  const value: PaymentRequiredContext = React.useMemo(() => {
    return {
      showModal: params => {
        const {
          features,
          downgradeAction,
          downgradeTo,
          exceptionForUpgradeModal,
          callbackAfterClose,
          description,
        } = params || {};
        setModalInfo({
          description,
          features,
          downgradeAction,
          downgradeTo,
          exceptionForUpgradeModal,
          callbackAfterClose,
        });

        if (
          !isDevInstance ||
          shouldShowInfoModal(instance?.application_id) ||
          exceptionForUpgradeModal
        ) {
          onUpgradeModalOpen();
        }
      },
    };
  }, [onUpgradeModalOpen, isDevInstance, shouldShowInfoModal, instance]);

  // exceptionForUpgradeModal is used in order to handle the exception of showing the
  // billing modal we normally show in production, but in dev instances just before we create a prod instance
  const hasExceptionForUpgradeModal =
    isDevInstance && modalInfo?.exceptionForUpgradeModal;

  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  const hasSeenBillingModal = !shouldShowInfoModal(instance?.application_id);

  const showInfoBillingModal =
    isDevInstance &&
    !hasSeenBillingModal &&
    !modalInfo?.exceptionForUpgradeModal;

  return (
    <PaymentRequiredContext.Provider value={value}>
      {showInfoBillingModal && (
        <Suspense>
          <InfoBillingModal
            isOpen={isUpgradeModalOpen}
            onClose={onUpgradeModalClose}
            // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
            callbackAfterClose={modalInfo?.callbackAfterClose}
            description={modalInfo?.description}
          />
        </Suspense>
      )}

      {(!isDevInstance || hasExceptionForUpgradeModal) && (
        <Suspense>
          <BillingModal
            features={modalInfo?.features}
            downgradeAction={modalInfo?.downgradeAction}
            downgradeTo={modalInfo?.downgradeTo}
            isOpen={isUpgradeModalOpen}
            onClose={onUpgradeModalClose}
          />
        </Suspense>
      )}

      {children}
    </PaymentRequiredContext.Provider>
  );
}
