import { IconProps } from '@/app/components/Icon';

export function GithubIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          className='fill-[#1F2328] dark:fill-white'
          d='M10 1C5.0275 1 1 5.12937 1 10.2277C1 14.3109 3.57625 17.7597 7.15375 18.9824C7.60375 19.0631 7.7725 18.7863 7.7725 18.5441C7.7725 18.3249 7.76125 17.5982 7.76125 16.8254C5.5 17.2522 4.915 16.2602 4.735 15.7412C4.63375 15.4759 4.195 14.6569 3.8125 14.4378C3.4975 14.2647 3.0475 13.838 3.80125 13.8264C4.51 13.8149 5.01625 14.4954 5.185 14.7723C5.995 16.1679 7.28875 15.7758 7.80625 15.5335C7.885 14.9337 8.12125 14.53 8.38 14.2993C6.3775 14.0687 4.285 13.2728 4.285 9.74319C4.285 8.73968 4.63375 7.90919 5.2075 7.26326C5.1175 7.03257 4.8025 6.08674 5.2975 4.81794C5.2975 4.81794 6.05125 4.57571 7.7725 5.76377C8.4925 5.55615 9.2575 5.45234 10.0225 5.45234C10.7875 5.45234 11.5525 5.55615 12.2725 5.76377C13.9937 4.56418 14.7475 4.81794 14.7475 4.81794C15.2425 6.08674 14.9275 7.03257 14.8375 7.26326C15.4112 7.90919 15.76 8.72815 15.76 9.74319C15.76 13.2843 13.6562 14.0687 11.6537 14.2993C11.98 14.5877 12.2612 15.1414 12.2612 16.0065C12.2612 17.2407 12.25 18.2326 12.25 18.5441C12.25 18.7863 12.4187 19.0746 12.8687 18.9824C16.4237 17.7597 19 14.2993 19 10.2277C19 5.12937 14.9725 1 10 1Z'
          stroke='none'
        />
      ) : (
        <>
          <path
            className='fill-[#1F2328] dark:fill-white'
            d='M8 1C4.1325 1 1 4.21173 1 8.17707C1 11.3529 3.00375 14.0353 5.78625 14.9863C6.13625 15.0491 6.2675 14.8338 6.2675 14.6454C6.2675 14.4749 6.25875 13.9097 6.25875 13.3087C4.5 13.6406 4.045 12.8691 3.905 12.4654C3.82625 12.259 3.485 11.622 3.1875 11.4516C2.9425 11.317 2.5925 10.9851 3.17875 10.9761C3.73 10.9671 4.12375 11.4964 4.255 11.7118C4.885 12.7973 5.89125 12.4923 6.29375 12.3039C6.355 11.8374 6.53875 11.5234 6.74 11.3439C5.1825 11.1645 3.555 10.5455 3.555 7.80026C3.555 7.01975 3.82625 6.37382 4.2725 5.87142C4.2025 5.692 3.9575 4.95635 4.3425 3.96951C4.3425 3.96951 4.92875 3.78111 6.2675 4.70515C6.8275 4.54367 7.4225 4.46293 8.0175 4.46293C8.6125 4.46293 9.2075 4.54367 9.7675 4.70515C11.1062 3.77214 11.6925 3.96951 11.6925 3.96951C12.0775 4.95635 11.8325 5.692 11.7625 5.87142C12.2087 6.37382 12.48 7.01078 12.48 7.80026C12.48 10.5545 10.8437 11.1645 9.28625 11.3439C9.54 11.5682 9.75875 11.9988 9.75875 12.6717C9.75875 13.6316 9.75 14.4032 9.75 14.6454C9.75 14.8338 9.88125 15.0581 10.2312 14.9863C12.9962 14.0353 15 11.3439 15 8.17707C15 4.21173 11.8675 1 8 1Z'
            stroke='none'
          />
        </>
      )}
    </>
  );
}
