import { IconProps } from '@/app/components/Icon';

export function PasskeyAddedIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M12.9254 2.75C10.537 2.75 8.60088 4.68617 8.60088 7.07456C8.60088 7.3099 8.61968 7.54085 8.65585 7.76599L3.3891 12.6566C2.98156 13.035 2.75 13.566 2.75 14.1221V17.25H6.25V15.75C6.25 15.1977 6.69772 14.75 7.25 14.75H8L12.0201 11.3042C12.3121 11.3664 12.6149 11.3991 12.9254 11.3991C15.3138 11.3991 17.25 9.46295 17.25 7.07456C17.25 4.68617 15.3138 2.75 12.9254 2.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <circle cx='13' cy='7' r='1.25' />
          <path d='M13 15.75H16.5M14.75 14L14.75 17.5' />
        </>
      ) : (
        <path d='M10 11.5H11.75M11.75 11.5H13.5M11.75 11.5V9.75M11.75 11.5V13.25M13.25 6.25V5.88158C13.25 4.15206 11.8479 2.75 10.1184 2.75C8.3889 2.75 6.98684 4.15206 6.98684 5.88158C6.98684 6.052 7.00046 6.21924 7.02665 6.38227L3.21279 9.92371C2.91768 10.1977 2.75 10.5823 2.75 10.985V13.25H5.25V11.25H6.55172L9 9.31098M10 5.97998V5.99998' />
      )}
    </>
  );
}
