import { useUser } from '@clerk/nextjs';

export default function useClerkStaff() {
  const { user } = useUser();

  return (
    (user?.primaryEmailAddress?.emailAddress.endsWith('@clerk.dev') &&
      user?.primaryEmailAddress?.verification.status === 'verified') ||
    false
  );
}
