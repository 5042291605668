import { IconProps } from '@/app/components/Icon';

export function XTwitterIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            className='fill-black dark:fill-white'
            d='M15.175 1H17.9357L11.9057 8.62455L19 19H13.4458L9.0955 12.7062L4.11737 19H1.3555L7.80513 10.8441L1 1H6.69587L10.6277 6.75131L15.175 1ZM14.2075 17.1727H15.7375L5.86337 2.73173H4.22312L14.2075 17.1727Z'
            stroke='none'
          />
        </>
      ) : (
        <>
          <path
            className='fill-black dark:fill-white'
            d='M12.025 1H14.1722L9.48225 6.9302L15 15H10.6801L7.2965 10.1048L3.42462 15H1.2765L6.29288 8.65653L1 1H5.43013L8.48825 5.47324L12.025 1ZM11.2725 13.5788H12.4625L4.78262 2.3469H3.50687L11.2725 13.5788Z'
            stroke='none'
          />
        </>
      )}
    </>
  );
}
