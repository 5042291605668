import useClerkStaff from '@hooks/useClerkStaff';
import { useStaffModeStore } from './StaffModeStore';
import { useEffect, useState } from 'react';

export const UNRELEASED_FEATURES_FOR_STAFF_TESTING = [
  'color-scheme-toggle',
  'ceramic-sandbox',
  'api-keys-configure-api-version',
  'enterprise-connections-redesign',
  'stripe-billing',
  'new-checkout',
  'custom-oauth-providers',
  'dashboard-user-roles',
  'ios-application',
  'dashboard-delete-test-users',
  'commerce',
  'force-app-router',
  'invitations',
  'export-users',
] as const;

export type UnreleaseFeatureForStaffTesting =
  (typeof UNRELEASED_FEATURES_FOR_STAFF_TESTING)[number];

export default function StaffMode({
  children,
  feature,
}: {
  children: React.ReactNode;
  feature: UnreleaseFeatureForStaffTesting;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const isClerkStaff = useClerkStaff();
  const staffModeEnabled = useStaffModeStore(state => state.staffModeEnabled);

  const checkLoading = () => {
    if (isClerkStaff !== false && typeof staffModeEnabled !== undefined) {
      setIsLoading(false);
    }
  };

  useEffect(checkLoading, [isClerkStaff, staffModeEnabled]);

  if (isLoading || !isClerkStaff || !staffModeEnabled) {
    return null;
  }

  if (!feature || !UNRELEASED_FEATURES_FOR_STAFF_TESTING.includes(feature)) {
    throw new Error(
      'The "feature" prop is required and must be added to the list of features in the UNRELEASED_FEATURES_FOR_STAFF_TESTING.',
    );
  }

  return children;
}

export function useStaffMode({
  feature,
}: {
  feature: UnreleaseFeatureForStaffTesting;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const isClerkStaff = useClerkStaff();
  const staffModeEnabled = useStaffModeStore(state => state.staffModeEnabled);

  const checkLoading = () => {
    if (
      typeof isClerkStaff !== 'undefined' &&
      typeof staffModeEnabled !== 'undefined'
    ) {
      setIsLoading(false);
    }
  };

  useEffect(checkLoading, [isClerkStaff, staffModeEnabled]);

  if (!feature || !UNRELEASED_FEATURES_FOR_STAFF_TESTING.includes(feature)) {
    throw new Error(
      'The "feature" prop is required and must be added to the list of features in UNRELEASED_FEATURES_FOR_STAFF_TESTING.',
    );
  }

  return {
    staffModeLoading: isLoading,
    staffModeEnabled: isClerkStaff && staffModeEnabled && !isLoading,
  };
}
