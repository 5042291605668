import { IconProps } from '@/app/components/Icon';

export function LinearIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          d='M15.8829 16.7106C16.0565 16.5759 16.2137 16.422 16.3514 16.2521C19.8829 12.7954 19.8829 7.22232 16.3514 3.76562C12.8198 0.308922 7.12613 0.308922 3.59459 3.76562C3.45045 3.94198 3.30631 4.08307 3.16216 4.25943L15.8829 16.7106ZM14.8018 17.4866L2.36937 5.3176C2.11712 5.7056 1.9009 6.0936 1.72072 6.51687L13.5766 18.1215C14.009 17.9451 14.4054 17.7335 14.8018 17.4866ZM12.2072 18.58L1.25225 7.85722C1.10811 8.38631 1.03604 8.91539 1 9.44448L10.5856 18.8269C11.1261 18.7917 11.6667 18.7211 12.2072 18.58ZM8.6036 18.7564L1.07207 11.3845C1.36036 13.1834 2.18919 14.8764 3.59459 16.2873C5.03604 17.6277 6.8018 18.4742 8.6036 18.7564Z'
          fill='#222326'
          stroke='none'
        />
      ) : (
        <path
          d='M12.5756 13.2194C12.7106 13.1146 12.8329 12.9949 12.9399 12.8627C15.6867 10.1742 15.6867 5.83959 12.9399 3.15105C10.1932 0.462508 5.76476 0.462508 3.01802 3.15105C2.90591 3.28822 2.79379 3.39796 2.68168 3.53513L12.5756 13.2194ZM11.7347 13.8229L2.06507 4.35815C1.86887 4.65993 1.7007 4.9617 1.56056 5.29091L10.7818 14.3167C11.1181 14.1796 11.4264 14.015 11.7347 13.8229ZM9.71672 14.6734L1.1962 6.33341C1.08408 6.74492 1.02803 7.15643 1 7.56794L8.45546 14.8654C8.87588 14.838 9.2963 14.7831 9.71672 14.6734ZM6.91391 14.8105L1.05606 9.07682C1.28028 10.476 1.92492 11.7928 3.01802 12.8902C4.13914 13.9327 5.51251 14.5911 6.91391 14.8105Z'
          fill='#222326'
          stroke='none'
        />
      )}
    </>
  );
}
