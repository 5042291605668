import { IconProps } from '@/app/components/Icon';

export function CoinbaseIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          d='M10.0168 14.5C7.5306 14.5 5.51685 12.4862 5.51685 10C5.51685 7.51375 7.5306 5.5 10.0168 5.5C12.2443 5.5 14.0931 7.12375 14.4493 9.25H18.9831C18.6006 4.63 14.7344 1 10.0168 1C5.0481 1 1.01685 5.03125 1.01685 10C1.01685 14.9687 5.0481 19 10.0168 19C14.7344 19 18.6006 15.37 18.9831 10.75H14.4493C14.0931 12.8762 12.2443 14.5 10.0168 14.5Z'
          fill='#1652F0'
          stroke='none'
        />
      ) : (
        <>
          <path
            d='M8.01315 11.5197C6.07577 11.5197 4.50657 9.95053 4.50657 8.01315C4.50657 6.07577 6.07577 4.50657 8.01315 4.50657C9.7489 4.50657 11.1895 5.77187 11.4671 7.42872H15C14.7019 3.82863 11.6892 1 8.01315 1C4.14131 1 1 4.14131 1 8.01315C1 11.885 4.14131 15.0263 8.01315 15.0263C11.6892 15.0263 14.7019 12.1977 15 8.59756H11.4671C11.1895 10.2544 9.7489 11.5197 8.01315 11.5197Z'
            fill='#1652F0'
            stroke='none'
          />
        </>
      )}
    </>
  );
}
