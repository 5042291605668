import { IconProps } from '@/app/components/Icon';

export function MegaphoneIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.75 13.25V10.25V9.98453C14.8319 9.99474 14.9153 10 15 10C16.1046 10 17 9.10457 17 8C17 6.89543 16.1046 6 15 6C14.9153 6 14.8319 6.00526 14.75 6.01547V5.75V2.75L7.87631 4.50212C7.23053 4.66673 6.56671 4.75 5.90028 4.75H5.75C4.09315 4.75 2.75 6.09315 2.75 7.75V8.25C2.75 9.64788 3.70608 10.8225 5 11.1555V17H8V11.5294L14.75 13.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M4.75 11.0793V16.25C4.75 16.8023 5.19772 17.25 5.75 17.25H7.25C7.80228 17.25 8.25 16.8023 8.25 16.25V11.5931M14.75 5.75V2.75L7.87631 4.50212C7.23053 4.66673 6.56671 4.75 5.90028 4.75H5.75C4.09315 4.75 2.75 6.09315 2.75 7.75V8.25C2.75 9.90685 4.09315 11.25 5.75 11.25H5.90028C6.56671 11.25 7.23053 11.3333 7.87631 11.4979L14.75 13.25V10.25M14.75 5.75H15C16.2426 5.75 17.25 6.75736 17.25 8C17.25 9.24264 16.2426 10.25 15 10.25H14.75M14.75 5.75V10.25' />
        </>
      ) : (
        <>
          <path d='M11.25 8.25V11.25L4.26493 9.50373C4.1737 9.48093 4.08526 9.45206 4 9.41762C3.25315 9.11597 2.75 8.38715 2.75 7.56345V6.43655C2.75 5.51882 3.3746 4.71885 4.26493 4.49627L11.25 2.75V5.75M11.25 8.25H12C12.6904 8.25 13.25 7.69036 13.25 7C13.25 6.30964 12.6904 5.75 12 5.75H11.25M11.25 8.25V5.75M4.25 9.5V12.25C4.25 12.8023 4.69772 13.25 5.25 13.25H5.75C6.30228 13.25 6.75 12.8023 6.75 12.25V10.125' />
        </>
      )}
    </>
  );
}
