import { IconProps } from '@/app/components/Icon';

export function ArrowCompressIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M17.25 2.75L10.75 9.25M10.75 9.25L15.25 9.25M10.75 9.25L10.75 4.75' />
          <path d='M2.75 17.25L9.25 10.75M9.25 10.75L4.75 10.75M9.25 10.75L9.25 15.25' />
        </>
      ) : (
        <>
          <path d='M13.25 2.75L8.75 7.25M8.75 7.25L12.25 7.25M8.75 7.25V3.75M2.75 13.25L7.25 8.75M7.25 8.75L3.75 8.75M7.25 8.75L7.25 12.25' />
        </>
      )}
    </>
  );
}
