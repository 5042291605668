import { IconProps } from '@/app/components/Icon';

export function DoorIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M4.75 4.75V17.25H15.25V4.75C15.25 3.64543 14.3546 2.75 13.25 2.75H6.75C5.64543 2.75 4.75 3.64543 4.75 4.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M2.75 17.25H4.75M17.25 17.25H15.25M4.75 17.25V4.75C4.75 3.64543 5.64543 2.75 6.75 2.75H13.25C14.3546 2.75 15.25 3.64543 15.25 4.75V17.25M4.75 17.25H15.25' />
          <path d='M12.25 9.75H11.75' />
        </>
      ) : (
        <>
          <path d='M3.75 13.25V2.75H12.25V13.25M3.75 13.25H12.25M3.75 13.25H2.75M12.25 13.25H13.25M9.25 8H8.75' />
        </>
      )}
    </>
  );
}
