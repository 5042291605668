import { IconProps } from '@/app/components/Icon';

export function SsoConnectionsIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <rect
            x='2.75'
            y='2.75'
            width='14.5'
            height='14.5'
            rx='2.5'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.5 13.25V13.2501H7.5V13.25C6.5335 13.25 5.75 12.4665 5.75 11.5C5.75 10.5335 6.5335 9.75 7.5 9.75C7.58893 9.75 7.67631 9.75663 7.76167 9.76943C7.87713 8.6351 8.8352 7.75 10 7.75C11.1648 7.75 12.1229 8.6351 12.2383 9.76943C12.3237 9.75663 12.4111 9.75 12.5 9.75C13.4665 9.75 14.25 10.5335 14.25 11.5C14.25 12.4665 13.4665 13.25 12.5 13.25Z'
            fill='white'
          />
          <path
            d='M12.5 13.25V12.5C12.0858 12.5 11.75 12.8358 11.75 13.25H12.5ZM12.5 13.2501V14.0001C12.9142 14.0001 13.25 13.6643 13.25 13.2501H12.5ZM7.5 13.2501H6.75C6.75 13.6643 7.08579 14.0001 7.5 14.0001V13.2501ZM7.5 13.25H8.25C8.25 12.8358 7.91421 12.5 7.5 12.5V13.25ZM7.76167 9.76943L7.65046 10.5111C7.85327 10.5415 8.05969 10.4876 8.22166 10.3618C8.38364 10.236 8.48705 10.0494 8.50781 9.84538L7.76167 9.76943ZM12.2383 9.76943L11.4922 9.84538C11.513 10.0494 11.6164 10.236 11.7783 10.3618C11.9403 10.4876 12.1467 10.5415 12.3495 10.5111L12.2383 9.76943ZM11.75 13.25V13.2501H13.25V13.25H11.75ZM12.5 12.5001H7.5V14.0001H12.5V12.5001ZM8.25 13.2501V13.25H6.75V13.2501H8.25ZM5 11.5C5 12.8807 6.11929 14 7.5 14V12.5C6.94772 12.5 6.5 12.0523 6.5 11.5H5ZM7.5 9C6.11929 9 5 10.1193 5 11.5H6.5C6.5 10.9477 6.94772 10.5 7.5 10.5V9ZM7.87288 9.02772C7.75083 9.00942 7.62628 9 7.5 9V10.5C7.55158 10.5 7.60179 10.5038 7.65046 10.5111L7.87288 9.02772ZM10 7C8.44647 7 7.16955 8.18025 7.01552 9.69348L8.50781 9.84538C8.58471 9.08996 9.22393 8.5 10 8.5V7ZM12.9845 9.69348C12.8304 8.18025 11.5535 7 10 7V8.5C10.7761 8.5 11.4153 9.08996 11.4922 9.84538L12.9845 9.69348ZM12.5 9C12.3737 9 12.2492 9.00942 12.1271 9.02772L12.3495 10.5111C12.3982 10.5038 12.4484 10.5 12.5 10.5V9ZM15 11.5C15 10.1193 13.8807 9 12.5 9V10.5C13.0523 10.5 13.5 10.9477 13.5 11.5H15ZM12.5 14C13.8807 14 15 12.8807 15 11.5H13.5C13.5 12.0523 13.0523 12.5 12.5 12.5V14Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
        </>
      ) : (
        <>
          <path d='M2.75 4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H11.25C12.3546 2.75 13.25 3.64543 13.25 4.75V11.25C13.25 12.3546 12.3546 13.25 11.25 13.25H4.75C3.64543 13.25 2.75 12.3546 2.75 11.25V4.75Z' />
          <path d='M9.74394 7.77625C9.75821 7.68627 9.76562 7.594 9.76562 7.5C9.76562 6.5335 8.98212 5.75 8.01562 5.75C7.04913 5.75 6.26562 6.5335 6.26562 7.5C6.26562 7.59651 6.27344 7.69119 6.28846 7.78345C6.19586 7.76157 6.09928 7.75 6 7.75C5.30964 7.75 4.75 8.30964 4.75 9C4.75 9.69036 5.30964 10.25 6 10.25L10 10.2501C10.6904 10.2501 11.25 9.69036 11.25 9C11.25 8.30964 10.6904 7.75 10 7.75C9.91224 7.75 9.8266 7.75904 9.74394 7.77625Z' />
        </>
      )}
    </>
  );
}
