import { IconProps } from '@/app/components/Icon';

export function ArrowDownCircleIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <rect
            width='14.5'
            height='14.5'
            rx='7.25'
            transform='matrix(1 0 0 -1 2.75 17.25)'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M7.75 10.75L10 13.25M12.25 10.75L10 13.25M10 13.25V6.75' />
        </>
      ) : (
        <>
          <circle cx='8' cy='8' r='5.25' />
          <path d='M5.75 7.75L8 10.25M8 10.25L10.25 7.75M8 10.25V5.75' />
        </>
      )}
    </>
  );
}
