import { IconProps } from '@/app/components/Icon';

export function SpotifyIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          d='M1.00299 9.94495C1.00299 14.8727 5.07374 18.9435 10.0015 18.9435C14.9293 18.9435 19 14.8727 19 9.94495C19 5.01719 14.9293 1 10.0015 1C5.07374 1 1.00299 5.01719 1.00299 9.94495ZM13.3224 14.1228C11.2334 12.8374 8.55531 12.5159 5.44868 13.2122C4.6988 13.3194 4.59168 12.2481 5.23443 12.141C8.66243 11.3376 11.5548 11.7125 13.9115 13.1587C14.5007 13.5337 13.9115 14.4442 13.3224 14.1228ZM14.233 11.7125C11.8226 10.2128 8.12681 9.78425 5.28799 10.6413C4.37743 10.9091 4.05606 9.51645 4.91306 9.30219C8.18037 8.33806 12.1976 8.82013 14.9828 10.5341C15.7326 11.0162 14.9828 12.1946 14.233 11.7125ZM4.85949 7.90956C3.94893 8.23094 3.35974 6.67762 4.37743 6.30269C7.53761 5.33857 12.8939 5.49925 16.2148 7.48106C17.1253 7.96312 16.2683 9.46288 15.3042 8.92725C12.4118 7.21325 7.59118 7.05256 4.85949 7.90956Z'
          fill='#1DB954'
          stroke='none'
        />
      ) : (
        <path
          d='M1 7.95541C1 11.7888 4.16667 14.9554 8 14.9554C11.8333 14.9554 15 11.7888 15 7.95541C15 4.12207 11.8333 0.99707 8 0.99707C4.16667 0.99707 1 4.12207 1 7.95541ZM10.5834 11.2054C8.9583 10.2054 6.875 9.95539 4.45834 10.497C3.875 10.5804 3.79167 9.74707 4.29167 9.66377C6.95833 9.03874 9.20834 9.33043 11.0416 10.4554C11.5 10.7471 11.0416 11.4554 10.5834 11.2054ZM11.2917 9.33043C9.41666 8.16374 6.54167 7.8304 4.33333 8.49708C3.625 8.7054 3.375 7.62207 4.04167 7.4554C6.58333 6.7054 9.70835 7.08041 11.875 8.41371C12.4583 8.78877 11.875 9.70542 11.2917 9.33043ZM4 6.37207C3.29167 6.62207 2.83333 5.41373 3.625 5.12207C6.08333 4.37207 10.25 4.49707 12.8334 6.03874C13.5417 6.41373 12.875 7.5804 12.125 7.16373C9.87502 5.8304 6.125 5.7054 4 6.37207Z'
          fill='#1DB954'
          stroke='none'
        />
      )}
    </>
  );
}
