import { IconProps } from '@/app/components/Icon';

export function MoonIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M17.0001 12.706C16.0496 13.1575 14.9868 13.4101 13.8651 13.4101C9.81625 13.4101 6.53398 10.1185 6.53398 6.05807C6.53398 4.96692 6.77101 3.93128 7.19625 3.00012C4.71564 4.17865 3 6.71241 3 9.64801C3 13.7084 6.28227 17.0001 10.3312 17.0001C13.292 17.0001 15.8429 15.2399 17.0001 12.706Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M13 9.93087C12.3211 10.2533 11.562 10.4337 10.7608 10.4337C7.86876 10.4337 5.52428 8.08319 5.52428 5.1837C5.52428 4.4045 5.69359 3.66494 5.99735 3C4.22547 3.84156 3 5.65089 3 7.74718C3 10.6467 5.34448 12.9972 8.23654 12.9972C10.3514 12.9972 12.1734 11.7403 13 9.93087Z' />
        </>
      )}
    </>
  );
}
