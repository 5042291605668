import { IconProps } from '@/app/components/Icon';

export function GlobeIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <circle
            cx='10'
            cy='10'
            r='7.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M3 10H17' />
          <ellipse cx='10' cy='10' rx='3.25' ry='7.25' />
        </>
      ) : (
        <>
          <path d='M13.25 8C13.25 10.8995 10.8995 13.25 8 13.25C5.10051 13.25 2.75 10.8995 2.75 8M13.25 8C13.25 5.10051 10.8995 2.75 8 2.75C5.10051 2.75 2.75 5.10051 2.75 8M13.25 8H2.75' />
          <path d='M8 3C8 3 5.75 5 5.75 8C5.75 11 8 13 8 13' />
          <path d='M8 3C8 3 10.25 5 10.25 8C10.25 11 8 13 8 13' />
        </>
      )}
    </>
  );
}
