import { IconProps } from '@/app/components/Icon';

export function MicrosoftIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <g stroke='none'>
          <path d='M1 1H19V19H1V1Z' fill='#F3F3F3' />
          <path
            d='M1.78314 1.78223H9.60923V9.60831H1.78314V1.78223Z'
            fill='#F35325'
          />
          <path
            d='M10.3911 1.78223H18.2172V9.60831H10.3911V1.78223Z'
            fill='#81BC06'
          />
          <path
            d='M1.78314 10.391H9.60923V18.217H1.78314V10.391Z'
            fill='#05A6F0'
          />
          <path
            d='M10.3911 10.391H18.2172V18.217H10.3911V10.391Z'
            fill='#FFBA08'
          />
        </g>
      ) : (
        <g stroke='none'>
          <path d='M1 1H15V15H1V1Z' fill='#F3F3F3' />
          <path
            d='M1.60907 1.6084H7.69603V7.69535H1.60907V1.6084Z'
            fill='#F35325'
          />
          <path
            d='M8.3042 1.6084H14.3912V7.69535H8.3042V1.6084Z'
            fill='#81BC06'
          />
          <path
            d='M1.60907 8.3042H7.69603V14.3911H1.60907V8.3042Z'
            fill='#05A6F0'
          />
          <path
            d='M8.3042 8.3042H14.3912V14.3911H8.3042V8.3042Z'
            fill='#FFBA08'
          />
        </g>
      )}
    </>
  );
}
