import { IconProps } from '@/app/components/Icon';

export function EnvelopeOpenIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2.74878 7.09829L10 2.74756L17.2512 7.09829V15.25C17.2512 16.3546 16.3558 17.25 15.2512 17.25H4.74878C3.64421 17.25 2.74878 16.3546 2.74878 15.25V7.09829Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M2.74878 7.09829L10 2.74756L17.2512 7.09829M2.74878 7.09829V15.25C2.74878 16.3546 3.64421 17.25 4.74878 17.25H15.2512C16.3558 17.25 17.2512 16.3546 17.2512 15.25V7.09829M2.74878 7.09829L10 11.7253L17.2512 7.09829' />
        </>
      ) : (
        <path d='M2.75 5.9L8 2.75L13.25 5.9M2.75 5.9V12.25C2.75 12.8023 3.19772 13.25 3.75 13.25H12.25C12.8023 13.25 13.25 12.8023 13.25 12.25V5.9M2.75 5.9L8 9.25L13.25 5.9' />
      )}
    </>
  );
}
