import { IconProps } from '@/app/components/Icon';

export function UserCirclePlusIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <circle
            cx='10'
            cy='10'
            r='7.25'
            stroke='none'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M10.25 2.75H10C5.99594 2.75 2.75 5.99594 2.75 10C2.75 14.0041 5.99594 17.25 10 17.25C14.0041 17.25 17.25 14.0041 17.25 10V9.75' />
          <path d='M15.5 2.75V6.25M17.25 4.5L13.75 4.5' />
          <circle cx='10' cy='8.5' r='1.75' />
          <path d='M14.8414 15.3966C13.8189 13.8047 13.0326 12.75 9.99997 12.75C6.96735 12.75 6.18107 13.8047 5.15851 15.3966' />
        </>
      ) : (
        <>
          <path d='M11.1786 12.1788C10.4001 11.3023 9.26453 10.75 8 10.75C6.73547 10.75 5.59993 11.3023 4.82141 12.1788M11.1786 12.1788C12.4375 11.2197 13.25 9.70474 13.25 8V7.75M11.1786 12.1788C10.2963 12.8509 9.19476 13.25 8 13.25C6.80524 13.25 5.7037 12.8509 4.82141 12.1788M4.82141 12.1788C3.56251 11.2197 2.75 9.70474 2.75 8C2.75 5.10051 5.10051 2.75 8 2.75H8.25M9.25 7C9.25 7.69036 8.69036 8.25 8 8.25C7.30964 8.25 6.75 7.69036 6.75 7C6.75 6.30964 7.30964 5.75 8 5.75C8.69036 5.75 9.25 6.30964 9.25 7Z' />
          <path d='M12 2.75V5.25M13.25 4L10.75 4' />
        </>
      )}
    </>
  );
}
