import { GET, POST } from '@utils/api';
import { ExportUsersLogsResponse, ExportUsersResponse } from './types';

export const exportUserLogs = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<ExportUsersLogsResponse> => {
  return GET<ExportUsersLogsResponse>({ path, token });
};

export const requestExportUsers = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<ExportUsersResponse> => {
  return POST<ExportUsersResponse>({ path, token });
};
