import { IconProps } from '@/app/components/Icon';

export function SocialConnectionsIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M7.75 5C7.75 3.75736 8.75736 2.75 10 2.75C11.2426 2.75 12.25 3.75736 12.25 5C12.25 6.24264 11.2426 7.25 10 7.25C8.75736 7.25 7.75 6.24264 7.75 5Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M2.75 15C2.75 13.7574 3.75736 12.75 5 12.75C6.24264 12.75 7.25 13.7574 7.25 15C7.25 16.2426 6.24264 17.25 5 17.25C3.75736 17.25 2.75 16.2426 2.75 15Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M12.75 15C12.75 13.7574 13.7574 12.75 15 12.75C16.2426 12.75 17.25 13.7574 17.25 15C17.25 16.2426 16.2426 17.25 15 17.25C13.7574 17.25 12.75 16.2426 12.75 15Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M9 7L6 13M11 7L14 13' />
        </>
      ) : (
        <>
          <path d='M7.15 6.2L5.36875 9.7625M8.85 6.2L10.6312 9.7625M8 6.25C7.0335 6.25 6.25 5.4665 6.25 4.5C6.25 3.5335 7.0335 2.75 8 2.75C8.9665 2.75 9.75 3.5335 9.75 4.5C9.75 5.4665 8.9665 6.25 8 6.25ZM4.5 13.25C3.5335 13.25 2.75 12.4665 2.75 11.5C2.75 10.5335 3.5335 9.75 4.5 9.75C5.4665 9.75 6.25 10.5335 6.25 11.5C6.25 12.4665 5.4665 13.25 4.5 13.25ZM11.5 13.25C10.5335 13.25 9.75 12.4665 9.75 11.5C9.75 10.5335 10.5335 9.75 11.5 9.75C12.4665 9.75 13.25 10.5335 13.25 11.5C13.25 12.4665 12.4665 13.25 11.5 13.25Z' />
        </>
      )}
    </>
  );
}
