import { IconProps } from '@/app/components/Icon';

export function PhoneStarIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M6.25 17.25H13.75C14.5784 17.25 15.25 16.5784 15.25 15.75V4.25C15.25 3.42157 14.5784 2.75 13.75 2.75H6.25C5.42157 2.75 4.75 3.42157 4.75 4.25V15.75C4.75 16.5784 5.42157 17.25 6.25 17.25Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M11.25 2.75H6.25C5.42157 2.75 4.75 3.42157 4.75 4.25V15.75C4.75 16.5784 5.42157 17.25 6.25 17.25H13.75C14.5784 17.25 15.25 16.5784 15.25 15.75V10.75' />
          <path
            d='M13.75 5C15.5 5 15.5 2.75 15.5 2.75C15.5 2.75 15.5 5 17.25 5C15.5 5 15.5 7.25 15.5 7.25C15.5 7.25 15.5 5 13.75 5Z'
            fill='currentColor'
          />
        </>
      ) : (
        <path d='M9.25 4.25V3.75C9.25 3.19772 8.80229 2.75 8.25 2.75H3.75C3.19772 2.75 2.75 3.19772 2.75 3.75V12.25C2.75 12.8023 3.19772 13.25 3.75 13.25H8.25C8.80228 13.25 9.25 12.8023 9.25 12.25V11.75M11 5.75C11 5.75 11 8 8.75 8C11 8 11 10.25 11 10.25C11 10.25 11 8 13.25 8C11 8 11 5.75 11 5.75Z' />
      )}
    </>
  );
}
