import { IconProps } from '@/app/components/Icon';

export function StripeBillingIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M6 18H14C16.2091 18 18 16.2091 18 14V4C18 2.89543 17.1046 2 16 2H4C2.89543 2 2 2.89543 2 4V14C2 16.2091 3.79086 18 6 18Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path
            d='M9.39054 8.38241C9.39054 8.03959 9.69289 7.90768 10.1938 7.90768C10.9118 7.90768 11.8189 8.10993 12.537 8.47037V6.40438C11.7528 6.11429 10.978 6 10.1938 6C8.27559 6 7 6.9319 7 8.48799C7 10.9143 10.5905 10.5275 10.5905 11.5737C10.5905 11.978 10.2126 12.1099 9.68346 12.1099C8.89924 12.1099 7.89763 11.811 7.10391 11.4066V13.4989C7.98267 13.8506 8.87087 14 9.68346 14C11.6488 14 13 13.0946 13 11.5209C12.9906 8.90115 9.39054 9.36703 9.39054 8.38241Z'
            fill='currentColor'
            stroke='none'
          />
          <path d='M2.75 2.75V14.25C2.75 15.9069 4.09315 17.25 5.75 17.25H14.25C15.9069 17.25 17.25 15.9069 17.25 14.25V2.75' />
          <path d='M2.75 2.75H3.25' />
          <path d='M16.75 2.75H17.25' />
          <path d='M6.25 2.75H6.75' />
          <path d='M9.75781 2.75H10.2578' />
          <path d='M13.25 2.75H13.75' />
        </>
      ) : (
        <>
          <path
            d='M7.59369 6.78681C7.59369 6.52969 7.79526 6.43076 8.12918 6.43076C8.60788 6.43076 9.21259 6.58245 9.69133 6.85278V5.30329C9.16852 5.08572 8.65199 5 8.12918 5C6.85039 5 6 5.69892 6 6.86599C6 8.68571 8.39368 8.39564 8.39368 9.18028C8.39368 9.48352 8.14175 9.58245 7.78897 9.58245C7.26616 9.58245 6.59842 9.35825 6.06927 9.05497V10.6242C6.65511 10.8879 7.24725 11 7.78897 11C9.0992 11 10 10.3209 10 9.14069C9.99371 7.17586 7.59369 7.52527 7.59369 6.78681Z'
            fill='currentColor'
            stroke='none'
          />
          <path d='M2.75 2.75V11.75C2.75 12.5784 3.42157 13.25 4.25 13.25H11.75C12.5784 13.25 13.25 12.5784 13.25 11.75V2.75' />
          <path d='M2.75 2.75H3.25' />
          <path d='M6.25 2.75H6.75' />
          <path d='M9.25 2.75H9.75' />
          <path d='M12.75 2.75H13.25' />
        </>
      )}
    </>
  );
}
