import React from 'react';

const SupportIcon = () => {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.6406 31.75C25.063 31.75 31.8906 24.9223 31.8906 16.5C31.8906 8.07766 25.063 1.25 16.6406 1.25C8.21828 1.25 1.39062 8.07766 1.39062 16.5C1.39062 24.9223 8.21828 31.75 16.6406 31.75ZM16.6406 24.75C21.197 24.75 24.8906 21.0563 24.8906 16.5C24.8906 11.9437 21.197 8.25 16.6406 8.25C12.0843 8.25 8.39062 11.9437 8.39062 16.5C8.39062 21.0563 12.0843 24.75 16.6406 24.75Z'
        className='fill-[#EEEEF0] sm:fill-purple-100'
      />
      <path
        d='M27.1406 6L22.6406 10.5M6.14062 6L10.6406 10.5M10.6406 22.5L6.14062 27M22.6406 22.5L27.1406 27M31.8906 16.5C31.8906 24.9223 25.063 31.75 16.6406 31.75C8.21828 31.75 1.39062 24.9223 1.39062 16.5C1.39062 8.07766 8.21828 1.25 16.6406 1.25C25.063 1.25 31.8906 8.07766 31.8906 16.5ZM24.8906 16.5C24.8906 21.0563 21.197 24.75 16.6406 24.75C12.0843 24.75 8.39062 21.0563 8.39062 16.5C8.39062 11.9437 12.0843 8.25 16.6406 8.25C21.197 8.25 24.8906 11.9437 24.8906 16.5Z'
        className='stroke-gray-900 group-hover:stroke-gray-1100 sm:stroke-purple group-hover:sm:stroke-purple'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SupportIcon;
