import { IconProps } from '@/app/components/Icon';

export function CoinbaseWalletIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <g stroke='none'>
          <circle cx='10' cy='10' r='9' fill='#0052FF' />
          <circle cx='10.0001' cy='9.99995' r='6.3' fill='white' />
          <rect
            x='7.96777'
            y='7.96765'
            width='4.06445'
            height='4.06445'
            rx='0.45'
            fill='#0052FF'
          />
        </g>
      ) : (
        <g stroke='none'>
          <circle cx='8' cy='8' r='7' fill='#0052FF' />
          <circle cx='8.0001' cy='7.99998' r='4.9' fill='white' />
          <rect
            x='6.41937'
            y='6.41931'
            width='3.16124'
            height='3.16124'
            rx='0.35'
            fill='#0052FF'
          />
        </g>
      )}
    </>
  );
}
