import { IconProps } from '@/app/components/Icon';

export function BubbleQuestionIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <rect
            x='2.75208'
            y='2.74548'
            width='14.5'
            height='14.5'
            rx='3'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M7.75 8.25C7.75 6.5 8.75736 5.75 10 5.75C11.2426 5.75 12.25 6.67341 12.25 7.8125C12.25 8.47011 11.9143 9.05584 11.3913 9.4335C10.7406 9.90347 10 10.4906 10 11.25M10 13.76V13.75' />
        </>
      ) : (
        <>
          <path d='M6.75 6.88612C6.75 5.91583 7.30853 5.5 7.99751 5.5C8.68648 5.5 9.24501 6.01198 9.24501 6.64355C9.24501 7.00816 9.05886 7.33291 8.76892 7.54231C8.61277 7.65508 8.44729 7.81637 8.3098 7.9972M7.99751 10.5V10.49M4.25208 13.2455H11.7521C12.5805 13.2455 13.2521 12.5739 13.2521 11.7455V4.24548C13.2521 3.41706 12.5805 2.74548 11.7521 2.74548H4.25208C3.42365 2.74548 2.75208 3.41706 2.75208 4.24548V11.7455C2.75208 12.5739 3.42365 13.2455 4.25208 13.2455Z' />
        </>
      )}
    </>
  );
}
