import { APIQueryHook } from '@typeDefs/API';
import { buildExportUsersPath } from './utils';
import { useQuery } from '@hooks/useQuery';
import { ExportUsersLogsResponse } from './types';
import { exportUserLogs } from './api';

export const useFetchExportUsersLogs: APIQueryHook<
  ExportUsersLogsResponse
> = () => {
  const path = buildExportUsersPath({
    action: '/user_export_logs',
  });
  return useQuery(path, exportUserLogs);
};
