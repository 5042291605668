import { IconProps } from '@/app/components/Icon';

export function SpeechBubbleIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M10 16.0791C14.0041 16.0791 17.25 13.0952 17.25 9.41453C17.25 5.73381 14.0041 2.75 10 2.75C5.99594 2.75 2.75 5.73381 2.75 9.41453C2.75 10.6496 3.1155 11.8063 3.75251 12.798C3.89707 13.0231 4.01618 13.2665 4.07671 13.5281C4.33375 14.639 4.40832 15.4234 3.802 16.3325C3.52979 16.7407 3.80517 17.3462 4.2784 17.237C5.10808 17.0456 5.91908 16.7554 6.62689 16.3478C7.23759 15.9961 7.95775 15.8439 8.64974 15.9637C9.08722 16.0394 9.53858 16.0791 10 16.0791Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M8 12.25C10.8995 12.25 13.25 10.1234 13.25 7.5C13.25 4.87665 10.8995 2.75 8 2.75C5.10051 2.75 2.75 4.87665 2.75 7.5C2.75 8.48105 3.07872 9.39263 3.64195 10.1495C3.59056 11.4286 3.29325 12.7218 2.75 13.25C3.98973 13.1352 5.33401 12.7494 6.22735 11.9724C6.7811 12.1521 7.37791 12.25 8 12.25Z' />
        </>
      )}
    </>
  );
}
