import { IconProps } from '@/app/components/Icon';

export function ThumbUpIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2.75 9.74997H5.75L8.75 2.75H9.25C10.3546 2.75 11.25 3.64543 11.25 4.75V8.74997L15.2523 8.73861C16.4495 8.73861 17.3785 9.78331 17.2386 10.9723L16.7092 15.4723C16.5907 16.4795 15.7371 17.2386 14.7229 17.2386L5.75 17.25H2.75V9.74997Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M5.75 9.74997H2.75V17.25H5.75M5.75 9.74997L8.75 2.75H9.25C10.3546 2.75 11.25 3.64543 11.25 4.75V8.74997L15.2523 8.73861C16.4495 8.73861 17.3785 9.78331 17.2386 10.9723L16.7092 15.4723C16.5907 16.4795 15.7371 17.2386 14.7229 17.2386L5.75 17.25M5.75 9.74997V17.25' />
        </>
      ) : (
        <>
          <path d='M5.25 7.74997H2.75V13.25H5.25M5.25 7.74997L7.09484 2.75H7.45691C8.25677 2.75 8.75 3.39842 8.75 4.19828V7.24997H11.8034C12.6704 7.24997 13.3431 7.8431 13.2418 8.70409L12.8584 11.9627C12.7726 12.6921 12.1545 13.25 11.4201 13.25H5.25M5.25 7.74997V13.25' />
        </>
      )}
    </>
  );
}
