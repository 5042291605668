import { IconProps } from '@/app/components/Icon';

export function ApplicationIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M10 17.25C14.0041 17.25 17.25 14.0041 17.25 10C17.25 5.99594 14.0041 2.75 10 2.75C5.99594 2.75 2.75 5.99594 2.75 10C2.75 14.0041 5.99594 17.25 10 17.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M8.75 5.75L13.25 13.25M11.25 5.75L6.75 13.25' />
          <path d='M6.75 11.25H9.25' />
          <path d='M12.5 11.25H13.25' />
        </>
      ) : (
        <>
          <path d='M8 13.25C10.8995 13.25 13.25 10.8995 13.25 8C13.25 5.10051 10.8995 2.75 8 2.75C5.10051 2.75 2.75 5.10051 2.75 8C2.75 10.8995 5.10051 13.25 8 13.25Z' />
          <path d='M7.13462 5.75L10.25 10.25M8.86538 5.75L5.75 10.25' />
          <path d='M5.75 8.25H10.25' />
        </>
      )}
    </>
  );
}
