import { IconProps } from '@/app/components/Icon';

export function TwitterIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          d='M19 4.41746C18.3383 4.71077 17.627 4.90896 16.8796 4.99854C17.6421 4.54192 18.2273 3.81735 18.5031 2.95484C17.7897 3.37817 16.9991 3.68575 16.1581 3.85064C15.4844 3.134 14.5251 2.6853 13.4628 2.6853C11.4239 2.6853 9.77014 4.33898 9.77014 6.37951C9.77014 6.66807 9.80344 6.9487 9.86527 7.21982C6.79498 7.06523 4.07507 5.59469 2.25333 3.36072C1.93624 3.90772 1.7539 4.54192 1.7539 5.21734C1.7539 6.49842 2.40633 7.62967 3.39648 8.29162C2.79082 8.27179 2.22163 8.10611 1.72377 7.82944V7.87701C1.72377 9.66626 2.99613 11.159 4.68627 11.4983C4.37551 11.5823 4.04969 11.6267 3.71357 11.6267C3.47575 11.6267 3.24347 11.6046 3.01834 11.5618C3.48843 13.0283 4.85196 14.097 6.46836 14.1255C5.20394 15.1164 3.61131 15.707 1.88154 15.707C1.58346 15.707 1.28936 15.6894 1 15.6555C2.63464 16.7042 4.57529 17.3147 6.66021 17.3147C13.4539 17.3147 17.1682 11.6878 17.1682 6.80759C17.1682 6.64904 17.164 6.48891 17.157 6.33036C17.8784 5.80873 18.5047 5.15947 18.9985 4.41984L19 4.41746Z'
          fill='#1DA1F2'
          stroke='none'
        />
      ) : (
        <path
          d='M15 3.65803C14.4853 3.88616 13.9321 4.0403 13.3508 4.10998C13.9439 3.75483 14.399 3.19127 14.6136 2.52043C14.0586 2.84969 13.4438 3.08892 12.7896 3.21716C12.2656 2.65978 11.5195 2.31079 10.6933 2.31079C9.10746 2.31079 7.82122 3.59698 7.82122 5.18406C7.82122 5.4085 7.84712 5.62677 7.89521 5.83764C5.50721 5.7174 3.39172 4.57365 1.97481 2.83612C1.72818 3.26156 1.58637 3.75483 1.58637 4.28015C1.58637 5.27655 2.09381 6.15641 2.86393 6.67126C2.39286 6.65584 1.95015 6.52698 1.56294 6.31178V6.34879C1.56294 7.74042 2.55255 8.90142 3.8671 9.16534C3.6254 9.23068 3.37198 9.26523 3.11056 9.26523C2.92558 9.26523 2.74492 9.24799 2.56982 9.2147C2.93544 10.3553 3.99597 11.1865 5.25317 11.2087C4.26973 11.9794 3.03102 12.4388 1.68564 12.4388C1.4538 12.4388 1.22505 12.4251 1 12.3987C2.27139 13.2144 3.78078 13.6892 5.40239 13.6892C10.6864 13.6892 13.5753 9.31273 13.5753 5.51702C13.5753 5.3937 13.572 5.26915 13.5665 5.14583C14.1277 4.74012 14.6147 4.23514 14.9988 3.65987L15 3.65803Z'
          fill='#1DA1F2'
          stroke='none'
        />
      )}
    </>
  );
}
