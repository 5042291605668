import { Application } from '@/app/api/types/applications';
import type { ReadonlyURLSearchParams } from 'next/navigation';

export const createQueryString = (
  searchParams: URLSearchParams,
  param: Record<string, unknown>,
) => {
  const params = new URLSearchParams(searchParams.toString());

  Object.entries(param).forEach(([key, value]) =>
    value === undefined ? params.delete(key) : params.set(key, String(value)),
  );

  return params.toString();
};

export const slugify = (str: string) => {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
};

export const baseUrl = (applicationId: string, instanceId: string) =>
  `/apps/${applicationId}/instances/${instanceId}`;

type ToProps = {
  page?: string | null;
  applicationId?: string;
  instanceId?: string;
  search?: ReadonlyURLSearchParams;
};
export const to = (
  pathname: string,
  { page, applicationId, instanceId, search }: ToProps,
) => {
  // Our URL structure is /apps/:appId/instances/:insId/:rest
  const [, appId, , insId, ...rest] = pathname.split('/').filter(Boolean);

  const application = applicationId ?? appId;
  const instance = instanceId ?? insId;

  if (!application) {
    return '/';
  }

  const pagePath =
    page &&
    // If we're going to the root, we don't need to add that slash
    (page === '/'
      ? ''
      : // If the path starts with a slash, we don't need to add another
        page.startsWith('/')
        ? page
        : `/${page}`);

  // If there's no page, we keep the rest of the path, if there's no rest, we keep it empty
  const restPath = rest.length > 0 ? `/${rest.join('/')}` : '';

  const url = `/apps/${application}/instances/${instance}${page ? pagePath : restPath}`;

  // Add search params to the URL
  const searchParams = new URLSearchParams();
  if (search) {
    search.forEach((value, key) => searchParams.append(key, value));
  }
  const searchString = searchParams.toString();

  return `${url}${searchString ? `?${searchString}` : ''}`;
};

export const findApplication = (
  applications: Application[],
  applicationId: string,
) => {
  // We have two possible cases:
  // 1. The applicationId is the id of the application
  // 2. The applicationId is a slug of name of the application
  return applications.find(
    app => app.id === applicationId || slugify(app.name) === applicationId,
  );
};

export const findInstance = (application: Application, instanceId: string) => {
  // We have two possible cases:
  // 1. The instanceId is the id of the instance
  // 2. The instanceId is the environment_type of the instance
  return (
    application?.instances.find(
      env => env.id === instanceId || env.environment_type === instanceId,
    ) ?? application?.instances[0]
  );
};

export const getParams = (path: string) => {
  const [, application, , instance, ...rest] = path.split('/').filter(Boolean);
  return { application, instance, rest: rest.join('/') };
};
