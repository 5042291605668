'use state';

import {
  useState,
  createContext,
  useContext,
  useRef,
  cloneElement,
  useEffect,
} from 'react';
import { Dialog, Heading, Modal, ModalOverlay } from 'react-aria-components';
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
} from 'framer-motion';
import { Icon } from '@/app/components/Icon';
import { cx } from 'cva';
import { useInView } from 'react-intersection-observer';

const MotionModal = motion(Modal);
const MotionModalOverlay = motion(ModalOverlay);

const easeInBounce = [0.1, 1.2, 0, 1];
const easeOut = [0.25, 0, 0, 1];

const DialogContext = createContext<((open: boolean) => void) | null>(null);

export function DialogNext(props: {
  isOpen?: boolean;
  title: React.ReactNode;
  description: React.ReactNode;
  children: React.ReactNode;
  trigger: React.ReactElement<{ onClick?: () => void }>;
  isDismissable?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const triggerWithProps = cloneElement(props.trigger, {
    onClick: () => {
      props.trigger.props.onClick?.();
      setIsOpen(true);
    },
  });

  return (
    <>
      {triggerWithProps}

      <AnimatePresence>
        {isOpen && (
          <MotionModalOverlay
            isOpen
            isDismissable={props.isDismissable ?? true}
            isKeyboardDismissDisabled={!(props.isDismissable ?? true)}
            onOpenChange={setIsOpen}
            className='fixed inset-0 grid place-items-end sm:place-items-center p-3 overflow-y-auto backdrop-blur bg-[radial-gradient(circle_at_50%_100%,theme(colors.black/0.5)_40%,theme(colors.black/0.7))] sm:bg-[radial-gradient(theme(colors.black/0.5)_40%,theme(colors.black/0.7))]'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { delay: 0.1 } }}
            transition={{ duration: 0.2 }}
          >
            <MotionModal
              className={cx(
                'mx-auto sm:max-w-[32rem] w-full rounded-3xl p-1 bg-white/15 shadow-[inset_0_0_0_1px_theme(colors.white/0.01)]',
                'sm:[--scale-from:96%] [--scale-to:100%] sm:[--scale-out:90%]',
                'max-sm:[--slide-from:calc(100%_+_24px)] [--slide-to:0%]',
                'sm:[--opacity-from:0] [--opacity-to:1] sm:[--opacity-out:0]',
                'sm:[--blur-from:2px] sm:[--blur-to:0] sm:[--blur-out:8px]',
              )}
              initial={{
                y: 'var(--slide-from, 0%)',
                scale: 'var(--scale-from, 100%)',
                opacity: 'var(--opacity-from, 1)',
                filter: 'blur(var(--blur-from, 0))',
              }}
              animate={{
                y: 'var(--slide-to)',
                scale: 'var(--scale-to)',
                opacity: 'var(--opacity-to)',
                filter: 'blur(var(--blur-to, 0))',
                transition: {
                  opacity: { delay: 0.1, duration: 0.1 },
                  filter: { delay: 0.1, duration: 0.1 },
                  delay: 0.1,
                  ease: easeInBounce,
                  duration: 0.4,
                },
              }}
              exit={{
                y: 'var(--slide-from, 0%)',
                scale: 'var(--scale-out, 100%)',
                opacity: 'var(--opacity-from, 1)',
                filter: 'blur(var(--blur-out, 0))',
                transition: {
                  ease: easeOut,
                  duration: 0.2,
                },
              }}
            >
              <DialogContext.Provider value={setIsOpen}>
                <Dialog className='bg-white rounded-xl relative flex flex-col shadow-lg shadow-black/10 isolate max-h-[80dvh] overflow-hidden'>
                  <Content title={props.title} description={props.description}>
                    {props.children}
                  </Content>

                  <button
                    onClick={() => setIsOpen(false)}
                    className={cx(
                      'absolute top-3 right-3 text-gray-900 cursor-pointer transition hover:bg-gray-300 hover:text-gray-1200 rounded p-1 z-20',
                      'focus:text-gray-1200 focus:ring-blue focus:ring-offset-2 focus:ring-2 focus:bg-gray-300',
                    )}
                  >
                    <Icon name='x' size='base' />
                  </button>
                </Dialog>
              </DialogContext.Provider>
            </MotionModal>
          </MotionModalOverlay>
        )}
      </AnimatePresence>
    </>
  );
}

function Content(props: {
  children: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
}) {
  const headerTextRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [headerHeight, setHeaderHeight] = useState<number>();
  const [previousWidth, setPreviousWidth] = useState<number>();

  const { scrollY } = useScroll({
    container: contentRef,
  });

  const { ref: topInViewDetector, inView: isTopDetectorInView } = useInView({
    initialInView: true,
  });

  useEffect(() => {
    const header = headerTextRef.current;

    if (!header) {
      return;
    }

    function updateHeight(forceUpdate = false) {
      if (header) {
        const currentWidth = header.offsetWidth;
        if (forceUpdate || currentWidth !== previousWidth) {
          setHeaderHeight(header.offsetHeight);
          setPreviousWidth(currentWidth);
        }
      }
    }

    setPreviousWidth(header.offsetWidth);
    updateHeight(true);

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        updateHeight(width !== previousWidth);
      }
    });

    resizeObserver.observe(header);

    return () => {
      resizeObserver.disconnect();
    };
  }, [headerTextRef, previousWidth]);

  const SCROLL_THRESHOLD = 100;

  const paddingTop = useTransform(scrollY, [0, SCROLL_THRESHOLD], [36, 14]);
  const paddingBottom = useTransform(scrollY, [0, SCROLL_THRESHOLD], [24, 14]);
  const height = useTransform(
    scrollY,
    [0, SCROLL_THRESHOLD],
    [headerHeight, 23],
  );
  const opacity = useTransform(scrollY, [0, SCROLL_THRESHOLD], [1, 0]);
  const y = useTransform(scrollY, [0, SCROLL_THRESHOLD], [0, -6]);
  const blur = useTransform(
    scrollY,
    [0, SCROLL_THRESHOLD],
    ['blur(0px)', 'blur(4px)'],
  );

  return (
    <>
      <motion.header
        className={cx('border-b px-6 flex-none z-10 bg-inherit transition', {
          'border-transparent duration-100': isTopDetectorInView,
          'shadow-sm shadow-black/1 bg-clip-padding': !isTopDetectorInView,
        })}
        style={{
          paddingTop,
          paddingBottom,
        }}
      >
        <motion.div style={{ height }}>
          <div ref={headerTextRef}>
            <Heading
              slot='title'
              className={cx('font-semibold text-primary text-xl text-center')}
            >
              {props.title}
            </Heading>

            <motion.p
              style={{ opacity, y, filter: blur }}
              className='pt-0.5 text-gray-1100 text-center'
            >
              {props.description}
            </motion.p>
          </div>
        </motion.div>
      </motion.header>

      <div
        ref={contentRef}
        className='px-6 pb-6 relative overflow-y-auto rounded-inherit flex-1 min-h-0'
      >
        <div ref={topInViewDetector} />
        {props.children}
      </div>
    </>
  );
}

function DialogAction(props: {
  children: React.ReactElement<{ onClick?: (...args: any[]) => void }>;
}) {
  const setOpen = useContext(DialogContext);
  if (!setOpen) {
    throw new Error('DialogAction must be used within a DialogNext component');
  }

  const childWithClose = cloneElement(props.children, {
    onClick: (...args: any[]) => {
      props.children.props.onClick?.(...args);
      setOpen(false);
    },
  });

  return childWithClose;
}

DialogNext.Action = DialogAction;
