import { IconProps } from '@/app/components/Icon';

export function DocumentIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M5.675 17.3779H14.325C15.4491 17.3779 16.3603 16.4667 16.3603 15.3426V6.94706L12.0353 2.62207H5.675C4.55094 2.62207 3.63971 3.5333 3.63971 4.65736V15.3426C3.63971 16.4667 4.55094 17.3779 5.675 17.3779Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M16.1059 7.20147H11.7809V2.87648' />
          <path d='M7.71029 13.3073H12.2897Z' />
          <path d='M7.71029 10.2544H12.2897Z' />
          <path d='M16.1059 7.20147H11.7809V2.87648M7.71029 13.3073H12.2897M7.71029 10.2544H12.2897M5.675 17.3779H14.325C15.4491 17.3779 16.3603 16.4667 16.3603 15.3426V6.94706L12.0353 2.62207H5.675C4.55094 2.62207 3.63971 3.5333 3.63971 4.65736V15.3426C3.63971 16.4667 4.55094 17.3779 5.675 17.3779Z' />
        </>
      ) : (
        <>
          <path d='M12.4483 5.96121H9.29743V2.81034M6.33191 10.4095H9.66812M6.33191 8.18534H9.66812M4.84915 13.375H11.1509C11.9698 13.375 12.6336 12.7112 12.6336 11.8922V5.77586L9.48277 2.625H4.84915C4.03025 2.625 3.36639 3.28885 3.36639 4.10776V11.8922C3.36639 12.7112 4.03025 13.375 4.84915 13.375Z' />
        </>
      )}
    </>
  );
}
