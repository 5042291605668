import { IconProps } from '@/app/components/Icon';

export function LockSquareIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M14 2H6C3.79086 2 2 3.79086 2 6V14C2 16.2091 3.79086 18 6 18H14C16.2091 18 18 16.2091 18 14V6C18 3.79086 16.2091 2 14 2Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M17.25 6.25V4.25C17.25 3.42157 16.5784 2.75 15.75 2.75H4.75C3.64543 2.75 2.75 3.64543 2.75 4.75V15.25C2.75 16.3546 3.64543 17.25 4.75 17.25H7.25' />
          <path d='M11.75 12.75V12C11.75 10.7574 12.7574 9.75 14 9.75V9.75C15.2426 9.75 16.25 10.7574 16.25 12V12.75M11.75 12.75H10.75V16.25C10.75 16.8023 11.1977 17.25 11.75 17.25H16.25C16.8023 17.25 17.25 16.8023 17.25 16.25V12.75H11.75Z' />
        </>
      ) : (
        <>
          <path d='M8.75 9.75V8.5C8.75 7.5335 9.5335 6.75 10.5 6.75V6.75C11.4665 6.75 12.25 7.5335 12.25 8.5V9.75M8.75 9.75H7.75V12.25C7.75 12.8023 8.19772 13.25 8.75 13.25H12.25C12.8023 13.25 13.25 12.8023 13.25 12.25V9.75H8.75Z' />
          <path d='M5.25 13.25H4.75C3.64543 13.25 2.75 12.3546 2.75 11.25V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H11.25C12.3546 2.75 13.25 3.64543 13.25 4.75V5.25' />
        </>
      )}
    </>
  );
}
