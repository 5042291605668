import { IconProps } from '@/app/components/Icon';

export function FaceScanIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M5 18H15C16.6569 18 18 16.6569 18 15V5C18 3.34315 16.6569 2 15 2H5C3.34315 2 2 3.34315 2 5V15C2 16.6569 3.34315 18 5 18Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M2.75 5.25V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H5.25' />
          <path d='M2.75 14.75V15.25C2.75 16.3546 3.64543 17.25 4.75 17.25H5.25' />
          <path d='M17.25 5.25V4.75C17.25 3.64543 16.3546 2.75 15.25 2.75H14.75' />
          <path d='M17.25 14.75V15.25C17.25 16.3546 16.3546 17.25 15.25 17.25H14.75' />
          <path d='M7.25 6.75V8.25' />
          <path d='M12.75 6.75V8.25' />
          <path d='M13.25 11.75C12.6097 12.4267 11.5834 13.25 10 13.25C8.41663 13.25 7.39029 12.4267 6.75 11.75' />
        </>
      ) : (
        <>
          <path d='M2.75 5.25V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H5.25M2.75 10.75V11.25C2.75 12.3546 3.64543 13.25 4.75 13.25H5.25M13.25 5.25V4.75C13.25 3.64543 12.3546 2.75 11.25 2.75H10.75M13.25 10.75V11.25C13.25 12.3546 12.3546 13.25 11.25 13.25H10.75M6.25 5.75V6.25M9.75 5.75V6.25M9.74559 9.55078C9.40168 9.90813 8.85043 10.25 8 10.25C7.14957 10.25 6.59831 9.90813 6.25441 9.55078' />
        </>
      )}
    </>
  );
}
