import { IconProps } from '@/app/components/Icon';

export function ProgressIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M10 15.75V17.25' />
          <path d='M4.25 10L2.75 10' />
          <path d='M5.93414 5.93414L4.87348 4.87348' />
          <path d='M15.1265 15.1265L14.0659 14.0659' />
          <path d='M4.87341 15.1265L5.93407 14.0659' />
          <path
            d='M17.25 10.25C17.25 6.10786 13.8921 2.75 9.75 2.75V10.25H17.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M13.25 8C13.25 5.10051 10.8995 2.75 8 2.75' />
          <path d='M13.25 8.25C13.25 5.21243 10.7876 2.75 7.75 2.75V8.25H13.25Z' />
          <path d='M11.7123 11.7123L11.3587 11.3588' />
          <path d='M8 12.75V13.25' />
          <path d='M3.25 8L2.75 8' />
          <path d='M4.64124 4.64124L4.28768 4.28768' />
          <path d='M4.64136 11.3587L4.2878 11.7123' />
        </>
      )}
    </>
  );
}
