import { useUnsafeMetadata } from '../hooks/useUnsafeMetadata.ts';
import { useExportUsersToast } from '../hooks/useExportUsersToast.ts';
import { useAuth } from '@clerk/nextjs';
import { useFetchExportUsersLogs } from '../dapi/exportUsers/queries.ts';
import { useInstance } from '../hooks/useInstance.ts';
import { getInstance } from '../app/api/instances.ts';
import { getApplication } from '../app/api/applications.ts';
import { useEffect, useRef } from 'react';
import { Poller } from '../utils/poller.ts';

export const ExportUsersPoller = () => {
  const pollersRef = useRef([] as Poller[]);
  const { getExportInstanceIds, removeExportInstanceId } = useUnsafeMetadata();
  const { showExportUsersToast } = useExportUsersToast();
  const { getToken } = useAuth();
  const { mutate: revalidateServerLogs } = useFetchExportUsersLogs();
  const { data: activeInstance, mutate: revalidateActiveInstance } =
    useInstance();

  const isCurrentInstance = (id: string) => {
    return activeInstance?.id === id;
  };

  useEffect(() => {
    pollersRef.current = getExportInstanceIds().map(id => {
      const handleCompletion = async () => {
        void removeExportInstanceId(id);
        const { application_id } = await getInstance(id, {
          token: await getToken(),
        });
        const { name } = await getApplication(application_id, {
          token: await getToken(),
        });
        showExportUsersToast(id, application_id, name);
        if (isCurrentInstance(id)) {
          await revalidateServerLogs();
        }
      };

      const shouldRun = async () => {
        const token = await getToken();
        if (isCurrentInstance(id)) {
          // If it's the active instance, we can mutate the data directly
          // so the rest of the update gets updated as well
          return (await revalidateActiveInstance())!.user_export.in_progress;
        }
        // If we're polling an instance that isn't the active instance
        // we need to manually fetch the data
        return (await getInstance(id, { token })).user_export.in_progress;
      };

      const poller = Poller({ delayInMs: 1000 });
      void poller.run(async stop => {
        try {
          if (!(await shouldRun())) {
            stop();
            await handleCompletion();
          }
        } catch (e) {
          stop();
        }
      });
      return poller;
    });

    return () => {
      pollersRef.current.forEach(poller => poller.stop());
      pollersRef.current = [];
    };
  }, [activeInstance?.id, getExportInstanceIds().join(',')]);

  // Revalidate the server logs for the current instance
  // on status change. This is usually from queued -> in_progress
  useEffect(() => {
    void revalidateServerLogs();
  }, [activeInstance?.user_export.status]);

  return null;
};
