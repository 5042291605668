import { IconProps } from '@/app/components/Icon';

export function ArrowDotsIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M10.0163 2.75C6.00321 2.75 2.75 5.99594 2.75 10C2.75 13.1567 4.9278 15.8422 7.75 16.8375M2.75 17.25H7.75V16.8375M7.75 12.4167V16.8375M17.233 10.4999C17.2443 10.3348 17.25 10.168 17.25 10C17.25 9.6607 17.2267 9.32684 17.1816 8.99994M12.5 3.19257C12.9428 3.35523 13.3653 3.55985 13.7627 3.80162M15.6847 5.49994C15.9699 5.85964 16.2216 6.24703 16.4352 6.65741M14 16.0476C13.5348 16.3559 13.0321 16.612 12.5 16.8074M16.6021 13C16.4095 13.4232 16.1774 13.8247 15.9105 14.1996' />
        </>
      ) : (
        <>
          <path d='M8 2.75C5.10051 2.75 2.75 5.10051 2.75 8C2.75 10.2859 4.21091 12.2306 6.25 12.9513M6.25 9.75V12.9513M2.75 13.25H6.25V12.9513' />
          <path d='M11 3.69104C11.335 3.92472 11.6415 4.19647 11.9132 4.50003M13.0326 6.50003C13.1283 6.82153 13.194 7.15595 13.2265 7.50003M13 9.60544C12.8999 9.91736 12.7715 10.2166 12.6177 10.5' />
        </>
      )}
    </>
  );
}
