import { IconProps } from '@/app/components/Icon';

export function AtlassianIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M6.4884 9.20149C6.21232 8.91072 5.77792 8.92671 5.5932 9.30141L1.05732 18.2275C1.01594 18.3089 0.996398 18.3993 1.00055 18.4903C1.00469 18.5812 1.0324 18.6695 1.08102 18.7469C1.12964 18.8244 1.19756 18.8882 1.27833 18.9325C1.3591 18.9768 1.45002 19 1.54247 18.9999H7.8586C7.96033 19.0018 8.06042 18.9745 8.14659 18.9213C8.23277 18.868 8.30129 18.7912 8.34375 18.7001C9.70584 15.9333 8.88067 11.7175 6.4884 9.20149Z'
            fill='url(#paint0_linear_4255_16)'
            stroke='none'
          />
          <path
            d='M9.83001 1.28334C7.42886 5.19448 7.58733 9.52636 9.16921 12.7777L12.0506 18.7037C12.0926 18.7923 12.1578 18.8669 12.239 18.9193C12.3201 18.9717 12.4139 18.9996 12.5097 19H18.4867C18.5741 19.0001 18.6602 18.9771 18.7366 18.9334C18.8131 18.8896 18.8773 18.8265 18.9233 18.75C18.9693 18.6735 18.9956 18.5861 18.9995 18.4963C19.0034 18.4064 18.9849 18.317 18.9458 18.2365C18.9458 18.2365 10.9048 1.69223 10.7069 1.28038C10.5215 0.910002 10.0624 0.902101 9.83001 1.28334Z'
            fill='#2681FF'
            stroke='none'
          />
          <defs>
            <linearGradient
              id='paint0_linear_4255_16'
              x1='8.97506'
              y1='10.5804'
              x2='4.29883'
              y2='18.8074'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#0052CC' />
              <stop offset='0.92' stopColor='#2684FF' />
            </linearGradient>
          </defs>
        </>
      ) : (
        <>
          <path
            d='M4.77327 7.45514C4.58347 7.23124 4.28482 7.24355 4.15782 7.53208L1.0394 14.4052C1.01096 14.4678 0.997523 14.5375 1.00038 14.6075C1.00323 14.6775 1.02227 14.7455 1.0557 14.8051C1.08912 14.8647 1.13582 14.9139 1.19135 14.948C1.24688 14.9821 1.30939 15 1.37295 14.9999H5.71529C5.78522 15.0014 5.85404 14.9804 5.91328 14.9394C5.97253 14.8984 6.01964 14.8392 6.04883 14.7691C6.98526 12.6386 6.41796 9.3925 4.77327 7.45514Z'
            fill='url(#paint0_linear_4255_14)'
            stroke='none'
          />
          <path
            d='M7.49728 1.22037C5.5327 4.26237 5.66236 7.63161 6.95663 10.1605L9.31413 14.7695C9.34848 14.8384 9.40187 14.8965 9.46826 14.9372C9.53465 14.978 9.61138 14.9997 9.68976 15H14.58C14.6516 15.0001 14.722 14.9822 14.7845 14.9482C14.847 14.9141 14.8996 14.865 14.9373 14.8055C14.9749 14.746 14.9964 14.6781 14.9996 14.6082C15.0028 14.5383 14.9877 14.4688 14.9556 14.4062C14.9556 14.4062 8.37663 1.5384 8.21475 1.21807C8.06308 0.930002 7.68746 0.923856 7.49728 1.22037Z'
            fill='#2681FF'
            stroke='none'
          />
          <defs>
            <linearGradient
              id='paint0_linear_4255_14'
              x1='6.48285'
              y1='8.51691'
              x2='2.68594'
              y2='14.4812'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#0052CC' />
              <stop offset='0.92' stopColor='#2684FF' />
            </linearGradient>
          </defs>
        </>
      )}
    </>
  );
}
