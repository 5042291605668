import { IconProps } from '@/app/components/Icon';

export function XeroIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <g stroke='none'>
          <mask
            id='mask0_4324_692'
            maskUnits='userSpaceOnUse'
            x='1'
            y='1'
            width='18'
            height='18'
          >
            <path d='M19 1H1V19H19V1Z' fill='white' />
          </mask>
          <g mask='url(#mask0_4324_692)'>
            <path
              d='M9.99995 18.9657C14.9516 18.9657 18.9656 14.9516 18.9656 9.99997C18.9656 5.04838 14.9516 1.0343 9.99995 1.0343C5.04832 1.0343 1.03424 5.04838 1.03424 9.99997C1.03424 14.9516 5.04832 18.9657 9.99995 18.9657Z'
              fill='#1AB4D7'
            />
            <path
              d='M5.32639 9.97402L6.85646 8.44017C6.90718 8.38846 6.93549 8.32002 6.93549 8.24742C6.93549 8.09613 6.8125 7.97327 6.66121 7.97327C6.58752 7.97327 6.51843 8.00216 6.46634 8.0549L4.93764 9.58285L3.40211 8.0526C3.35022 8.00145 3.28147 7.97327 3.20857 7.97327C3.05746 7.97327 2.93445 8.09607 2.93445 8.24721C2.93445 8.32094 2.96388 8.39012 3.01671 8.44225L4.54656 9.97189L3.0175 11.5038C2.96376 11.5568 2.93445 11.6261 2.93445 11.7001C2.93445 11.8514 3.05746 11.9741 3.20857 11.9741C3.2816 11.9741 3.35041 11.9457 3.40211 11.8941L4.93526 10.363L6.46258 11.8885C6.51675 11.9445 6.58664 11.9743 6.66121 11.9743C6.81235 11.9743 6.93534 11.8514 6.93534 11.7001C6.93534 11.6271 6.90707 11.5586 6.8556 11.5069L5.32639 9.97402Z'
              fill='white'
            />
            <path
              d='M14.4218 9.97354C14.4218 10.2482 14.6452 10.4717 14.9202 10.4717C15.1946 10.4717 15.4181 10.2482 15.4181 9.97354C15.4181 9.69878 15.1946 9.47534 14.9202 9.47534C14.6452 9.47534 14.4218 9.69878 14.4218 9.97354Z'
              fill='white'
            />
            <path
              d='M13.4767 9.97399C13.4767 9.17849 14.1238 8.53122 14.9194 8.53122C15.7147 8.53122 16.3619 9.17849 16.3619 9.97399C16.3619 10.7693 15.7147 11.4163 14.9194 11.4163C14.1238 11.4163 13.4767 10.7693 13.4767 9.97399ZM12.9092 9.97399C12.9092 11.0822 13.811 11.9839 14.9194 11.9839C16.0279 11.9839 16.9302 11.0822 16.9302 9.97399C16.9302 8.86555 16.0279 7.96362 14.9194 7.96362C13.811 7.96362 12.9092 8.86555 12.9092 9.97399Z'
              fill='white'
            />
            <path
              d='M12.7667 7.99817L12.6824 7.99792C12.4292 7.99792 12.185 8.07778 11.981 8.23496C11.9541 8.11197 11.8442 8.01943 11.7133 8.01943C11.5625 8.01943 11.4418 8.14019 11.4414 8.29121L11.4423 11.677C11.4428 11.8278 11.5657 11.9503 11.7164 11.9503C11.8673 11.9503 11.9902 11.8278 11.9906 11.6767L11.9907 9.59482C11.9907 8.90096 12.0541 8.62072 12.6485 8.54646C12.7035 8.53963 12.7634 8.54069 12.7634 8.54069C12.9261 8.53511 13.0417 8.42335 13.0417 8.2723C13.0417 8.12115 12.9183 7.99817 12.7667 7.99817Z'
              fill='white'
            />
            <path
              d='M7.50291 9.64332C7.50291 9.63581 7.50349 9.628 7.50386 9.62029C7.66301 8.99111 8.23284 8.52552 8.91146 8.52552C9.59828 8.52552 10.1734 9.00255 10.3244 9.64332H7.50291ZM10.8858 9.59155C10.7676 9.03223 10.4614 8.57279 9.99508 8.27775C9.31348 7.84501 8.41341 7.86895 7.75505 8.33714C7.21802 8.71918 6.90802 9.34415 6.90802 9.98794C6.90802 10.1493 6.92748 10.3122 6.96799 10.4726C7.17077 11.2704 7.85652 11.8742 8.67412 11.9743C8.91673 12.0037 9.15288 11.9896 9.39742 11.9263C9.60755 11.8752 9.81088 11.7899 9.99823 11.6699C10.1927 11.5449 10.3552 11.38 10.5125 11.1827C10.5157 11.1791 10.5189 11.1759 10.5221 11.1721C10.6313 11.0366 10.611 10.844 10.4911 10.7521C10.3898 10.6746 10.2199 10.6431 10.0862 10.8143C10.0574 10.8552 10.0254 10.8973 9.99009 10.9394C9.88378 11.057 9.75189 11.1707 9.59385 11.259C9.39276 11.3665 9.16359 11.4279 8.91996 11.4292C8.12257 11.4203 7.69587 10.8638 7.54409 10.4665C7.51758 10.3922 7.49714 10.3153 7.4829 10.2361C7.48104 10.2212 7.47966 10.2069 7.47912 10.1938C7.64399 10.1938 10.3407 10.1933 10.3407 10.1933C10.733 10.1851 10.9441 9.9082 10.8858 9.59155Z'
              fill='white'
            />
          </g>
        </g>
      ) : (
        <g stroke='none'>
          <mask
            id='mask0_4324_687'
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width='15'
            height='16'
          >
            <path d='M15 0.990234H0.980469V15.0098H15V0.990234Z' fill='white' />
          </mask>
          <g mask='url(#mask0_4324_687)'>
            <path
              d='M7.99032 14.9832C11.847 14.9832 14.9733 11.8568 14.9733 8.00012C14.9733 4.1435 11.847 1.01709 7.99032 1.01709C4.13368 1.01709 1.00726 4.1435 1.00726 8.00012C1.00726 11.8568 4.13368 14.9832 7.99032 14.9832Z'
              fill='#1AB4D7'
            />
            <path
              d='M4.35011 7.9797L5.54183 6.78504C5.58134 6.74477 5.60338 6.69146 5.60338 6.63492C5.60338 6.51708 5.50759 6.42139 5.38976 6.42139C5.33236 6.42139 5.27855 6.44389 5.23798 6.48497L4.04733 7.67503L2.85136 6.48318C2.81095 6.44334 2.7574 6.42139 2.70063 6.42139C2.58293 6.42139 2.48712 6.51703 2.48712 6.63475C2.48712 6.69217 2.51004 6.74606 2.55119 6.78666L3.74274 7.97804L2.55181 9.17121C2.50995 9.21243 2.48712 9.26641 2.48712 9.3241C2.48712 9.44193 2.58293 9.53747 2.70063 9.53747C2.7575 9.53747 2.8111 9.51539 2.85136 9.47516L4.04548 8.28265L5.23506 9.47081C5.27725 9.51441 5.33168 9.53761 5.38976 9.53761C5.50748 9.53761 5.60327 9.44193 5.60327 9.3241C5.60327 9.26725 5.58125 9.21383 5.54116 9.1736L4.35011 7.9797Z'
              fill='white'
            />
            <path
              d='M11.4342 7.97933C11.4342 8.19324 11.6082 8.3673 11.8224 8.3673C12.0361 8.3673 12.2102 8.19324 12.2102 7.97933C12.2102 7.76533 12.0361 7.59131 11.8224 7.59131C11.6082 7.59131 11.4342 7.76533 11.4342 7.97933Z'
              fill='white'
            />
            <path
              d='M10.6981 7.97974C10.6981 7.36015 11.2021 6.85602 11.8218 6.85602C12.4412 6.85602 12.9453 7.36015 12.9453 7.97974C12.9453 8.59916 12.4412 9.1031 11.8218 9.1031C11.2021 9.1031 10.6981 8.59916 10.6981 7.97974ZM10.2561 7.97974C10.2561 8.84289 10.9585 9.5452 11.8218 9.5452C12.6851 9.5452 13.3879 8.84289 13.3879 7.97974C13.3879 7.11642 12.6851 6.41394 11.8218 6.41394C10.9585 6.41394 10.2561 7.11642 10.2561 7.97974Z'
              fill='white'
            />
            <path
              d='M10.1452 6.44074L10.0796 6.44055C9.8823 6.44055 9.69212 6.50275 9.53328 6.62517C9.51232 6.52938 9.42666 6.45731 9.32474 6.45731C9.20733 6.45731 9.11326 6.55136 9.11298 6.66898L9.11368 9.30608C9.11403 9.42349 9.20978 9.5189 9.32719 9.5189C9.44468 9.5189 9.54043 9.42349 9.54071 9.3058L9.54078 7.68432C9.54078 7.14389 9.5902 6.92563 10.0531 6.86778C10.096 6.86246 10.1426 6.86329 10.1426 6.86329C10.2694 6.85894 10.3594 6.7719 10.3594 6.65425C10.3594 6.53653 10.2633 6.44074 10.1452 6.44074Z'
              fill='white'
            />
            <path
              d='M6.04537 7.72225C6.04537 7.71641 6.04582 7.71032 6.04611 7.70432C6.17007 7.21428 6.61388 6.85164 7.14243 6.85164C7.67738 6.85164 8.12528 7.22319 8.2429 7.72225H6.04537ZM8.68017 7.68193C8.58813 7.2463 8.34966 6.88846 7.98643 6.65866C7.45555 6.32162 6.75452 6.34026 6.24175 6.70492C5.82348 7.00248 5.58203 7.48924 5.58203 7.99066C5.58203 8.11637 5.59719 8.24325 5.62874 8.36816C5.78667 8.98951 6.32078 9.45979 6.95758 9.53774C7.14654 9.56066 7.33047 9.54966 7.52093 9.50038C7.6846 9.46056 7.84296 9.39418 7.98888 9.30067C8.14035 9.2033 8.26688 9.07489 8.38941 8.92123C8.39193 8.91843 8.39438 8.9159 8.39691 8.91296C8.48194 8.80746 8.46616 8.65745 8.37272 8.58581C8.29386 8.52546 8.16152 8.50093 8.05743 8.63432C8.03499 8.66615 8.01004 8.69895 7.98254 8.73176C7.89974 8.82331 7.79702 8.91191 7.67393 8.98067C7.5173 9.06437 7.33881 9.11218 7.14906 9.11323C6.528 9.10629 6.19566 8.67281 6.07744 8.36339C6.0568 8.30556 6.04088 8.24563 6.02978 8.18394C6.02834 8.17238 6.02726 8.16123 6.02684 8.151C6.15525 8.151 8.25559 8.15065 8.25559 8.15065C8.56115 8.1442 8.7256 7.92856 8.68017 7.68193Z'
              fill='white'
            />
          </g>
        </g>
      )}
    </>
  );
}
