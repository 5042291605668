import { IconProps } from '@/app/components/Icon';

export function ChevronUpIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M5.75 13.25L10 8.75L14.25 13.25' />
        </>
      ) : (
        <>
          <path d='M4.75 10.25L8 6.75L11.25 10.25' />
        </>
      )}
    </>
  );
}
