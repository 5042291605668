import { IconProps } from '@/app/components/Icon';

export function ArrowUpSquareIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2.75 14.25V5.75C2.75 4.09315 4.09315 2.75 5.75 2.75H14.25C15.9069 2.75 17.25 4.09315 17.25 5.75V14.25C17.25 15.9069 15.9069 17.25 14.25 17.25H5.75C4.09315 17.25 2.75 15.9069 2.75 14.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M10 14.25V5.75M10 5.75L6.75 8.97222M10 5.75L13.25 8.97222' />
        </>
      ) : (
        <>
          <path d='M2.75 11.25V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H11.25C12.3546 2.75 13.25 3.64543 13.25 4.75V11.25C13.25 12.3546 12.3546 13.25 11.25 13.25H4.75C3.64543 13.25 2.75 12.3546 2.75 11.25Z' />
          <path d='M8 10.25V5.75M8 5.75L5.75 7.96429M8 5.75L10.25 7.96429' />
        </>
      )}
    </>
  );
}
