import { Application } from '@/app/api/types/applications';
import { CurrentSubscription } from '@/app/api/types/products';
import { request } from '@/app/utils/http';
import { GetTokenOptions, Options } from './types/utils';
import { queryOptions } from '@tanstack/react-query';

export const getApplications = async (
  session: string,
  organizationId: string | null | undefined,
  { token }: Options,
) =>
  await request<Application[]>({
    path: `/v1/applications?session=${session}&organizationId=${organizationId}`,
    token,
  });

export const getApplication = async (
  applicationId: string,
  { token }: Options,
) =>
  await request<Application>({
    path: `/v1/applications/${applicationId}`,
    token,
  });

export const patchApplication = async (
  applicationId: string,
  data: Partial<Application>,
  { token }: Options,
) =>
  await request<Application>({
    data,
    method: 'PATCH',
    path: `/v1/applications/${applicationId}`,
    token,
  });

export const getCurrentSubscription = async (
  applicationId: string,
  { token }: Options,
) =>
  await request<CurrentSubscription>({
    path: `/v1/applications/${applicationId}/current_subscription`,
    token,
  });
export const currentSubscriptionQuery = (
  applicationId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () =>
      getCurrentSubscription(applicationId, { token: await getToken() }),
    queryKey: ['currentSubscription', applicationId],
  });
};
