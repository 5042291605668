import { IconProps } from '@/app/components/Icon';

export function MultiFactorIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M15.25 16.2462H4.75' />
          <path d='M10 8L14.25 5.71154M10 8V12.25M10 8L5.75 5.71154M10 8L14.25 10.2885M10 8V3.75M10 8L5.75 10.2885' />
        </>
      ) : (
        <>
          <path d='M12.25 13.2695H3.75' />
          <path d='M8 6.03125L11.25 4.28125M8 6.03125V9.28125M8 6.03125L4.75 4.28125M8 6.03125L11.25 7.78125M8 6.03125V2.78125M8 6.03125L4.75 7.78125' />
        </>
      )}
    </>
  );
}
