import { IconProps } from '@/app/components/Icon';

export function KeyIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M12.9254 2.75C10.537 2.75 8.60088 4.68617 8.60088 7.07456C8.60088 7.3099 8.61968 7.54085 8.65585 7.76599L3.3891 12.6566C2.98156 13.035 2.75 13.566 2.75 14.1221V17.25H6.25V15.75C6.25 15.1977 6.69772 14.75 7.25 14.75H8L12.0201 11.3042C12.3121 11.3664 12.6149 11.3991 12.9254 11.3991C15.3138 11.3991 17.25 9.46295 17.25 7.07456C17.25 4.68617 15.3138 2.75 12.9254 2.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <circle cx='13' cy='7' r='1.25' />
        </>
      ) : (
        <>
          <path d='M10.1184 2.75C8.3889 2.75 6.98684 4.15206 6.98684 5.88158C6.98684 6.052 7.00046 6.21924 7.02665 6.38227L3.21279 9.92371C2.91768 10.1977 2.75 10.5823 2.75 10.985V13.25H5.25V11.25H6.55172L9.46283 8.94442C9.67425 8.98946 9.89357 9.01316 10.1184 9.01316C11.8479 9.01316 13.25 7.6111 13.25 5.88158C13.25 4.15206 11.8479 2.75 10.1184 2.75Z' />
          <circle cx='10' cy='6' r='1' fill='currentColor' stroke='none' />
        </>
      )}
    </>
  );
}
