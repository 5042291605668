import { IconProps } from '@/app/components/Icon';

export function SwatchIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2.75 3.75V14C2.75 15.7949 4.20507 17.25 6 17.25H16.25C16.8023 17.25 17.25 16.8023 17.25 16.25V11.75C17.25 11.1977 16.8023 10.75 16.25 10.75H13.9424L16.6391 8.0533C17.0296 7.66278 17.0296 7.02961 16.6391 6.63909L13.4571 3.45711C13.0666 3.06658 12.4334 3.06658 12.0429 3.45711L9.25 6.25V3.75C9.25 3.19772 8.80228 2.75 8.25 2.75H3.75C3.19772 2.75 2.75 3.19772 2.75 3.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M6 17.25C7.79492 17.25 9.25 15.7949 9.25 14V3.75C9.25 3.19772 8.80228 2.75 8.25 2.75H3.75C3.19772 2.75 2.75 3.19772 2.75 3.75V14C2.75 15.7949 4.20507 17.25 6 17.25ZM6 17.25L16.25 17.25C16.8023 17.25 17.25 16.8023 17.25 16.25V11.75C17.25 11.1977 16.8023 10.75 16.25 10.75H14M9.25 6.25L12.0429 3.45711C12.4334 3.06658 13.0666 3.06658 13.4571 3.45711L16.6391 6.63909C17.0296 7.02961 17.0296 7.66278 16.6391 8.0533L9.39124 15.3011C9.19016 15.5022 8.96929 15.6714 8.7349 15.8088' />
          <circle cx='6' cy='14' r='1' fill='currentColor' stroke='none' />
        </>
      ) : (
        <>
          <path d='M5 13.25C3.75736 13.25 2.75 12.2426 2.75 11V2.75H7.25V5.29655M5 13.25C6.24264 13.25 7.25 12.2426 7.25 11V8.75M5 13.25H13.25V8.75H10.7034M7.25 5.29655L9.79655 2.75L13.25 6.20345L10.7034 8.75M7.25 5.29655V8.75M10.7034 8.75L7.25 8.75' />
        </>
      )}
    </>
  );
}
