import React from 'react';
import { ChakraProvider } from '@context/ChakraContext';
import { useLocation } from '@hooks/useLocation';
import { useLastActive } from '@hooks/useLastActive';
import ErrorBoundary from '@components/errorBoundary/ErrorBoundary';
import { useUser } from '@clerk/nextjs';
import { Home } from './Home';
import DashboardApplication from './DashboardApplication';
import { SupportPopover } from '@components/SupportPopover';
import useClerkStaff from '@/hooks/useClerkStaff';

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const { isHome } = useLocation();

  return isHome ? (
    <Home>{children}</Home>
  ) : (
    <DashboardApplication>{children}</DashboardApplication>
  );
};

type LayoutProps = {
  children: React.ReactNode;
};

export function Layout({ children }: LayoutProps) {
  const isClerkStaff = useClerkStaff();

  const { isLoaded } = useUser();
  useLastActive({ shouldTrack: true });

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <ChakraProvider>
        <ErrorBoundary>
          <MainLayout>{children}</MainLayout>
        </ErrorBoundary>
      </ChakraProvider>

      {isClerkStaff && <SupportPopover />}
    </>
  );
}

export function PublicLayout({ children }: LayoutProps) {
  return <>{children}</>;
}
