import { useInstance } from '@hooks/useInstance';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useAnalytics } from '@hooks/useAnalytics';
import { useApplication } from '@hooks/useApplication';
import { useRouter } from 'next/router';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { cx } from 'cva';
import { CreateInstanceDialog } from '@components/instances/CreateInstanceDialog';
import { CreateInstanceMultipleAppsDomainDialog } from '@components/instances/CreateInstanceMultipleAppsDomainDialog';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { NavigationBreadcrumbButton } from '@/app/components/NavigationBreadcrumbButton';

export function EnvironmentStatus() {
  const { track } = useAnalytics();
  const { application } = useApplication();
  const { instance } = useInstance();
  const router = useRouter();
  const environment = instance?.environment_type;
  const { featureFlags } = useFeatureFlags();
  const isUseSuffixedCookiesEnabled = featureFlags.use_suffixed_cookies;

  const activeInstance = application?.instances.find(
    ({ id }) => id === instance?.id,
  );

  const onSelectInstance = (instanceId: string) => {
    if (!instanceId) {
      return;
    }
    track('Dashboard_Top Nav_Instance Menu Item Clicked', {
      surface: 'Dashboard',
      location: 'Top Nav',
      clickedOn: activeInstance ? activeInstance.environment_type : '',
    });
    const { pathname, query, push } = router;
    void push({ pathname, query: { ...query, instanceId: instanceId } });
  };

  const canCreateProductionInstance = () => {
    return !application?.instances?.some(
      instance => instance.environment_type === 'production',
    );
  };

  return (
    environment && (
      <div
        className={cx('text-center', {
          '[--badge-color-primary:theme(colors.legacy-orange.500)] [--badge-color-secondary:theme(colors.legacy-orange.600/0.1)] [--badge-ring-color:theme(colors.legacy-orange.500/0.1)] [--line-color:theme(colors.legacy-orange.400)] dark:[--badge-color-primary:theme(colors.legacy-orange.600)] dark:[--line-color:theme(colors.legacy-orange.500)]':
            environment === 'development',
          '[--badge-color-primary:theme(colors.sky.500)] [--badge-color-secondary:theme(colors.sky.600/0.1)] [--badge-ring-color:theme(colors.sky.500/0.1)] [--line-color:theme(colors.sky.400)]':
            environment === 'staging',
          '[--badge-color-primary:theme(colors.purple.500)] [--badge-color-secondary:theme(colors.purple.600/0.1)] [--badge-ring-color:theme(colors.purple.500/0.1)] [--line-color:theme(colors.purple.400)]':
            environment === 'production',
        })}
      >
        <DropdownMenu.Root>
          {/* <DropdownMenu.Trigger
            className={cx(
              'group relative isolate flex items-center gap-1 rounded-[7px] border bg-clip-padding py-[0.3125rem] pl-2.5 pr-2 font-book transition select-none',
              'hover:border-black/5 hover:bg-white hover:shadow hover:shadow-black/2',
              'dark:hover:border-black/5 dark:hover:bg-gray-300 dark:hover:shadow-black/2 dark:hover:before:opacity-100',
              'before:absolute before:inset-0 before:rounded-inherit before:opacity-0 before:shadow-[inset_0_1px_0,inset_0_1px_0,inset_0_0_0_1px] before:shadow-white/2 before:transition before:pointer-events-none',
              'after:absolute after:-inset-1 after:rounded-[0.5625rem] after:border-2 after:border-legacy-blue-500 after:opacity-0 after:pointer-events-none',
              'text-[--badge-color-primary] hover:bg-[--badge-color-secondary] aria-expanded:bg-[--badge-color-secondary]',
            )}
         */}

          <DropdownMenu.Trigger asChild>
            <NavigationBreadcrumbButton>
              <span className='flex items-center gap-2.5 capitalize'>
                <span
                  className={cx(
                    'block size-1.5 shrink-0 rounded-full shadow-[0_0_0_3px] ml-1',
                    {
                      'bg-legacy-orange-500 shadow-legacy-orange-500/25':
                        environment === 'development',
                      'bg-sky-500 shadow-sky-500/25': environment === 'staging',
                      'bg-purple-500 shadow-purple-500/25':
                        environment === 'production',
                    },
                  )}
                />
                <span className='truncate capitalize'>{environment}</span>
              </span>
            </NavigationBreadcrumbButton>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              onCloseAutoFocus={e => e.preventDefault()}
              sideOffset={4}
              side='bottom'
              align='start'
              className='w-60 rounded-[0.5625rem] border border-black/[0.06] bg-white bg-clip-padding shadow-lg'
            >
              <DropdownMenu.Group className='p-px'>
                {application?.instances?.map(ins => {
                  const isSelected = activeInstance?.id === ins.id;
                  return (
                    <DropdownMenu.Item
                      key={ins.id}
                      onSelect={() => {
                        onSelectInstance(ins.id);
                      }}
                      className={cx(
                        'relative isolate flex cursor-pointer items-center gap-2 px-2 py-1.5 font-book capitalize outline-none transition focus-visible:text-primary',
                        'before:absolute before:inset-px before:-z-10 before:rounded before:border before:border-black/1 before:bg-legacyGray-50 before:opacity-0 data-[highlighted]:before:opacity-100',
                        {
                          'text-primary shadow-[0_0_0_1px_theme(colors.white/0.02)_inset,0_1px_0_0_theme(colors.white/0.04)_inset]':
                            isSelected,
                          'bg-transparent text-secondary hover:text-primary':
                            !isSelected,
                        },
                      )}
                    >
                      <span className='size-4 flex-none opacity-60'>
                        {isSelected && <Icon name='check-legacy' size='sm' />}
                      </span>
                      {ins.environment_type}
                    </DropdownMenu.Item>
                  );
                })}

                {canCreateProductionInstance() &&
                  (isUseSuffixedCookiesEnabled ? (
                    <CreateInstanceMultipleAppsDomainDialog
                      instances={application.instances}
                      modalTrigger={
                        <DropdownMenu.Item
                          data-analytics='create-production-instance-modal-opened'
                          onSelect={event => {
                            event.preventDefault();
                          }}
                          className={cx(
                            'relative isolate flex cursor-pointer items-center gap-2 px-2 py-1.5 font-book text-purple outline-none transition focus-visible:text-purple',
                            'before:absolute before:inset-px before:-z-10 before:rounded before:border before:border-purple/1 before:bg-purple-50 before:opacity-0 data-[highlighted]:before:opacity-100',
                          )}
                        >
                          <span className='size-4 flex-none'>
                            <Icon name='plus-legacy' size='sm' />
                          </span>
                          Create production instance
                        </DropdownMenu.Item>
                      }
                    />
                  ) : (
                    <CreateInstanceDialog
                      instances={application.instances}
                      modalTrigger={
                        <DropdownMenu.Item
                          onSelect={event => {
                            event.preventDefault();
                          }}
                          className={cx(
                            'relative isolate flex cursor-pointer items-center gap-2 px-2 py-1.5 font-book text-purple outline-none transition focus-visible:text-purple',
                            'before:absolute before:inset-px before:-z-10 before:rounded before:border before:border-purple/1 before:bg-purple-50 before:opacity-0 data-[highlighted]:before:opacity-100',
                          )}
                        >
                          <span className='size-4 flex-none'>
                            <Icon name='plus-legacy' size='sm' />
                          </span>
                          Create production instance
                        </DropdownMenu.Item>
                      }
                    />
                  ))}
              </DropdownMenu.Group>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    )
  );
}
