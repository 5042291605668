import { IconProps } from '@/app/components/Icon';

export function UnlockIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M3.75 15.25V9.75C3.75 9.19772 4.19772 8.75 4.75 8.75H15.25C15.8023 8.75 16.25 9.19772 16.25 9.75V15.25C16.25 16.3546 15.3546 17.25 14.25 17.25H5.75C4.64543 17.25 3.75 16.3546 3.75 15.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M14.25 8.5V7C14.25 4.65279 12.3472 2.75 10 2.75C8.82904 2.75 7.76868 3.22356 7 3.9896' />
        </>
      ) : (
        <>
          <path d='M10.75 7.75H12.25V11.25C12.25 12.3546 11.3546 13.25 10.25 13.25H5.75C4.64543 13.25 3.75 12.3546 3.75 11.25V7.75H10.75ZM10.75 7.75V5.5C10.75 3.98122 9.51878 2.75 8 2.75C7.06992 2.75 6.24768 3.21172 5.75 3.91846' />
        </>
      )}
    </>
  );
}
