import { IconProps } from '@/app/components/Icon';

export function GoogleIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <g stroke='none'>
          <path
            d='M19.0267 10.2C19.0267 9.45998 18.9653 8.91998 18.8323 8.35999H10.1842V11.6999H15.2604C15.1581 12.53 14.6055 13.78 13.3773 14.6199L13.3601 14.7318L16.0944 16.8015L16.2839 16.82C18.0237 15.25 19.0267 12.94 19.0267 10.2Z'
            fill='#4285F4'
          />
          <path
            d='M10.1842 19C12.6711 19 14.7589 18.2 16.2839 16.82L13.3773 14.62C12.5995 15.15 11.5556 15.52 10.1842 15.52C7.74845 15.52 5.68112 13.95 4.94417 11.78L4.83615 11.789L1.99293 13.939L1.95575 14.04C3.47042 16.98 6.58168 19 10.1842 19Z'
            fill='#34A853'
          />
          <path
            d='M4.94464 11.7809C4.75017 11.2208 4.63762 10.6207 4.63762 10.0007C4.63762 9.38057 4.75017 8.78052 4.93441 8.22046L4.92926 8.10119L2.05006 5.91638L1.95586 5.96016C1.33152 7.18032 0.973267 8.55051 0.973267 10.0007C0.973267 11.4509 1.33152 12.821 1.95586 14.0411L4.94464 11.7809Z'
            fill='#FBBC05'
          />
          <path
            d='M10.1842 4.47998C11.9138 4.47998 13.0805 5.20998 13.7458 5.82002L16.3453 3.34C14.7488 1.89 12.6711 1 10.1842 1C6.58168 1 3.47042 3.01999 1.95575 5.95997L4.93394 8.22C5.68112 6.05 7.74845 4.47998 10.1842 4.47998Z'
            fill='#EB4335'
          />
        </g>
      ) : (
        <g stroke='none'>
          <path
            d='M15.0417 8.15559C15.0417 7.58004 14.9939 7.16004 14.8904 6.72449H8.16412V9.32225H12.1123C12.0327 9.96783 11.6029 10.9401 10.6477 11.5934L10.6343 11.6803L12.761 13.2902L12.9083 13.3045C14.2615 12.0834 15.0417 10.2867 15.0417 8.15559Z'
            fill='#4285F4'
          />
          <path
            d='M8.16411 15C10.0984 15 11.7222 14.3777 12.9083 13.3044L10.6476 11.5933C10.0427 12.0055 9.23074 12.2933 8.16411 12.2933C6.26961 12.2933 4.66168 11.0722 4.0885 9.3844L4.00449 9.39137L1.79308 11.0636L1.76416 11.1422C2.94224 13.4288 5.36212 15 8.16411 15Z'
            fill='#34A853'
          />
          <path
            d='M4.08849 9.38451C3.93725 8.94895 3.84973 8.48225 3.84973 8.00005C3.84973 7.51779 3.93725 7.05114 4.08054 6.61559L4.07653 6.52283L1.83741 4.82373L1.76415 4.85778C1.27861 5.80668 1 6.87227 1 8.00005C1 9.12783 1.27861 10.1934 1.76415 11.1423L4.08849 9.38451Z'
            fill='#FBBC05'
          />
          <path
            d='M8.16411 3.70666C9.50935 3.70666 10.4168 4.27444 10.9342 4.74892L12.9561 2.82001C11.7143 1.69223 10.0984 1 8.16411 1C5.36212 1 2.94224 2.57111 1.76416 4.85777L4.08054 6.61558C4.66168 4.9278 6.26961 3.70666 8.16411 3.70666Z'
            fill='#EB4335'
          />
        </g>
      )}
    </>
  );
}
