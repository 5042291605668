import { IconProps } from '@/app/components/Icon';

export function RefreshIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <circle
            cx='10'
            cy='10'
            r='7'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M17.25 2.75V7.25M17.25 7.25H12.75M17.25 7.25C17.25 7.25 14.0041 2.75 10 2.75C6.33522 2.75 3.30553 5.46916 2.8184 9' />
          <path d='M2.75 17.25L2.75 12.75M2.75 12.75L7.25 12.75M2.75 12.75C2.75 12.75 5.99594 17.25 10 17.25C13.6648 17.25 16.6945 14.5308 17.1816 11' />
        </>
      ) : (
        <>
          <path d='M10.682 6.23202H14.01L11.8893 4.11002C11.2066 3.42727 10.3561 2.93628 9.42346 2.68642C8.4908 2.43657 7.50879 2.43664 6.57617 2.68664C5.64354 2.93663 4.79316 3.42775 4.11052 4.1106C3.42788 4.79345 2.93703 5.64398 2.68732 6.57669M1.98999 13.096V9.76802M1.98999 9.76802H5.31799M1.98999 9.76802L4.10999 11.89C4.79273 12.5728 5.64318 13.0638 6.57584 13.3136C7.50851 13.5635 8.49051 13.5634 9.42314 13.3134C10.3558 13.0634 11.2061 12.5723 11.8888 11.8894C12.5714 11.2066 13.0623 10.3561 13.312 9.42335M14.01 2.90402V6.23069' />
        </>
      )}
    </>
  );
}
