import { IconProps } from '@/app/components/Icon';

export function CreditCardIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2.75 5.85C2.75 4.6902 3.59795 3.75 4.64394 3.75H15.3561C16.4021 3.75 17.25 4.6902 17.25 5.85V7.75V14.15C17.25 15.3098 16.4021 16.25 15.3561 16.25H4.64394C3.59794 16.25 2.75 15.3098 2.75 14.15V7.75V5.85Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M2.75 7.75V14.15C2.75 15.3098 3.59794 16.25 4.64394 16.25H15.3561C16.4021 16.25 17.25 15.3098 17.25 14.15V7.75M2.75 7.75V5.85C2.75 4.6902 3.59795 3.75 4.64394 3.75H15.3561C16.4021 3.75 17.25 4.6902 17.25 5.85V7.75M2.75 7.75H17.25M5.75 13.25H6.25M10.75 13.25H14.25' />
        </>
      ) : (
        <>
          <path d='M2.75 6.75V10.25C2.75 11.3546 3.64543 12.25 4.75 12.25H11.25C12.3546 12.25 13.25 11.3546 13.25 10.25V6.75M2.75 6.75V5.75C2.75 4.64543 3.64543 3.75 4.75 3.75H11.25C12.3546 3.75 13.25 4.64543 13.25 5.75V6.75M2.75 6.75H13.25M5.75 9.25H6.25' />
        </>
      )}
    </>
  );
}
