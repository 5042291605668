import { IconProps } from '@/app/components/Icon';

export function PlugIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M6.98016 13.0198C8.62037 14.6601 11.2797 14.6601 12.9199 13.0198L16.25 9.68976L10.3103 3.75L6.98016 7.08015C5.33995 8.72036 5.33995 11.3796 6.98016 13.0198Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M6.98016 13.0198C8.62037 14.6601 11.2797 14.6601 12.9199 13.0198L16.25 9.68976L10.3103 3.75L6.98016 7.08015C5.33995 8.72036 5.33995 11.3796 6.98016 13.0198ZM6.98016 13.0198L2.75 17.25' />
          <path d='M14.25 2.75L12 5' />
          <path d='M17.25 5.75L15 8' />
        </>
      ) : (
        <>
          <path d='M4.78122 11.2188C3.40626 9.84381 3.40626 7.61456 4.78122 6.23959L7.27082 3.75L12.25 8.72918L9.76041 11.2188C8.38544 12.5937 6.15619 12.5937 4.78122 11.2188ZM4.78122 11.2188L2.75 13.25' />
          <path d='M8.75 5.25L11.25 2.75' />
          <path d='M10.75 7.25L13.25 4.75' />
        </>
      )}
    </>
  );
}
