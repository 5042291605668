import { IconProps } from '@/app/components/Icon';

export function ArrowUpCircleIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <rect
            x='2.75'
            y='2.75'
            width='14.5'
            height='14.5'
            rx='7.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M7.75 9.25L10 6.75M12.25 9.25L10 6.75M10 6.75V13.25' />
        </>
      ) : (
        <>
          <circle cx='8' cy='8' r='5.25' />
          <path d='M5.75 8.25L8 5.75M10.25 8.25L8 5.75M8 5.75V10.25' />
        </>
      )}
    </>
  );
}
