import { IconProps } from '@/app/components/Icon';

export function DiscordIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          d='M16.2477 4.20003C15.0826 3.65487 13.8367 3.25867 12.5341 3.03308C12.3742 3.32229 12.1874 3.71128 12.0585 4.02072C10.6739 3.81249 9.302 3.81249 7.94287 4.02072C7.81404 3.71128 7.62292 3.32229 7.46152 3.03308C6.1576 3.25867 4.91032 3.65633 3.74514 4.20292C1.39498 7.75432 0.757892 11.2175 1.07644 14.6316C2.63519 15.7955 4.1458 16.5027 5.63093 16.9655C5.99762 16.4607 6.32465 15.9242 6.60638 15.3589C6.06982 15.155 5.5559 14.9034 5.0703 14.6113C5.19913 14.5158 5.32514 14.4161 5.44688 14.3134C8.40864 15.6987 11.6267 15.6987 14.553 14.3134C14.6762 14.4161 14.8022 14.5158 14.9297 14.6113C14.4426 14.9048 13.9273 15.1565 13.3907 15.3603C13.6724 15.9242 13.9981 16.4622 14.3662 16.9668C15.8527 16.5041 17.3647 15.7971 18.9235 14.6316C19.2973 10.6738 18.285 7.24242 16.2477 4.20003ZM7.00988 12.532C6.12079 12.532 5.39167 11.7019 5.39167 10.6912C5.39167 9.6804 6.10522 8.84894 7.00988 8.84894C7.91456 8.84894 8.64367 9.67894 8.6281 10.6912C8.6295 11.7019 7.91456 12.532 7.00988 12.532ZM12.9901 12.532C12.101 12.532 11.3719 11.7019 11.3719 10.6912C11.3719 9.6804 12.0854 8.84894 12.9901 8.84894C13.8947 8.84894 14.6238 9.67894 14.6083 10.6912C14.6083 11.7019 13.8947 12.532 12.9901 12.532Z'
          fill='#5865F2'
          stroke='none'
        />
      ) : (
        <path
          d='M12.8593 3.48893C11.9531 3.06491 10.9841 2.75676 9.97099 2.5813C9.8466 2.80624 9.70128 3.10878 9.60104 3.34946C8.52416 3.18751 7.45711 3.18751 6.40001 3.34946C6.29981 3.10878 6.15116 2.80624 6.02563 2.5813C5.01147 2.75676 4.04136 3.06605 3.13511 3.49117C1.30721 6.25337 0.811694 8.94697 1.05945 11.6023C2.27182 12.5077 3.44674 13.0576 4.60183 13.4176C4.88704 13.025 5.14139 12.6078 5.36052 12.168C4.94319 12.0095 4.54348 11.8137 4.16579 11.5866C4.26599 11.5123 4.364 11.4348 4.45868 11.3549C6.76228 12.4323 9.26518 12.4323 11.5412 11.3549C11.6371 11.4348 11.7351 11.5123 11.8342 11.5866C11.4553 11.8149 11.0546 12.0106 10.6372 12.1691C10.8563 12.6078 11.1096 13.0261 11.3959 13.4186C12.5521 13.0588 13.7281 12.5088 14.9405 11.6023C15.2312 8.5241 14.4439 5.85523 12.8593 3.48893ZM5.67435 9.96932C4.98284 9.96932 4.41574 9.32371 4.41574 8.53761C4.41574 7.75143 4.97073 7.10475 5.67435 7.10475C6.37799 7.10475 6.94508 7.7503 6.93297 8.53761C6.93406 9.32371 6.37799 9.96932 5.67435 9.96932ZM10.3256 9.96932C9.63408 9.96932 9.06701 9.32371 9.06701 8.53761C9.06701 7.75143 9.62197 7.10475 10.3256 7.10475C11.0292 7.10475 11.5963 7.7503 11.5842 8.53761C11.5842 9.32371 11.0292 9.96932 10.3256 9.96932Z'
          fill='#5865F2'
          stroke='none'
        />
      )}
    </>
  );
}
