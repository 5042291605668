import { IconProps } from '@/app/components/Icon';

export function DollarIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M13.9445 6.31707C13.34 5.63686 12.0577 4.75 10.0553 4.75C7.01019 4.75 5.75 6.0625 5.75 7.375C5.75 8.6875 6.96819 10 10.0553 10C14.6229 10 14.2422 12.625 14.2422 12.625C14.2422 13.7169 13.1003 15.25 10.0553 15.25C7.52272 15.25 6.3066 14.3421 5.75 13.659' />
          <path d='M10 2.75L10 17.25' />
        </>
      ) : (
        <>
          <path d='M11.0164 5.01858C10.5542 4.46793 9.57355 3.75 8.04226 3.75C5.71368 3.75 4.75 4.8125 4.75 5.875C4.75 6.9375 5.68156 8 8.04226 8C11.5351 8 11.25 10.125 11.25 10.125C11.25 11.009 10.3708 12.25 8.04226 12.25C6.10561 12.25 5.17564 11.5151 4.75 10.9621M8 2.75V13.25' />
        </>
      )}
    </>
  );
}
