import { IconProps } from '@/app/components/Icon';

export function RouteIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <rect
            x='2.75'
            y='2.75'
            width='4.5'
            height='4.5'
            rx='2.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <rect
            x='12.75'
            y='12.75'
            width='4.5'
            height='4.5'
            rx='2.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M7.25 5H12.25C13.3546 5 14.25 5.89543 14.25 7V8C14.25 9.10457 13.3546 10 12.25 10H7.75C6.64543 10 5.75 10.8954 5.75 12V13C5.75 14.1046 6.64543 15 7.75 15H10' />
        </>
      ) : (
        <>
          <rect x='2.75' y='2.75' width='3.5' height='3.5' rx='1' />
          <rect x='9.75' y='9.75' width='3.5' height='3.5' rx='1' />
          <path d='M8.75 4.75H9.625C10.5225 4.75 11.25 5.47754 11.25 6.375V6.375C11.25 7.27246 10.5225 8 9.625 8H6.375C5.47754 8 4.75 8.72754 4.75 9.625V9.625C4.75 10.5225 5.47754 11.25 6.375 11.25H7' />
        </>
      )}
    </>
  );
}
