import { IconProps } from '@/app/components/Icon';

export function ArrowLeftRightIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M6.25557 2.75989L2.75557 6.00989L6.25557 9.25989' />
          <path d='M3 6H14.25' />
          <path d='M13.75 17.25L17.2495 14L13.75 10.75' />
          <path d='M16.5857 14L5.75 14' />
        </>
      ) : (
        <>
          <path d='M5.25 2.75415L2.75798 4.92082M2.75798 4.92082L5.25 7.25M2.75798 4.92082L11.0762 4.92084M10.75 13.25L13.2429 11.0868M13.2429 11.0868L10.75 8.75004M13.2429 11.0868L4.92468 11.0867' />
        </>
      )}
    </>
  );
}
