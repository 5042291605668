import { IconProps } from '@/app/components/Icon';

export function ApiIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M3.25 13.25V10.25M7.25 13.25V10.25M7.25 10.25V8.75C7.25 7.64543 6.35457 6.75 5.25 6.75V6.75C4.14543 6.75 3.25 7.64543 3.25 8.75V10.25M7.25 10.25H3.25' />
          <path d='M10.25 13.25V10.25M10.25 10.25V6.75H12C12.9665 6.75 13.75 7.5335 13.75 8.5V8.5C13.75 9.4665 12.9665 10.25 12 10.25H10.25Z' />
          <path d='M16.75 13.25V6.75' />
        </>
      ) : (
        <>
          <path d='M3 10.25V8.25M5.5 10.25V8.25M5.5 8.25V6.75C5.5 6.19772 5.05228 5.75 4.5 5.75H4C3.44772 5.75 3 6.19772 3 6.75V8.25M5.5 8.25H3' />
          <path d='M8 10.25V8.25M8 8.25V5.75H9.5C10.0523 5.75 10.5 6.19772 10.5 6.75V7.25C10.5 7.80228 10.0523 8.25 9.5 8.25H8Z' />
          <path d='M13 10.25V8.25V5.75' />
        </>
      )}
    </>
  );
}
