import { IconProps } from '@/app/components/Icon';

export function SunIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <circle
            cx='10'
            cy='10'
            r='2.75'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M10 4.25V2.75' />
          <path d='M14.0659 5.93414L15.1265 4.87348' />
          <path d='M15.75 10L17.25 10' />
          <path d='M14.0659 14.0659L15.1265 15.1265' />
          <path d='M10 17.25V15.75' />
          <path d='M4.87347 15.1265L5.93413 14.0659' />
          <path d='M2.75 10L4.25 10' />
          <path d='M4.87347 4.87347L5.93413 5.93413' />
        </>
      ) : (
        <>
          <path d='M8 3.25V2.75M11.3588 4.64124L11.7123 4.28769M12.75 8H13.25M11.3588 11.3588L11.7123 11.7123M8 13.25V12.75M4.28769 11.7123L4.64124 11.3588M2.75 8H3.25M4.28769 4.28769L4.64124 4.64124M10.25 8C10.25 9.24264 9.24264 10.25 8 10.25C6.75736 10.25 5.75 9.24264 5.75 8C5.75 6.75736 6.75736 5.75 8 5.75C9.24264 5.75 10.25 6.75736 10.25 8Z' />
        </>
      )}
    </>
  );
}
