import { PaymentRequiredProvider } from '@context/PaymentRequired/PaymentRequiredProvider';
import { VitalProviders } from '@context/vitals/core/Provider';
import { useLocation } from '@hooks/useLocation';
import React, { Fragment } from 'react';
import { Layout } from '@components/layouts/Layout';
import CommandMenu from '@components/CommandMenu';
import { ExportUsersPoller } from '@components/ExportUsersPoller.ts';

export default function DashboardApplication({ children }) {
  return (
    <VitalProviders>
      <ExportUsersPoller />
      <PaymentRequiredProvider>
        <Layout>
          <RemountOnApplicationInstanceSwitch>
            {children}
          </RemountOnApplicationInstanceSwitch>
        </Layout>
        <CommandMenu />
      </PaymentRequiredProvider>
    </VitalProviders>
  );
}

function RemountOnApplicationInstanceSwitch(
  props: React.PropsWithChildren<object>,
) {
  const { applicationId, instanceId } = useLocation();
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  const key = applicationId + instanceId;
  return <Fragment key={key}>{props.children}</Fragment>;
}
