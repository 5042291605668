'use client';

import React from 'react';
import { useSession, useUser } from '@clerk/nextjs';
import { useSessionTracking } from '@hooks/useSessionTracking';
import { AnalyticsContextType } from '@/app/components/analytics/Analytics';

const TWO_SECONDS = 2 * 1000;
const ONE_MINUTE = 60 * 1000;

export const Listener = ({
  identify,
  track,
}: Pick<AnalyticsContextType, 'identify' | 'track'>) => {
  const { isLoaded: isUserLoaded, isSignedIn, user } = useUser();
  const { isLoaded: isSessionLoaded, session } = useSession();

  const {
    hasSignedUp,
    updateSignUp,
    hasSignedIn,
    updateSignIn,
    clearTracking,
  } = useSessionTracking();

  React.useEffect(() => {
    if (isUserLoaded && user == null) {
      clearTracking();
    }
    if (!isUserLoaded || !isSignedIn) {
      return;
    }
    if (!isSessionLoaded) {
      return;
    }

    const sessionCreatedDiff = Math.abs(
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      Date.now() - session.createdAt.getTime(),
    );
    if (sessionCreatedDiff >= ONE_MINUTE) {
      // session is too old to be a sign up or sign in
      return;
    }

    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    const signedUp = hasSignedUp(session.id);
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    const signedIn = hasSignedIn(session.id);
    const sessionUserCreatedDiff = Math.abs(
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      session.createdAt.getTime() - session.user.createdAt.getTime(),
    ); // in ms

    if (!signedUp && !signedIn) {
      identify(user.id, {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
        email: user.primaryEmailAddress.emailAddress,
        avatar: user.imageUrl,
        // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
        createdAt: user.createdAt.toISOString(),
      });
    }

    if (sessionUserCreatedDiff <= TWO_SECONDS) {
      // sign up
      if (signedUp) {
        return;
      }

      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      const externalAccounts = session.user.externalAccounts;
      const signedUpWith =
        externalAccounts.length === 0 ? 'Email' : externalAccounts[0].provider;

      track('Dashboard_Authentication_Customer Created', {
        surface: 'Dashboard',
        location: 'Authentication',
        signedUpWith,
      });
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      updateSignUp(session.id);
    } else {
      // sign in
      if (signedIn) {
        return;
      }

      track('Dashboard_Authentication_Customer Signed In', {
        surface: 'Dashboard',
        location: 'Authentication',
      });
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      updateSignIn(session.id);
    }
    // FIXME from turning on exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoaded, isSignedIn, user]);
  return null;
};
