import { IconProps } from '@/app/components/Icon';

export function LineIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <g stroke='none'>
          <path
            d='M14.9422 1H5.05787C2.81678 1 1 2.81678 1 5.05787V14.9422C1 17.1833 2.81678 19 5.05787 19H14.9422C17.1833 19 19 17.1833 19 14.9422V5.05787C19 2.81678 17.1833 1 14.9422 1Z'
            fill='#06C755'
          />
          <path
            d='M15.9997 9.15199C15.9997 6.46661 13.3076 4.28186 9.99838 4.28186C6.6892 4.28186 3.99707 6.46661 3.99707 9.15199C3.99707 11.5595 6.13457 13.576 9.0157 13.9568C9.21145 13.999 9.4775 14.0857 9.54444 14.2527C9.60519 14.4045 9.58438 14.6425 9.56413 14.7955C9.56413 14.7955 9.49382 15.219 9.47863 15.309C9.4522 15.461 9.3577 15.9025 9.99838 15.633C10.6391 15.3637 13.4544 13.598 14.7133 12.1484C15.5829 11.195 15.9997 10.2252 15.9997 9.15199Z'
            fill='white'
          />
          <path
            d='M14.0026 10.7025H12.3151C12.2853 10.7025 12.2567 10.6906 12.2355 10.6695C12.2145 10.6484 12.2026 10.6198 12.2026 10.59V7.97151C12.2026 7.94166 12.2145 7.91305 12.2355 7.89196C12.2567 7.87086 12.2853 7.85901 12.3151 7.85901H14.0026C14.0325 7.85901 14.0611 7.87086 14.0821 7.89196C14.1032 7.91305 14.1151 7.94166 14.1151 7.97151V8.39732C14.1151 8.42715 14.1032 8.45577 14.0821 8.47687C14.0611 8.49796 14.0325 8.50982 14.0026 8.50982H12.8568V8.95138H14.0026C14.0325 8.95138 14.0611 8.96324 14.0821 8.98433C14.1032 9.00543 14.1151 9.03404 14.1151 9.06388V9.49363C14.1151 9.52347 14.1032 9.55208 14.0821 9.57318C14.0611 9.59428 14.0325 9.60613 14.0026 9.60613H12.8568V10.0483H14.0026C14.0325 10.0483 14.0611 10.0601 14.0821 10.0812C14.1032 10.1024 14.1151 10.131 14.1151 10.1608V10.586C14.1156 10.6011 14.1131 10.6162 14.1077 10.6303C14.1023 10.6444 14.094 10.6573 14.0836 10.6681C14.073 10.679 14.0604 10.6876 14.0466 10.6936C14.0326 10.6994 14.0177 10.7025 14.0026 10.7025Z'
            fill='#06C755'
          />
          <path
            d='M7.76675 10.703C7.7966 10.703 7.82521 10.6911 7.8463 10.6701C7.86741 10.649 7.87925 10.6204 7.87925 10.5905V10.1653C7.87925 10.1355 7.86741 10.1069 7.8463 10.0857C7.82521 10.0646 7.7966 10.0528 7.76675 10.0528H6.62094V7.97151C6.62094 7.94166 6.60909 7.91305 6.588 7.89196C6.5669 7.87085 6.53828 7.85901 6.50844 7.85901H6.08094C6.05111 7.85901 6.02249 7.87085 6.00139 7.89196C5.9803 7.91305 5.96844 7.94166 5.96844 7.97151V10.5888C5.96844 10.6187 5.9803 10.6473 6.00139 10.6684C6.02249 10.6895 6.05111 10.7013 6.08094 10.7013H7.76844L7.76675 10.703Z'
            fill='#06C755'
          />
          <path
            d='M8.78133 7.85376H8.35608C8.29271 7.85376 8.24133 7.90513 8.24133 7.96851V10.5875C8.24133 10.6508 8.29271 10.7022 8.35608 10.7022H8.78133C8.84471 10.7022 8.89608 10.6508 8.89608 10.5875V7.96851C8.89608 7.90513 8.84471 7.85376 8.78133 7.85376Z'
            fill='#06C755'
          />
          <path
            d='M11.6783 7.85376H11.2531C11.2232 7.85376 11.1946 7.86561 11.1735 7.88671C11.1524 7.9078 11.1406 7.93642 11.1406 7.96626V9.52213L9.94239 7.90383C9.93965 7.89953 9.93645 7.89557 9.93283 7.89201L9.92608 7.88526L9.91989 7.8802H9.91651L9.91033 7.8757H9.90695L9.90076 7.87233H9.89683H9.89064H9.8867H9.87995H9.87545H9.8687H9.8642H9.85801H9.42433C9.39448 7.87233 9.36587 7.88418 9.34478 7.90528C9.32368 7.92637 9.31183 7.95498 9.31183 7.98483V10.6038C9.31183 10.6337 9.32368 10.6623 9.34478 10.6833C9.36587 10.7045 9.39448 10.7163 9.42433 10.7163H9.85014C9.87997 10.7163 9.90859 10.7045 9.92969 10.6833C9.95078 10.6623 9.96264 10.6337 9.96264 10.6038V9.03276L11.1625 10.6527C11.1704 10.6642 11.1803 10.6742 11.1917 10.682L11.1985 10.6865H11.2019L11.2075 10.6893H11.2131H11.2171H11.2249C11.2349 10.6917 11.2451 10.6931 11.2553 10.6932H11.6783C11.7081 10.6932 11.7367 10.6814 11.7579 10.6603C11.779 10.6391 11.7908 10.6106 11.7908 10.5807V7.96851C11.7911 7.95355 11.7884 7.93867 11.7829 7.92477C11.7774 7.91086 11.7691 7.89819 11.7587 7.8875C11.7482 7.87682 11.7357 7.86833 11.7219 7.86253C11.7081 7.85674 11.6933 7.85376 11.6783 7.85376Z'
            fill='#06C755'
          />
        </g>
      ) : (
        <g stroke='none'>
          <path
            d='M11.8439 1H4.15612C2.41305 1 1 2.41305 1 4.15612V11.8439C1 13.587 2.41305 15 4.15612 15H11.8439C13.587 15 15 13.587 15 11.8439V4.15612C15 2.41305 13.587 1 11.8439 1Z'
            fill='#06C755'
          />
          <path
            d='M12.6664 7.34036C12.6664 5.25174 10.5726 3.55249 7.99874 3.55249C5.42493 3.55249 3.33105 5.25174 3.33105 7.34036C3.33105 9.21284 4.99355 10.7813 7.23443 11.0774C7.38668 11.1103 7.59361 11.1777 7.64568 11.3076C7.69293 11.4257 7.67674 11.6108 7.66099 11.7298C7.66099 11.7298 7.6063 12.0592 7.59449 12.1292C7.57393 12.2473 7.50043 12.5908 7.99874 12.3812C8.49707 12.1717 10.6867 10.7983 11.6659 9.67092C12.3422 8.92934 12.6664 8.17509 12.6664 7.34036Z'
            fill='white'
          />
          <path
            d='M11.1131 8.5463H9.80063C9.77746 8.5463 9.7552 8.53706 9.73875 8.52068C9.72237 8.50423 9.71313 8.48197 9.71313 8.4588V6.42222C9.71313 6.399 9.72237 6.37675 9.73875 6.36034C9.7552 6.34394 9.77746 6.33472 9.80063 6.33472H11.1131C11.1364 6.33472 11.1586 6.34394 11.175 6.36034C11.1914 6.37675 11.2006 6.399 11.2006 6.42222V6.7534C11.2006 6.77661 11.1914 6.79887 11.175 6.81527C11.1586 6.83168 11.1364 6.8409 11.1131 6.8409H10.222V7.18434H11.1131C11.1364 7.18434 11.1586 7.19356 11.175 7.20997C11.1914 7.22638 11.2006 7.24863 11.2006 7.27184V7.60609C11.2006 7.6293 11.1914 7.65155 11.175 7.66796C11.1586 7.68437 11.1364 7.69359 11.1131 7.69359H10.222V8.03747H11.1131C11.1364 8.03747 11.1586 8.04671 11.175 8.06309C11.1914 8.07954 11.2006 8.1018 11.2006 8.12497V8.45572C11.2011 8.46748 11.1991 8.47917 11.1949 8.49016C11.1907 8.50115 11.1843 8.51116 11.1761 8.51956C11.1679 8.52803 11.1581 8.53475 11.1474 8.53937C11.1365 8.54392 11.1249 8.5463 11.1131 8.5463Z'
            fill='#06C755'
          />
          <path
            d='M6.26306 8.54672C6.28628 8.54672 6.30853 8.53748 6.32494 8.5211C6.34135 8.50472 6.35056 8.48246 6.35056 8.45922V8.12847C6.35056 8.1053 6.34135 8.08304 6.32494 8.06659C6.30853 8.05021 6.28628 8.04097 6.26306 8.04097H5.37188V6.42222C5.37188 6.399 5.36266 6.37675 5.34625 6.36034C5.32984 6.34393 5.30758 6.33472 5.28438 6.33472H4.95188C4.92867 6.33472 4.90641 6.34393 4.89001 6.36034C4.8736 6.37675 4.86438 6.399 4.86438 6.42222V8.45789C4.86438 8.48113 4.8736 8.50339 4.89001 8.51977C4.90641 8.53622 4.92867 8.54539 4.95188 8.54539H6.26438L6.26306 8.54672Z'
            fill='#06C755'
          />
          <path
            d='M7.05214 6.33057H6.72139C6.6721 6.33057 6.63214 6.37052 6.63214 6.41982V8.4568C6.63214 8.50608 6.6721 8.54605 6.72139 8.54605H7.05214C7.10143 8.54605 7.14139 8.50608 7.14139 8.4568V6.41982C7.14139 6.37052 7.10143 6.33057 7.05214 6.33057Z'
            fill='#06C755'
          />
          <path
            d='M9.30534 6.33057H8.97459C8.95135 6.33057 8.92909 6.33979 8.91271 6.35619C8.89626 6.3726 8.88709 6.39486 8.88709 6.41807V7.62819L7.95516 6.36951C7.95303 6.36617 7.95054 6.36309 7.94772 6.36032L7.94247 6.35507L7.93766 6.35113H7.93503L7.93022 6.34763H7.9276L7.92278 6.34501H7.91972H7.91491H7.91185H7.9066H7.9031H7.89785H7.89435H7.88953H7.55222C7.52901 6.34501 7.50676 6.35423 7.49035 6.37063C7.47394 6.38704 7.46472 6.4093 7.46472 6.43251V8.46947C7.46472 8.49271 7.47394 8.51497 7.49035 8.53135C7.50676 8.5478 7.52901 8.55697 7.55222 8.55697H7.88341C7.90661 8.55697 7.92887 8.5478 7.94528 8.53135C7.96169 8.51497 7.97091 8.49271 7.97091 8.46947V7.24757L8.9041 8.50754C8.91026 8.51643 8.91796 8.52421 8.92685 8.5303L8.9321 8.5338H8.93476L8.9391 8.53597H8.94351H8.94659H8.95268C8.96045 8.53786 8.96836 8.5389 8.97634 8.53904H9.30534C9.32851 8.53904 9.35077 8.5298 9.36722 8.51342C9.3836 8.49697 9.39284 8.47478 9.39284 8.45154V6.41982C9.39305 6.40818 9.39095 6.39661 9.38668 6.3858C9.38241 6.37497 9.37597 6.36513 9.36785 6.35681C9.35966 6.3485 9.34993 6.3419 9.33922 6.33739C9.32851 6.33288 9.31696 6.33057 9.30534 6.33057Z'
            fill='#06C755'
          />
        </g>
      )}
    </>
  );
}
