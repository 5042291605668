import { IconProps } from '@/app/components/Icon';

export function Web3IconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M11.9885 2.75L17.25 9.95685L11.9885 17.25' />
          <path
            d='M7.98849 2.75L13.25 9.95685L7.98849 17.25L2.75 9.95685L7.98849 2.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M9.4931 2.75L13.25 7.96875L9.4931 13.25M6.4931 2.75L10.25 7.96875L6.4931 13.25L2.75 7.96875L6.4931 2.75Z' />
        </>
      )}
    </>
  );
}
