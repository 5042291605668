import { Toaster } from '@clerk/ceramic/components/Toast';
import { useLocation } from '@hooks/useLocation';
import { useUIStore } from '@stores/ui.store';
import { cx } from 'cva';
import { motion } from 'framer-motion';
import { ApplicationSwitcher } from '@components/sidebar/ApplicationSwitcher';
import Link from 'next/link';
import { EnvironmentStatus } from '@components/common/EnvironmentStatus';
import { useMembership } from '@hooks/useMembership';
import { useRouter } from 'next/router';
import React from 'react';
import { useCheckout } from '@components/checkout/checkoutContext';
import { PrimaryNavigation } from './PrimaryNavigation';
import { BannerManager } from './AppLayout/BannerManager';
import { UserButton } from '@/app/components/UserButton';
import { OrganizationSwitcher } from '@/app/components/OrganizationSwitcher';
import { HelpCenter } from '@/app/components/HelpCenter';

// Split into a separate component to not re-render the whole app unnecessarily
function HeaderNavigation() {
  const { isHome } = useLocation();
  const { isLoaded } = useMembership();

  return (
    <motion.div
      className='flex items-center'
      initial={{ opacity: 0 }}
      animate={{ opacity: isLoaded ? 1 : 0 }}
      transition={{ duration: 0.15, delay: 0.1 }}
    >
      <OrganizationSwitcher />

      {!isHome && (
        <>
          <div className='flex items-center'>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              className='flex-shrink-0 text-gray-600'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden
            >
              <path
                d='M10 2L6 14'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <ApplicationSwitcher />
          </div>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            className='flex-shrink-0 text-gray-600'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
          >
            <path
              d='M10 2L6 14'
              stroke='currentColor'
              strokeWidth='1.25'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <EnvironmentStatus />
        </>
      )}
    </motion.div>
  );
}

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const setCrumbs = useUIStore(state => state.setCrumbs);
  const router = useRouter();
  const isAppsNew = router.pathname === '/apps/new';
  const { state } = useCheckout();
  const { isHome } = useLocation();

  return (
    <div className='flex flex-col flex-shrink-0 isolate'>
      <BannerManager />

      {!isAppsNew && (
        <div className='flex gap-3 border-b py-2 pl-2 pr-4 md:pl-3.5 md:pr-5 justify-between items-center overflow-x-auto bg-gray-100'>
          <div className='flex items-center gap-1'>
            <Link
              href='/'
              className={cx(
                'relative p-2 outline-none',
                'after:pointer-events-none after:absolute after:left-1/2 after:top-1/2 after:size-9 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:border-2 after:border-legacy-blue-500 after:opacity-0',
                'focus-visible:after:opacity-100',
              )}
              aria-label='Go to dashboard home page'
              onClick={() => setCrumbs([])}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                className='flex-shrink-0'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden
              >
                <ellipse
                  cx='12'
                  cy='12'
                  rx='3.74998'
                  ry='3.75'
                  fill='#131316'
                />
                <path
                  d='M18.7566 20.8788C19.0756 21.1978 19.0436 21.7261 18.6687 21.9772C16.7613 23.2548 14.4672 23.9999 11.9991 23.9999C9.5309 23.9999 7.23678 23.2548 5.32939 21.9772C4.95452 21.7261 4.92248 21.1978 5.24153 20.8788L7.98198 18.1383C8.22966 17.8906 8.6139 17.8515 8.92565 18.0112C9.84746 18.4835 10.8921 18.7499 11.9991 18.7499C13.106 18.7499 14.1507 18.4835 15.0725 18.0112C15.3842 17.8515 15.7685 17.8906 16.0161 18.1383L18.7566 20.8788Z'
                  fill='#131316'
                />
                <path
                  opacity='0.5'
                  d='M18.6696 2.02275C19.0445 2.27385 19.0765 2.80207 18.7575 3.12111L16.017 5.86158C15.7693 6.10927 15.3851 6.14839 15.0733 5.98868C14.1515 5.51644 13.1068 5.25 11.9999 5.25C8.27204 5.25 5.24997 8.27208 5.24997 12C5.24997 13.1069 5.51641 14.1516 5.98866 15.0734C6.14836 15.3852 6.10924 15.7694 5.86156 16.0171L3.1211 18.7575C2.80206 19.0766 2.27384 19.0446 2.02274 18.6697C0.745142 16.7623 0 14.4682 0 12C0 5.37258 5.37256 0 11.9999 0C14.4681 0 16.7622 0.745147 18.6696 2.02275Z'
                  fill='#131316'
                />
              </svg>
            </Link>
            <HeaderNavigation />
          </div>

          <div className='flex items-center gap-3.5'>
            <HelpCenter />
            <UserButton />
          </div>
        </div>
      )}

      {!isHome && !state.isCheckoutVisible && <PrimaryNavigation />}

      <div className='flex flex-1'>
        <main
          className={cx(
            isAppsNew || state.isCheckoutVisible
              ? 'flex h-screen w-full flex-col'
              : 'relative isolate w-full flex-1',
          )}
        >
          <div
            className={cx(
              isAppsNew || state.isCheckoutVisible
                ? 'h-full w-full overflow-hidden'
                : 'max-sm:px-3 max-sm:w-full max-sm:overflow-x-auto sm:mx-auto pb-20 sm:w-[calc(100%-theme(spacing.10))] max-w-6xl gap-12 lg:pb-10 mt-4 lg:mt-10',
            )}
          >
            {children}
          </div>
        </main>
      </div>

      <Toaster />
    </div>
  );
};
