import React from 'react';

const SalesCallIcon = () => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.5 11H29.5V27C29.5 28.6569 28.1569 30 26.5 30H6.5C4.84315 30 3.5 28.6569 3.5 27V11Z'
        fill='currentColor'
        fillOpacity={0.15}
      />
      <path
        d='M29.5 10.303V7.15152C29.5 5.41098 28.0891 4 26.3485 4H6.65152C4.91098 4 3.5 5.41098 3.5 7.15152V10.303M29.5 10.303H3.5M29.5 10.303V14.4394M3.5 10.303V26.8485C3.5 28.5891 4.91098 30 6.65152 30H11.5758'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M24.6727 16.1015C24.9952 15.0042 26.0015 14.25 27.1453 14.25H27.6032H28.773C30.1542 14.25 31.3959 15.381 31.2367 16.878C31.0062 19.0449 30.3252 21.1583 29.2278 23.0517C27.8621 25.4079 25.8986 27.3734 23.5446 28.7406C21.6398 29.8469 19.5547 30.4973 17.4063 30.7354C15.902 30.9022 14.75 29.6584 14.75 28.2607V26.6571C14.75 25.5135 15.5027 24.5059 16.5998 24.1828L18.1366 23.7304C19.0411 23.4641 20.0182 23.7244 20.6708 24.4048C20.9964 24.7442 21.4526 24.7897 21.7618 24.5896C23.0883 23.731 24.221 22.5971 25.0789 21.2691C25.2794 20.9586 25.2334 20.5009 24.8942 20.175C24.2145 19.5218 23.955 18.5443 24.2208 17.6399L24.6727 16.1015Z'
        fill='white'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 4V2M11.5 4V2'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.51562 17C10.0679 17 10.5156 16.5523 10.5156 16C10.5156 15.4477 10.0679 15 9.51562 15C8.96334 15 8.51562 15.4477 8.51562 16C8.51562 16.5523 8.96334 17 9.51562 17Z'
        fill='currentColor'
      />
      <path
        d='M13.9531 17C14.5054 17 14.9531 16.5523 14.9531 16C14.9531 15.4477 14.5054 15 13.9531 15C13.4008 15 12.9531 15.4477 12.9531 16C12.9531 16.5523 13.4008 17 13.9531 17Z'
        fill='currentColor'
      />
      <path
        d='M18.3906 17C18.9429 17 19.3906 16.5523 19.3906 16C19.3906 15.4477 18.9429 15 18.3906 15C17.8383 15 17.3906 15.4477 17.3906 16C17.3906 16.5523 17.8383 17 18.3906 17Z'
        fill='currentColor'
      />
      <path
        d='M9.51562 21.0312C10.0679 21.0312 10.5156 20.5835 10.5156 20.0312C10.5156 19.479 10.0679 19.0312 9.51562 19.0312C8.96334 19.0312 8.51562 19.479 8.51562 20.0312C8.51562 20.5835 8.96334 21.0312 9.51562 21.0312Z'
        fill='currentColor'
      />
      <path
        d='M13.9531 21.0312C14.5054 21.0312 14.9531 20.5835 14.9531 20.0312C14.9531 19.479 14.5054 19.0312 13.9531 19.0312C13.4008 19.0312 12.9531 19.479 12.9531 20.0312C12.9531 20.5835 13.4008 21.0312 13.9531 21.0312Z'
        fill='currentColor'
      />
      <path
        d='M9.51562 25.0625C10.0679 25.0625 10.5156 24.6148 10.5156 24.0625C10.5156 23.5102 10.0679 23.0625 9.51562 23.0625C8.96334 23.0625 8.51562 23.5102 8.51562 24.0625C8.51562 24.6148 8.96334 25.0625 9.51562 25.0625Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default SalesCallIcon;
