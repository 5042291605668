import { IconProps } from '@/app/components/Icon';

export function GitlabIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <g stroke='none'>
          <path
            d='M9.9996 18.2892L13.3157 8.08899H6.68835L9.9996 18.2892Z'
            fill='#E24329'
          />
          <path
            d='M2.04347 8.08899L1.03364 11.1879C0.942262 11.4693 1.04095 11.78 1.28214 11.9554L9.99952 18.2891L2.04347 8.08899Z'
            fill='#FCA326'
          />
          <path
            d='M2.04327 8.0895H6.68802L4.68905 1.94641C4.58675 1.63212 4.14087 1.63212 4.03489 1.94641L2.04327 8.0895Z'
            fill='#E24329'
          />
          <path
            d='M17.9602 8.08899L18.9664 11.1879C19.0578 11.4693 18.9591 11.78 18.7179 11.9554L9.99927 18.2891L17.9602 8.08899Z'
            fill='#FCA326'
          />
          <path
            d='M17.9601 8.0895H13.3154L15.3107 1.94641C15.413 1.63212 15.8589 1.63212 15.9648 1.94641L17.9601 8.0895Z'
            fill='#E24329'
          />
          <path
            d='M9.99927 18.2891L13.3154 8.08899H17.9602L9.99927 18.2891Z'
            fill='#FC6D26'
          />
          <path
            d='M9.99926 18.2891L2.04327 8.08899H6.68802L9.99926 18.2891Z'
            fill='#FC6D26'
          />
        </g>
      ) : (
        <g stroke='none'>
          <path
            d='M7.99967 14.4473L10.5788 6.51379H5.42426L7.99967 14.4473Z'
            fill='#E24329'
          />
          <path
            d='M1.81159 6.51379L1.02617 8.9241C0.955093 9.14292 1.03185 9.38456 1.21944 9.52099L7.99962 14.4472L1.81159 6.51379Z'
            fill='#FCA326'
          />
          <path
            d='M1.8114 6.51403H5.42398L3.86923 1.73607C3.78966 1.49162 3.44287 1.49162 3.36043 1.73607L1.8114 6.51403Z'
            fill='#E24329'
          />
          <path
            d='M14.1913 6.51379L14.9739 8.9241C15.0449 9.14292 14.9682 9.38456 14.7806 9.52099L7.99945 14.4472L14.1913 6.51379Z'
            fill='#FCA326'
          />
          <path
            d='M14.1913 6.51403H10.5787L12.1306 1.73607C12.2102 1.49162 12.557 1.49162 12.6394 1.73607L14.1913 6.51403Z'
            fill='#E24329'
          />
          <path
            d='M7.99945 14.4472L10.5787 6.51379H14.1913L7.99945 14.4472Z'
            fill='#FC6D26'
          />
          <path
            d='M7.99939 14.4472L1.8114 6.51379H5.42398L7.99939 14.4472Z'
            fill='#FC6D26'
          />
        </g>
      )}
    </>
  );
}
