import { IconProps } from '@/app/components/Icon';

export function QuestionMarkCircleIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 14.0041 14.0041 17.25 10 17.25C5.99594 17.25 2.75 14.0041 2.75 10C2.75 5.99594 5.99594 2.75 10 2.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M7.75 8.25C7.75 6.5 8.75736 5.75 10 5.75C11.2426 5.75 12.25 6.67341 12.25 7.8125C12.25 8.47011 11.9143 9.05584 11.3913 9.4335C10.7406 9.90347 10 10.4906 10 11.25M10 13.76V13.75' />
        </>
      ) : (
        <>
          <path d='M6.75 7.13889C6.75 6.16667 7.30964 5.75 8 5.75C8.69036 5.75 9.25 6.26301 9.25 6.89583C9.25 7.26117 9.06348 7.58658 8.77296 7.79639C8.6165 7.90939 8.45068 8.07101 8.31292 8.25219M8 10.76V10.75M13.25 8C13.25 5.10051 10.8995 2.75 8 2.75C5.10051 2.75 2.75 5.10051 2.75 8C2.75 10.8995 5.10051 13.25 8 13.25C10.8995 13.25 13.25 10.8995 13.25 8Z' />
        </>
      )}
    </>
  );
}
