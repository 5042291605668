import { IconProps } from '@/app/components/Icon';

export function BuildingIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M16.25 17.25V3.79547C16.25 3.24319 15.8023 2.79547 15.25 2.79547H4.75C4.19772 2.79547 3.75 3.24319 3.75 3.79547V9.25V17.25H16.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M16.25 17.25V3.79547C16.25 3.24319 15.8023 2.79547 15.25 2.79547H4.75C4.19772 2.79547 3.75 3.24319 3.75 3.79547V9.25V17.25M16.25 17.25H3.75M16.25 17.25H17.25M3.75 17.25H2.75' />
          <path d='M8.75 13.75V17.25H11.25V13.75H8.75Z' />
          <path d='M6.75 6.25V5.75' />
          <path d='M6.75 10.25V9.75' />
          <path d='M13.25 6.25V5.75' />
          <path d='M13.25 10.25V9.75' />
          <path d='M10 6.25V5.75' />
          <path d='M10 10.25V9.75' />
        </>
      ) : (
        <>
          <path d='M2.75 13.25H3.75M13.25 13.25H12.25M3.75 13.25V3.75C3.75 3.19772 4.19772 2.75 4.75 2.75H11.25C11.8023 2.75 12.25 3.19772 12.25 3.75V13.25M3.75 13.25H12.25' />
          <path d='M6.75 13.25V10.75H9.25V13.25' />
          <path d='M6.75 6.25V5.75' />
          <path d='M9.25 6.25V5.75' />
        </>
      )}
    </>
  );
}
