import { IconProps } from '@/app/components/Icon';

export function TiktokIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <g stroke='none'>
          <path
            d='M8.24183 8.10563V7.38579C7.99765 7.35175 7.75144 7.33432 7.5049 7.33362C4.49587 7.33362 2.04779 9.78022 2.04779 12.7901C2.04794 13.6673 2.2597 14.5316 2.66509 15.3095C3.07049 16.0874 3.65756 16.756 4.37649 17.2586C3.4683 16.2835 2.92886 14.9771 2.92886 13.5427C2.92721 12.1216 3.48256 10.7564 4.47579 9.73993C5.46902 8.7235 6.82103 8.13678 8.24183 8.10563Z'
            fill='#25F4EE'
          />
          <path
            d='M17.0879 5.80629V5.12596C16.29 5.12648 15.5094 4.89436 14.8414 4.45801C15.4174 5.11948 16.2006 5.61617 17.0879 5.80629Z'
            fill='#25F4EE'
          />
          <path
            d='M14.8414 4.45788C14.187 3.70879 13.8266 2.74772 13.8271 1.75305H13.0312C13.1342 2.30292 13.3478 2.82617 13.6589 3.29109C13.9701 3.75602 14.3723 4.15296 14.8414 4.45788Z'
            fill='#FE2C55'
          />
          <path
            d='M7.50486 10.2974C6.94705 10.2967 6.40515 10.4832 5.96593 10.8271C5.52672 11.1709 5.21559 11.6522 5.08239 12.1939C4.94919 12.7355 5.00162 13.3062 5.23128 13.8146C5.46094 14.3229 5.85454 14.7395 6.34906 14.9975C6.08025 14.6258 5.91946 14.187 5.88443 13.7296C5.8494 13.2722 5.94151 12.814 6.15057 12.4057C6.35963 11.9973 6.67751 11.6547 7.06909 11.4157C7.46066 11.1768 7.91068 11.0507 8.36942 11.0514C8.61896 11.0517 8.86698 11.0902 9.10488 11.1655V8.13819C8.86121 8.10382 8.6155 8.086 8.36942 8.08484C8.32639 8.08484 8.28364 8.0872 8.24061 8.08808V10.4127C8.00265 10.337 7.75454 10.2982 7.50486 10.2974Z'
            fill='#FE2C55'
          />
          <path
            d='M8.36949 16.0528C9.71217 16.0528 10.8264 14.9669 10.875 13.6357L10.8792 1.77083L13.031 1.75255C12.9848 1.50436 12.9614 1.25246 12.9611 1H9.99781L9.99309 12.8831C9.97856 13.3039 9.85742 13.7142 9.64103 14.0753C9.42463 14.4365 9.12007 14.7368 8.75589 14.9482C8.39171 15.1595 7.97984 15.2749 7.55888 15.2835C7.13792 15.2921 6.72166 15.1937 6.34912 14.9975C6.80219 15.6239 7.53794 16.0528 8.36949 16.0528Z'
            fill='#25F4EE'
          />
          <path
            d='M17.0879 5.7865V8.09103C15.6077 8.09278 14.1646 7.6287 12.9629 6.76455V12.79C12.9629 15.7993 10.5145 18.2477 7.50515 18.2477C6.38555 18.2485 5.2931 17.903 4.37762 17.2584C4.88722 17.8079 5.50482 18.2462 6.19172 18.5459C6.87862 18.8456 7.62 19.0002 8.36943 18.9999C11.3788 18.9999 13.8271 16.5533 13.8271 13.5425V7.51681C15.0289 8.38083 16.472 8.8449 17.9522 8.84328V5.87817C17.6617 5.87806 17.372 5.84733 17.0879 5.7865Z'
            fill='#FE2C55'
          />
          <path
            d='M12.9627 12.7899V6.76417C14.1645 7.62832 15.6076 8.0924 17.0878 8.09065V5.78641C16.215 5.59825 15.4267 5.13208 14.8413 4.45788C14.3726 4.15282 13.9706 3.75581 13.6597 3.29089C13.3488 2.82597 13.1355 2.3028 13.0326 1.75305H10.8619L10.8572 13.6362C10.8389 14.1551 10.6587 14.6554 10.3419 15.0667C10.025 15.4781 9.58731 15.78 9.0902 15.9301C8.5931 16.0802 8.06149 16.071 7.56989 15.9037C7.07829 15.7365 6.65136 15.4196 6.34895 14.9974C5.85443 14.7394 5.46083 14.3228 5.23117 13.8145C5.00151 13.3061 4.94909 12.7355 5.08229 12.1938C5.21549 11.6521 5.52661 11.1708 5.96583 10.827C6.40504 10.4831 6.94695 10.2966 7.50475 10.2973C7.75483 10.298 8.00334 10.3368 8.24168 10.4126V8.08799C5.2919 8.15697 2.91309 10.5762 2.91309 13.5413C2.91183 14.9212 3.43535 16.2501 4.37751 17.2583C5.29291 17.9028 6.38524 18.2484 7.50475 18.2476C10.5144 18.2476 12.9627 15.7992 12.9627 12.7899Z'
            fill='black'
          />
        </g>
      ) : (
        <g stroke='none'>
          <path
            d='M6.63256 6.5266V5.96673C6.44264 5.94025 6.25114 5.92669 6.05939 5.92615C3.71902 5.92615 1.81494 7.82907 1.81494 10.1701C1.81506 10.8524 1.97976 11.5246 2.29507 12.1296C2.61038 12.7346 3.06699 13.2547 3.62616 13.6456C2.91979 12.8872 2.50022 11.8711 2.50022 10.7555C2.49894 9.65012 2.93088 8.58829 3.7034 7.79773C4.47591 7.00717 5.52748 6.55083 6.63256 6.5266Z'
            fill='#25F4EE'
          />
          <path
            d='M13.5129 4.73825V4.2091C12.8923 4.2095 12.2851 4.02896 11.7656 3.68958C12.2136 4.20405 12.8228 4.59037 13.5129 4.73825Z'
            fill='#25F4EE'
          />
          <path
            d='M11.7656 3.68946C11.2566 3.10683 10.9763 2.35933 10.9767 1.58569H10.3577C10.4378 2.01337 10.6039 2.42034 10.8459 2.78196C11.0879 3.14357 11.4008 3.4523 11.7656 3.68946Z'
            fill='#FE2C55'
          />
          <path
            d='M6.05935 8.23139C5.6255 8.23084 5.20402 8.37591 4.8624 8.64335C4.52079 8.91079 4.2788 9.28513 4.1752 9.70643C4.0716 10.1277 4.11238 10.5716 4.291 10.967C4.46963 11.3624 4.77577 11.6863 5.16039 11.8871C4.95132 11.5979 4.82626 11.2566 4.79901 10.9009C4.77177 10.5451 4.84341 10.1887 5.00601 9.87115C5.16862 9.55356 5.41586 9.2871 5.72042 9.10121C6.02498 8.91533 6.37499 8.81727 6.7318 8.81785C6.92588 8.81809 7.11879 8.84801 7.30382 8.90658V6.552C7.1143 6.52526 6.92319 6.5114 6.7318 6.5105C6.69832 6.5105 6.66508 6.51233 6.63161 6.51302V8.32103C6.44652 8.26222 6.25355 8.23199 6.05935 8.23139Z'
            fill='#FE2C55'
          />
          <path
            d='M6.73187 12.7078C7.77618 12.7078 8.64282 11.8632 8.68065 10.8278L8.68386 1.59954L10.3575 1.58532C10.3216 1.39228 10.3034 1.19636 10.3032 1H7.99835L7.99468 10.2425C7.98338 10.5698 7.88916 10.8888 7.72085 11.1698C7.55254 11.4507 7.31566 11.6843 7.03241 11.8486C6.74915 12.013 6.42881 12.1027 6.10139 12.1094C5.77398 12.1162 5.45022 12.0396 5.16046 11.887C5.51285 12.3742 6.0851 12.7078 6.73187 12.7078Z'
            fill='#25F4EE'
          />
          <path
            d='M13.5129 4.7229V6.51532C12.3617 6.51668 11.2392 6.15573 10.3045 5.48361V10.1701C10.3045 12.5107 8.40021 14.415 6.05961 14.415C5.1888 14.4156 4.33912 14.1469 3.62708 13.6456C4.02343 14.0729 4.50379 14.4139 5.03805 14.6469C5.5723 14.88 6.14894 15.0002 6.73183 15.0001C9.07243 15.0001 10.9767 13.0972 10.9767 10.7554V6.06871C11.9114 6.74073 13.0339 7.10167 14.1851 7.10041V4.7942C13.9592 4.79411 13.7338 4.77021 13.5129 4.7229Z'
            fill='#FE2C55'
          />
          <path
            d='M10.3044 10.17V5.48326C11.2391 6.15538 12.3616 6.51633 13.5128 6.51496V4.72277C12.8339 4.57642 12.2208 4.21385 11.7656 3.68946C11.401 3.45219 11.0883 3.14341 10.8465 2.7818C10.6047 2.42019 10.4388 2.01328 10.3588 1.58569H8.67043L8.66676 10.8282C8.65256 11.2318 8.51241 11.6209 8.26594 11.9408C8.01948 12.2608 7.67907 12.4956 7.29243 12.6124C6.90579 12.7291 6.49231 12.7219 6.10996 12.5918C5.7276 12.4618 5.39554 12.2153 5.16033 11.8869C4.7757 11.6862 4.46957 11.3622 4.29094 10.9669C4.11232 10.5715 4.07154 10.1276 4.17514 9.70631C4.27874 9.28501 4.52073 8.91067 4.86234 8.64323C5.20396 8.37579 5.62544 8.23072 6.05929 8.23127C6.2538 8.23178 6.44709 8.26201 6.63246 8.32091V6.5129C4.33817 6.56655 2.48798 8.44815 2.48798 10.7544C2.487 11.8277 2.89418 12.8612 3.62698 13.6454C4.33896 14.1467 5.18856 14.4155 6.05929 14.4149C8.40012 14.4149 10.3044 12.5106 10.3044 10.17Z'
            fill='black'
          />
        </g>
      )}
    </>
  );
}
