import { IconProps } from '@/app/components/Icon';

export function EllipsisHorizontalIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M6 10V10.01M10 10V10.01M14 10V10.01' strokeWidth='2' />
        </>
      ) : (
        <g strokeWidth='2'>
          <path d='M4 8V8.01' />
          <path d='M8 8V8.01' />
          <path d='M12 8V8.01' />
        </g>
      )}
    </>
  );
}
