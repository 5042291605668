import { IconProps } from '@/app/components/Icon';

export function EnterpriseConnectionsIconPath(props: {
  size: IconProps['size'];
}) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M9 5.5L11.5 5.5' />
          <path d='M7.75 7.75L12.25 12.25' />
          <rect
            x='2.75146'
            y='2.74658'
            width='5.5'
            height='5.5'
            rx='1'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <rect
            x='11.75'
            y='2.74658'
            width='5.5'
            height='5.5'
            rx='1'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <rect
            x='11.75'
            y='11.75'
            width='5.5'
            height='5.5'
            rx='1'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <rect x='2.75' y='11.75' width='5.5' height='5.5' rx='1' />
        </>
      ) : (
        <>
          <path d='M6.26013 4.49548L9.74622 4.49548M6.24622 6.2514L9.74792 9.75311M3.75208 6.24548H5.25208C5.80436 6.24548 6.25208 5.79777 6.25208 5.24548V3.74548C6.25208 3.1932 5.80436 2.74548 5.25208 2.74548H3.75208C3.19979 2.74548 2.75208 3.1932 2.75208 3.74548V5.24548C2.75208 5.79777 3.19979 6.24548 3.75208 6.24548ZM10.7462 6.24548H12.2462C12.7985 6.24548 13.2462 5.79777 13.2462 5.24548V3.74548C13.2462 3.1932 12.7985 2.74548 12.2462 2.74548H10.7462C10.1939 2.74548 9.74622 3.1932 9.74622 3.74548V5.24548C9.74622 5.79777 10.1939 6.24548 10.7462 6.24548ZM10.7479 13.2531H12.2479C12.8002 13.2531 13.2479 12.8053 13.2479 12.2531V10.7531C13.2479 10.2008 12.8002 9.75305 12.2479 9.75305H10.7479C10.1956 9.75305 9.74792 10.2008 9.74792 10.7531V12.2531C9.74792 12.8053 10.1956 13.2531 10.7479 13.2531ZM3.76013 13.2546H5.26013C5.81242 13.2546 6.26013 12.8069 6.26013 12.2546V10.7546C6.26013 10.2023 5.81242 9.75458 5.26013 9.75458H3.76013C3.20785 9.75458 2.76013 10.2023 2.76013 10.7546V12.2546C2.76013 12.8069 3.20785 13.2546 3.76013 13.2546Z' />
        </>
      )}
    </>
  );
}
