import { IconProps } from '@/app/components/Icon';

export function EyeIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            fillRule='evenodd'
            d='M10 16.25C15 16.25 17.25 11.5 17.25 10C17.25 8.5 15 3.75 10 3.75C5 3.75 2.75 8.5 2.75 10C2.75 11.5 5 16.25 10 16.25ZM10 12.25C11.2426 12.25 12.25 11.2426 12.25 10C12.25 8.75736 11.2426 7.75 10 7.75C8.75736 7.75 7.75 8.75736 7.75 10C7.75 11.2426 8.75736 12.25 10 12.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M16.5 10C16.5 10.2278 16.4029 10.6966 16.1339 11.307C15.8728 11.8999 15.4742 12.57 14.9324 13.1973C13.8537 14.4463 12.2354 15.5 10 15.5V17C12.7646 17 14.7713 15.6787 16.0676 14.1777C16.7133 13.43 17.1897 12.6314 17.5067 11.9117C17.8159 11.2097 18 10.5222 18 10H16.5ZM10 4.5C12.2354 4.5 13.8537 5.55373 14.9324 6.80271C15.4742 7.43004 15.8728 8.10015 16.1339 8.69295C16.4029 9.30345 16.5 9.77221 16.5 10H18C18 9.47779 17.8159 8.7903 17.5067 8.0883C17.1897 7.3686 16.7133 6.56996 16.0676 5.82229C14.7713 4.32127 12.7646 3 10 3V4.5ZM3.5 10C3.5 9.77221 3.59714 9.30345 3.86605 8.69295C4.12717 8.10015 4.52583 7.43004 5.06762 6.80271C6.14629 5.55373 7.76464 4.5 10 4.5V3C7.23536 3 5.22871 4.32127 3.93238 5.82229C3.28667 6.56996 2.81033 7.3686 2.49332 8.0883C2.18411 8.7903 2 9.47779 2 10H3.5ZM10 15.5C7.76464 15.5 6.14629 14.4463 5.06762 13.1973C4.52583 12.57 4.12717 11.8999 3.86605 11.307C3.59714 10.6966 3.5 10.2278 3.5 10H2C2 10.5222 2.18411 11.2097 2.49332 11.9117C2.81033 12.6314 3.28667 13.43 3.93238 14.1777C5.22871 15.6787 7.23536 17 10 17V15.5ZM11.5 10C11.5 10.8284 10.8284 11.5 10 11.5V13C11.6569 13 13 11.6569 13 10H11.5ZM10 8.5C10.8284 8.5 11.5 9.17157 11.5 10H13C13 8.34315 11.6569 7 10 7V8.5ZM8.5 10C8.5 9.17157 9.17157 8.5 10 8.5V7C8.34315 7 7 8.34315 7 10H8.5ZM10 11.5C9.17157 11.5 8.5 10.8284 8.5 10H7C7 11.6569 8.34315 13 10 13V11.5Z'
            stroke='none'
          />
        </>
      ) : (
        <>
          <path d='M13.25 8C13.25 8.5 12.2 12.75 8 12.75C3.8 12.75 2.75 8.5 2.75 8C2.75 7.5 3.8 3.25 8 3.25C12.2 3.25 13.25 7.5 13.25 8Z' />
          <circle cx='8' cy='8' r='1.25' />
        </>
      )}
    </>
  );
}
