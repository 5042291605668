import { IconProps } from '@/app/components/Icon';

export function SlackIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <g stroke='none'>
          <path
            d='M4.7818 12.3744C4.7818 13.4151 3.93163 14.2653 2.8909 14.2653C1.85017 14.2653 1 13.4151 1 12.3744C1 11.3337 1.85017 10.4835 2.8909 10.4835H4.7818V12.3744Z'
            fill='#E01E5A'
          />
          <path
            d='M5.73474 12.3744C5.73474 11.3337 6.58491 10.4835 7.62564 10.4835C8.66637 10.4835 9.51653 11.3337 9.51653 12.3744V17.1089C9.51653 18.1497 8.66637 18.9999 7.62564 18.9999C6.58491 18.9999 5.73474 18.1497 5.73474 17.1089V12.3744Z'
            fill='#E01E5A'
          />
          <path
            d='M7.62564 4.7818C6.58491 4.7818 5.73474 3.93163 5.73474 2.8909C5.73474 1.85017 6.58491 1 7.62564 1C8.66637 1 9.51653 1.85017 9.51653 2.8909V4.7818H7.62564Z'
            fill='#36C5F0'
          />
          <path
            d='M7.62547 5.73474C8.6662 5.73474 9.51637 6.58491 9.51637 7.62564C9.51637 8.66637 8.6662 9.51653 7.62547 9.51653H2.8909C1.85017 9.51653 1 8.66637 1 7.62564C1 6.58491 1.85017 5.73474 2.8909 5.73474H7.62547Z'
            fill='#36C5F0'
          />
          <path
            d='M15.2183 7.62564C15.2183 6.58491 16.0684 5.73474 17.1091 5.73474C18.1499 5.73474 19 6.58491 19 7.62564C19 8.66637 18.1499 9.51653 17.1091 9.51653H15.2183V7.62564Z'
            fill='#2EB67D'
          />
          <path
            d='M14.2653 7.62547C14.2653 8.6662 13.4151 9.51637 12.3743 9.51637C11.3337 9.51637 10.4835 8.6662 10.4835 7.62547V2.8909C10.4835 1.85017 11.3337 1 12.3743 1C13.4151 1 14.2653 1.85017 14.2653 2.8909V7.62547Z'
            fill='#2EB67D'
          />
          <path
            d='M12.3743 15.2183C13.4151 15.2183 14.2653 16.0685 14.2653 17.1092C14.2653 18.15 13.4151 19.0001 12.3743 19.0001C11.3337 19.0001 10.4835 18.15 10.4835 17.1092V15.2183H12.3743Z'
            fill='#ECB22E'
          />
          <path
            d='M12.3743 14.2653C11.3337 14.2653 10.4835 13.4151 10.4835 12.3744C10.4835 11.3337 11.3337 10.4835 12.3743 10.4835H17.1089C18.1497 10.4835 18.9999 11.3337 18.9999 12.3744C18.9999 13.4151 18.1497 14.2653 17.1089 14.2653H12.3743Z'
            fill='#ECB22E'
          />
        </g>
      ) : (
        <g stroke='none'>
          <path
            d='M3.9414 9.84681C3.9414 10.6562 3.28015 11.3175 2.4707 11.3175C1.66124 11.3175 1 10.6562 1 9.84681C1 9.03733 1.66124 8.3761 2.4707 8.3761H3.9414V9.84681Z'
            fill='#E01E5A'
          />
          <path
            d='M4.68256 9.84681C4.68256 9.03733 5.3438 8.3761 6.15326 8.3761C6.96271 8.3761 7.62395 9.03733 7.62395 9.84681V13.5292C7.62395 14.3387 6.96271 14.9999 6.15326 14.9999C5.3438 14.9999 4.68256 14.3387 4.68256 13.5292V9.84681Z'
            fill='#E01E5A'
          />
          <path
            d='M6.15326 3.9414C5.3438 3.9414 4.68256 3.28015 4.68256 2.4707C4.68256 1.66124 5.3438 1 6.15326 1C6.96271 1 7.62395 1.66124 7.62395 2.4707V3.9414H6.15326Z'
            fill='#36C5F0'
          />
          <path
            d='M6.15314 4.6825C6.9626 4.6825 7.62384 5.34374 7.62384 6.1532C7.62384 6.96265 6.9626 7.62389 6.15314 7.62389H2.4707C1.66124 7.62389 1 6.96265 1 6.1532C1 5.34374 1.66124 4.6825 2.4707 4.6825H6.15314Z'
            fill='#36C5F0'
          />
          <path
            d='M12.0587 6.1532C12.0587 5.34374 12.7199 4.6825 13.5293 4.6825C14.3388 4.6825 15 5.34374 15 6.1532C15 6.96265 14.3388 7.62389 13.5293 7.62389H12.0587V6.1532Z'
            fill='#2EB67D'
          />
          <path
            d='M11.3174 6.15314C11.3174 6.9626 10.6562 7.62384 9.84668 7.62384C9.03726 7.62384 8.37604 6.9626 8.37604 6.15314V2.4707C8.37604 1.66124 9.03726 1 9.84668 1C10.6562 1 11.3174 1.66124 11.3174 2.4707V6.15314Z'
            fill='#2EB67D'
          />
          <path
            d='M9.84668 12.0587C10.6562 12.0587 11.3174 12.72 11.3174 13.5294C11.3174 14.3389 10.6562 15.0001 9.84668 15.0001C9.03726 15.0001 8.37604 14.3389 8.37604 13.5294V12.0587H9.84668Z'
            fill='#ECB22E'
          />
          <path
            d='M9.84668 11.3175C9.03726 11.3175 8.37604 10.6562 8.37604 9.84681C8.37604 9.03733 9.03726 8.3761 9.84668 8.3761H13.5291C14.3386 8.3761 14.9999 9.03733 14.9999 9.84681C14.9999 10.6562 14.3386 11.3175 13.5291 11.3175H9.84668Z'
            fill='#ECB22E'
          />
        </g>
      )}
    </>
  );
}
