import { IconProps } from '@/app/components/Icon';

export function DisconnectIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M3.9844 16.0469C6.45311 18.5156 9.32657 16.6444 10.9668 15.0042L11.2812 14.6897L5.34162 8.75L5.02707 9.06454C3.38686 10.7047 1.5157 13.5782 3.9844 16.0469Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M3.9844 16.0469C6.45311 18.5156 9.32657 16.6444 10.9668 15.0042L11.2812 14.6897L5.34162 8.75L5.02707 9.06454C3.38686 10.7047 1.5157 13.5782 3.9844 16.0469ZM3.9844 16.0469L2.78017 17.2511' />
          <path
            d='M16.0469 3.98432C13.5624 1.49991 10.7036 3.38793 9.0634 5.02813L8.74892 5.34262L14.6886 11.2823L15.0031 10.9678C16.6433 9.32761 18.5313 6.46873 16.0469 3.98432Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M16.0469 3.98432C13.5624 1.49991 10.7036 3.38793 9.0634 5.02813L8.74892 5.34262L14.6886 11.2823L15.0031 10.9678C16.6433 9.32761 18.5313 6.46873 16.0469 3.98432ZM16.0469 3.98432L17.25 2.78124' />
          <path d='M8.25391 8.75391L6.86328 10.1445' />
          <path d='M11.2461 11.7461L9.93359 13.0586' />
        </>
      ) : (
        <>
          <path d='M3.875 12.1562L2.75 13.25M3.875 12.1562C5.78125 14.0625 7.35793 13.1264 8.71094 11.7734L4.22655 7.28905C2.87745 8.63816 1.96875 10.25 3.875 12.1562Z' />
          <path d='M12.1328 3.85156L13.2578 2.75781M12.1328 3.85156C10.1875 1.90624 8.64988 2.88136 7.29687 4.23437L11.7813 8.71875C13.1304 7.36965 14.0781 5.79688 12.1328 3.85156Z' />
          <path d='M6.05469 7.96096L6.99219 7.02344' />
          <path d='M8.02344 10.0078L9 9.03125' />
        </>
      )}
    </>
  );
}
