import { IconProps } from '@/app/components/Icon';

export function WebhooksIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M12.0519 8.25906C11.8396 7.8132 12.0012 7.28868 12.3154 6.90773C12.7431 6.38923 13 5.72462 13 5C13 3.34315 11.6569 2 10 2C8.34315 2 7 3.34315 7 5C7 5.80083 7.31379 6.52838 7.82513 7.06639C8.13731 7.39486 8.32764 7.85205 8.19569 8.28557L7.2953 11.244C7.08976 11.9193 6.2356 12.1752 5.54112 12.0487C5.3656 12.0167 5.18475 12 5 12C3.34315 12 2 13.3431 2 15C2 16.6569 3.34315 18 5 18C6.18963 18 7.21754 17.3076 7.70266 16.3037C7.9152 15.864 8.31629 15.5 8.80473 15.5H11.1953C11.6837 15.5 12.0848 15.864 12.2973 16.3037C12.7825 17.3076 13.8104 18 15 18C16.6569 18 18 16.6569 18 15C18 13.3431 16.6569 12 15 12C14.9421 12 14.8846 12.0016 14.8276 12.0049C14.3066 12.0344 13.7543 11.8341 13.53 11.363L12.0519 8.25906Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M12.25 5.25V5C12.25 3.75736 11.2426 2.75 10 2.75C8.75736 2.75 7.75 3.75736 7.75 5C7.75 5.88343 8.25914 6.64794 9 7.01612C9.08094 7.05634 9.16464 7.09183 9.25075 7.12223L7.75 12.25M13.5811 16.7463C13.9682 17.0612 14.4621 17.25 15 17.25C16.2426 17.25 17.25 16.2426 17.25 15C17.25 13.7574 16.2426 12.75 15 12.75H13.75L11.75 8.75M5 12.75C3.75736 12.75 2.75 13.7574 2.75 15C2.75 16.2426 3.75736 17.25 5 17.25C6.24264 17.25 7.25 16.2426 7.25 15V14.75H11.25' />
        </>
      ) : (
        <>
          <path d='M10 4.5C10 3.5335 9.2165 2.75 8.25 2.75C7.2835 2.75 6.5 3.5335 6.5 4.5C6.5 5.13658 6.8399 5.69378 7.3481 6L5.75 9.25' />
          <path d='M10.5 12.9363C10.7835 13.134 11.1282 13.25 11.5 13.25C12.4665 13.25 13.25 12.4665 13.25 11.5C13.25 10.5335 12.4665 9.75 11.5 9.75H11L10.25 7.75' />
          <path d='M3.26256 10.2626C2.94588 10.5793 2.75 11.0168 2.75 11.5C2.75 12.4665 3.5335 13.25 4.5 13.25C5.38134 13.25 6.11051 12.5985 6.23216 11.7508H8.25' />
        </>
      )}
    </>
  );
}
