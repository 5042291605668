import { IconProps } from '@/app/components/Icon';

export function RotateAntiClockwiseIconPath(props: {
  size: IconProps['size'];
}) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M2.75 2.75V7.25M2.75 7.25H7.25M2.75 7.25C2.75 7.25 5.99594 2.75 10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 14.0041 14.0041 17.25 10 17.25C7.36297 17.25 5.03377 15.8238 3.75268 13.7079' />
        </>
      ) : (
        <>
          <path d='M2.75 2.75V6.00862M2.75 6.00862H6.00862M2.75 6.00862C2.75 6.00862 5.10051 2.75 8 2.75C10.8995 2.75 13.25 5.10051 13.25 8C13.25 10.8995 10.8995 13.25 8 13.25C6.09043 13.25 4.40376 12.2172 3.47608 10.685' />
        </>
      )}
    </>
  );
}
