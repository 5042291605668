import { IconProps } from '@/app/components/Icon';

export function BellIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M10 2.75C6.54822 2.75 4.75 5.54822 4.75 9V11.4722L2.75 14.75H7.75H12.25H17.25L15.25 11.4722V9C15.25 5.54822 13.4518 2.75 10 2.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M7.75 14.75H2.75L4.75 11.4722V9C4.75 5.54822 6.54822 2.75 10 2.75C13.4518 2.75 15.25 5.54822 15.25 9V11.4722L17.25 14.75H12.25M7.75 14.75C7.75 14.75 7.75 17.25 10 17.25C12.25 17.25 12.25 14.75 12.25 14.75M7.75 14.75H12.25' />
        </>
      ) : (
        <>
          <path d='M8 2.75C5.65279 2.75 4.25 4.65279 4.25 7V8.75L2.75 11.25H13.25L11.75 8.75V7C11.75 4.65279 10.3472 2.75 8 2.75Z' />
          <path d='M5.75 11.25C5.75 11.25 5.75 13.25 8 13.25C10.25 13.25 10.25 11.25 10.25 11.25' />
        </>
      )}
    </>
  );
}
