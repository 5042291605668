import { IconProps } from '@/app/components/Icon';

export function PenIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M3.63694 12.4575C3.71058 12.0596 3.90319 11.6934 4.18933 11.4072L12.2017 3.39487C13.0615 2.53505 14.4556 2.53504 15.3154 3.39487L16.6051 4.6846C17.465 5.54442 17.465 6.93847 16.6051 7.79829L8.59275 15.8107C8.30661 16.0968 7.9404 16.2894 7.54249 16.3631L2.75 17.25L3.63694 12.4575Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M11 5L15 9' />
          <path d='M17.25 17.25H11.75' />
        </>
      ) : (
        <>
          <path d='M9.23664 3.44288L4.18161 8.33251C3.90008 8.60483 3.70471 8.95383 3.61974 9.33619L2.75 13.25L6.45529 12.3782C6.81159 12.2943 7.13805 12.1143 7.3991 11.8578L12.6265 6.72023C13.4824 5.78723 13.4537 4.34072 12.5614 3.44288C11.6433 2.51904 10.1547 2.51904 9.23664 3.44288Z' />
        </>
      )}
    </>
  );
}
