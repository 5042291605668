import { IconProps } from '@/app/components/Icon';

export function FacebookIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M10 18.9999C14.9705 18.9999 19 14.9705 19 9.99993C19 5.02942 14.9705 1 10 1C5.02943 1 1 5.02942 1 9.99993C1 14.9705 5.02943 18.9999 10 18.9999Z'
            fill='#1977F3'
            stroke='none'
          />
          <path
            d='M13.5038 12.6014L13.9025 9.99917H11.4066V8.31085C11.4066 7.59957 11.7547 6.90472 12.8735 6.90472H14.0088V4.68985C14.0088 4.68985 12.9785 4.51392 11.9939 4.51392C9.9385 4.51392 8.59435 5.75932 8.59435 8.01596V9.99917H6.30859V12.6014H8.59435V18.8904C9.05252 18.9625 9.5221 18.9992 10.0004 18.9992C10.4789 18.9992 10.9484 18.9613 11.4066 18.8904V12.6014H13.5038Z'
            fill='white'
            stroke='none'
          />
        </>
      ) : (
        <>
          <path
            d='M8 14.9999C11.866 14.9999 15 11.8659 15 7.99995C15 4.13399 11.866 1 8 1C4.134 1 1 4.13399 1 7.99995C1 11.8659 4.134 14.9999 8 14.9999Z'
            fill='#1977F3'
            stroke='none'
          />
          <path
            d='M10.7252 10.0232L11.0353 7.99922H9.09403V6.68608C9.09403 6.13286 9.36479 5.59243 10.235 5.59243H11.1179V3.86975C11.1179 3.86975 10.3166 3.73291 9.55078 3.73291C7.95217 3.73291 6.90672 4.70156 6.90672 6.45672V7.99922H5.12891V10.0232H6.90672V14.9146C7.26307 14.9707 7.6283 14.9992 8.00035 14.9992C8.37247 14.9992 8.73766 14.9698 9.09403 14.9146V10.0232H10.7252Z'
            fill='white'
            stroke='none'
          />
        </>
      )}
    </>
  );
}
