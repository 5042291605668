import { IconProps } from '@/app/components/Icon';

export function PhoneZoomedIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M5.5 18H14.5C15.8807 18 17 16.8807 17 15.5V5.5C17 4.11929 15.8807 3 14.5 3H5.5C4.11929 3 3 4.11929 3 5.5V15.5C3 16.8807 4.11929 18 5.5 18Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M2.75 17.25V5.25C2.75 3.86929 3.86929 2.75 5.25 2.75H14.75C16.1307 2.75 17.25 3.86929 17.25 5.25V17.25' />
          <path d='M8.75 6.75H11.25' />
        </>
      ) : (
        <>
          <path d='M2.75 13.25V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H11.25C12.3546 2.75 13.25 3.64543 13.25 4.75V13.25M6.75 5.75H9.25' />
        </>
      )}
    </>
  );
}
