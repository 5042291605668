export interface DAPIError {
  code: string;
  message: string;
  long_message?: string;
  meta?: {
    param_name?: string;
    unsupported_features?: string[];
  };
}

export class HTTPError extends Error {
  code: number;
  fieldErrors: DAPIError[];
  globalErrors: DAPIError[];
  metaErrors: Array<Record<string, any>>;

  constructor(message: string, code: number, errorData: DAPIError[] = []) {
    super(message);

    this.code = code;
    this.name = 'HTTPError';

    Object.setPrototypeOf(this, HTTPError.prototype);

    const { fieldErrors, globalErrors } = this.parseErrorData(errorData);
    this.fieldErrors = fieldErrors;
    this.globalErrors = globalErrors;
    this.metaErrors = errorData
      .map(error => error?.meta)
      .filter((v): v is NonNullable<DAPIError['meta']> => Boolean(v));
  }

  private parseErrorData(data: DAPIError[]) {
    return (data || []).reduce<{
      fieldErrors: DAPIError[];
      globalErrors: DAPIError[];
    }>(
      (memo, err) => {
        if (err.meta?.param_name) {
          memo.fieldErrors.push(err);
        } else {
          memo.globalErrors.push(err);
        }

        return memo;
      },
      {
        fieldErrors: [],
        globalErrors: [],
      },
    );
  }
}

export function getToastErrorMessage(error: unknown, fallback: string) {
  return (
    (error instanceof HTTPError &&
      error.globalErrors.map(error => error.message).join(', ')) ||
    fallback
  );
}
