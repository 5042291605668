import { IconProps } from '@/app/components/Icon';

export function CogIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            className='fill-[var(--icon-fill,currentColor)]'
            fillOpacity={0.15}
            d='M8.37365 3.36993C8.45551 3.03395 8.73668 2.75 9.09377 2.75H10.9062C11.2633 2.75 11.5445 3.03395 11.6263 3.36993C12.0042 4.9208 13.7712 5.8349 15.3714 5.35772C15.7055 5.25811 16.09 5.34988 16.2694 5.63993L17.1585 7.07794C17.3455 7.38053 17.2292 7.76815 16.9601 8.00607C15.761 9.06631 15.761 10.9337 16.9601 11.9939C17.2292 12.2318 17.3455 12.6195 17.1584 12.922L16.2693 14.3601C16.09 14.6501 15.7054 14.7419 15.3714 14.6423C13.7712 14.1651 12.0042 15.0792 11.6263 16.6301C11.5445 16.9661 11.2633 17.25 10.9062 17.25H9.09377C8.73668 17.25 8.45551 16.9661 8.37366 16.6301C7.99584 15.0792 6.22883 14.1651 4.62859 14.6423C4.29457 14.7419 3.91 14.6501 3.73067 14.3601L2.84157 12.922C2.65449 12.6195 2.77081 12.2318 3.03989 11.9939C4.23902 10.9337 4.23899 9.06631 3.03986 8.00607C2.77077 7.76815 2.65445 7.38053 2.84154 7.07794L3.73064 5.63993C3.90996 5.34988 4.29453 5.25811 4.62856 5.35772C6.2288 5.8349 7.99583 4.9208 8.37365 3.36993Z'
          />
          <circle cx='10' cy='10' r='2.25' />
        </>
      ) : (
        <>
          <path d='M6.8223 3.19892C6.88157 2.95562 7.08518 2.75 7.34377 2.75H8.65623C8.91482 2.75 9.11842 2.95562 9.1777 3.19892C9.45129 4.32196 10.7309 4.98389 11.8897 4.63835C12.1315 4.56622 12.41 4.63267 12.5399 4.84271L13.1837 5.88403C13.3192 6.10314 13.235 6.38383 13.0401 6.55612C12.1718 7.32388 12.1717 8.67611 13.0401 9.44387C13.2349 9.61616 13.3192 9.89685 13.1837 10.116L12.5399 11.1573C12.41 11.3673 12.1315 11.4338 11.8896 11.3616C10.7308 11.0161 9.45129 11.678 9.1777 12.8011C9.11842 13.0444 8.91482 13.25 8.65623 13.25H7.34377C7.08518 13.25 6.88157 13.0444 6.8223 12.8011C6.54871 11.678 5.26915 11.0161 4.11036 11.3616C3.86848 11.4338 3.59 11.3673 3.46014 11.1573L2.81631 10.116C2.68084 9.89685 2.76507 9.61616 2.95992 9.44387C3.82825 8.67611 3.82824 7.32388 2.9599 6.55612C2.76504 6.38383 2.68081 6.10314 2.81628 5.88403L3.46012 4.84271C3.58997 4.63267 3.86845 4.56622 4.11033 4.63835C5.26913 4.98389 6.54871 4.32196 6.8223 3.19892Z' />
          <circle cx='8' cy='8' r='1' fill='currentColor' stroke='none' />
        </>
      )}
    </>
  );
}
