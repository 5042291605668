'use client';

import { DialogNext } from '@/app/components/ui/DialogNext';
import { Icon } from '@/app/components/Icon';
import DocumentationIcon from './ui/DialogNextIcons/DocumentationIcon';
import SupportIcon from './ui/DialogNextIcons/SupportIcon';
import FeatureRequestIcon from './ui/DialogNextIcons/FeatureRequestIcon';
import SalesCallIcon from './ui/DialogNextIcons/SalesCallIcon';
import DiscordIcon from './ui/DialogNextIcons/DiscordIcon';
import Link from 'next/link';
import { cx } from 'cva';
import { useAnalytics } from '@/app/components/analytics/Analytics';

export function HelpCenter() {
  return (
    <DialogNext
      title='How can we help?'
      description='Choose an option based on your needs.'
      trigger={
        <button
          data-analytics='help-modal-opened'
          className={cx(
            'text-gray-1100 p-1.5 rounded transition hover:bg-gray-300 hover:text-gray-1200',
            'focus:bg-gray-300 focus:text-gray-1200 focus:ring-blue focus:ring-offset-2 focus:ring-2',
          )}
        >
          <Icon name='bubble-question' size='base' />
        </button>
      }
    >
      <div className='grid grid-cols-6 gap-3 text-center font-medium'>
        <HelpItemLink
          icon={<DocumentationIcon />}
          title='Documentation'
          href='https://clerk.com/docs'
        />
        <HelpItemLink
          icon={<SupportIcon />}
          title='Support'
          href='https://clerk.com/contact/support'
        />
        <HelpItemLink
          icon={<FeatureRequestIcon />}
          title='Feature request'
          href='https://feedback.clerk.com/roadmap'
        />
        <HelpItemLink
          icon={<SalesCallIcon />}
          title='Contact sales'
          href='https://clerk.com/contact/sales'
        />
        <HelpItemLink
          icon={<DiscordIcon />}
          title='Discord'
          href='https://discord.gg/b5rXHjAg7A'
        />
      </div>
    </DialogNext>
  );
}

function HelpItemLink(props: {
  icon: React.ReactNode;
  title: string;
  href: string;
  className?: string;
}) {
  const { track } = useAnalytics();

  return (
    <DialogNext.Action>
      <Link
        target='_blank'
        href={props.href}
        onClick={() => {
          track('Dashboard Help Clicked', {
            surface: 'Dashboard',
            help_type: props.title,
          });
        }}
        className={cx(
          props.className,
          'first:col-span-full first:sm:col-span-3 first:bg-purple-100/15 first:hover:bg-purple-100/30 first:hover:border-purple-100',
          '[&:nth-child(2)]:col-span-3 sm:[&:nth-child(2)]:bg-purple-100/15 sm:[&:nth-child(2)]:hover:bg-purple-100/30 sm:[&:nth-child(2)]:hover:border-purple-100',
          'group border hover:bg-gray-100 transition pt-4.5 pb-4 rounded-md gap-2 flex flex-col items-center col-span-3 sm:col-span-2',
          'focus:text-gray-1200 focus:ring-blue focus:ring-offset-2 focus:ring-2 focus:bg-gray-100',
        )}
      >
        <div
          className={cx(
            'group-hover:text-gray-1100 text-gray-900 transform-gpu transition',
          )}
        >
          {props.icon}
        </div>
        {props.title}
      </Link>
    </DialogNext.Action>
  );
}
