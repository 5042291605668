import { IconProps } from '@/app/components/Icon';

export function QrCodeIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M8.24219 2.75H3.75C3.19772 2.75 2.75 3.19772 2.75 3.75V8.24219C2.75 8.79447 3.19772 9.24219 3.75 9.24219H8.24219C8.79447 9.24219 9.24219 8.79447 9.24219 8.24219V3.75C9.24219 3.19772 8.79447 2.75 8.24219 2.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M16.25 2.75H12.75C12.1977 2.75 11.75 3.19772 11.75 3.75V7.25C11.75 7.80228 12.1977 8.25 12.75 8.25H16.25C16.8023 8.25 17.25 7.80228 17.25 7.25V3.75C17.25 3.19772 16.8023 2.75 16.25 2.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M7.25 11.75H3.75C3.19772 11.75 2.75 12.1977 2.75 12.75V16.25C2.75 16.8023 3.19772 17.25 3.75 17.25H7.25C7.80228 17.25 8.25 16.8023 8.25 16.25V12.75C8.25 12.1977 7.80228 11.75 7.25 11.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M17.25 13.75V11.25H16.2266' />
          <path d='M12.7188 12.25H11.2344V11.25' />
          <path d='M13.7188 17.25H11.2344' />
          <path d='M16.2188 17.25H17.25V15.75' />
          <path d='M14.7188 14.75V13.25M11.2344 14.75H12.2031' />
        </>
      ) : (
        <>
          <path d='M6.25 2.75H3.75C3.19772 2.75 2.75 3.19772 2.75 3.75V6.25C2.75 6.80228 3.19772 7.25 3.75 7.25H6.25C6.80228 7.25 7.25 6.80228 7.25 6.25V3.75C7.25 3.19772 6.80228 2.75 6.25 2.75Z' />
          <path d='M12.25 2.75H10.75C10.1977 2.75 9.75 3.19772 9.75 3.75V5.25C9.75 5.80228 10.1977 6.25 10.75 6.25H12.25C12.8023 6.25 13.25 5.80228 13.25 5.25V3.75C13.25 3.19772 12.8023 2.75 12.25 2.75Z' />
          <path d='M5.25 9.75H3.75C3.19772 9.75 2.75 10.1977 2.75 10.75V12.25C2.75 12.8023 3.19772 13.25 3.75 13.25H5.25C5.80228 13.25 6.25 12.8023 6.25 12.25V10.75C6.25 10.1977 5.80228 9.75 5.25 9.75Z' />
          <path d='M10.25 13.25L8.75 13.25L8.75 11.75' />
          <path d='M13.25 13.25L13.25 11.75' />
          <path d='M13.25 9.25L13.25 8.75' />
          <path d='M8.75 8.75L10.75 8.75L10.75 10.25L11.2656 10.7656' />
        </>
      )}
    </>
  );
}
