import { IconProps } from '@/app/components/Icon';

export function ExportLinkIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <rect
            x='2.75'
            y='4.75'
            width='12.5'
            height='12.5'
            rx='2'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M10.3452 9.65476L17.25 2.75M17.25 2.75H11.7262M17.25 2.75V8.27381M8.27381 4.82143H4.75003C3.64546 4.82143 2.75003 5.71686 2.75003 6.82142L2.75001 15.25C2.75 16.3546 3.64543 17.25 4.75001 17.25H13.1786C14.2831 17.25 15.1786 16.3546 15.1786 15.25V11.7262' />
        </>
      ) : (
        <>
          <path d='M8.25 7.75L13.25 2.75M13.25 2.75H9.25M13.25 2.75V6.75M6.75 4.25H4.75002C3.64545 4.25 2.75002 5.14543 2.75002 6.24999L2.75001 11.25C2.75 12.3546 3.64543 13.25 4.75001 13.25H9.75C10.8546 13.25 11.75 12.3546 11.75 11.25V9.25' />
        </>
      )}
    </>
  );
}
