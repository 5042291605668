import { IconProps } from '@/app/components/Icon';

export function LockBordersIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M14 2H6C3.79086 2 2 3.79086 2 6V14C2 16.2091 3.79086 18 6 18H14C16.2091 18 18 16.2091 18 14V6C18 3.79086 16.2091 2 14 2Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M5.25 2.75H4.75C3.64543 2.75 2.75 3.64543 2.75 4.75V15.25C2.75 16.3546 3.64543 17.25 4.75 17.25H5.25' />
          <path d='M14.75 2.75H15.25C16.3546 2.75 17.25 3.64543 17.25 4.75V15.25C17.25 16.3546 16.3546 17.25 15.25 17.25H14.75' />
          <path d='M7.75 9.75V9C7.75 7.75736 8.75736 6.75 10 6.75V6.75C11.2426 6.75 12.25 7.75736 12.25 9V9.75M7.75 9.75H6.75V13.25C6.75 13.8023 7.19772 14.25 7.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V9.75H7.75Z' />
        </>
      ) : (
        <>
          <path d='M6.56818 7.75V6.68182C6.56818 5.89105 7.20923 5.25 8 5.25V5.25C8.79077 5.25 9.43182 5.89105 9.43182 6.68182V7.75M6.56818 7.75H5.75V10.25C5.75 10.8023 6.19772 11.25 6.75 11.25H9.25C9.80228 11.25 10.25 10.8023 10.25 10.25V7.75H6.56818Z' />
          <path d='M4.25 2.75V2.75C3.42157 2.75 2.75 3.42157 2.75 4.25V11.75C2.75 12.5784 3.42157 13.25 4.25 13.25V13.25' />
          <path d='M11.75 13.25C12.5784 13.25 13.25 12.6904 13.25 12V4C13.25 3.30964 12.5784 2.75 11.75 2.75' />
        </>
      )}
    </>
  );
}
