import React from 'react';

const DiscordIcon = () => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.9917 7.23449L26.8708 7.06797L26.6819 6.98643C24.8122 6.17922 22.8141 5.59305 20.7264 5.2594L20.2256 5.17935L19.9648 5.6144C19.7895 5.90676 19.5968 6.26204 19.4251 6.60513C17.573 6.38994 15.7331 6.38947 13.9068 6.60433C13.7319 6.25908 13.5351 5.90307 13.359 5.61187L13.0977 5.17977L12.599 5.25938C10.5087 5.59309 8.50816 6.18148 6.63831 6.99085L6.44777 7.07333L6.32677 7.24205C2.55936 12.4952 1.52573 17.6502 2.03872 22.7234L2.07363 23.0687L2.35936 23.2656C4.85758 24.987 7.28271 26.0346 9.66246 26.7187L10.147 26.8581L10.4589 26.4619C11.0551 25.7049 11.5877 24.8989 12.047 24.0484L12.1493 23.8588C15.1353 24.6213 18.2128 24.6241 21.1807 23.866L21.2809 24.0512C21.7393 24.8978 22.2695 25.7062 22.8695 26.4651L23.1814 26.8597L23.6649 26.7209C26.0468 26.0367 28.4744 24.9892 30.9729 23.2653L31.2579 23.0687L31.2931 22.7244C31.8952 16.8415 30.2577 11.735 26.9917 7.23449Z'
        fill='currentColor'
        fillOpacity={0.15}
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M9.58203 17.0008C9.58203 18.5128 10.819 19.75 12.3319 19.75C13.8653 19.75 15.0803 18.5128 15.0817 17.0067C15.1045 15.4793 13.8547 14.25 12.3319 14.25C10.7943 14.25 9.58203 15.4942 9.58203 17.0008Z'
        fill='white'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M18.2324 17.0008C18.2324 18.5128 19.4693 19.75 20.9823 19.75C22.5169 19.75 23.7286 18.5117 23.7321 17.0073C23.7552 15.4796 22.5052 14.25 20.9823 14.25C19.4446 14.25 18.2324 15.4942 18.2324 17.0008Z'
        fill='white'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default DiscordIcon;
