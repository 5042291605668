import { IconProps } from '@/app/components/Icon';

export function ExclamationOctagonIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M12.279 2.75002H7.72C7.18961 2.75014 6.68099 2.96092 6.306 3.33602L3.336 6.30702C2.9609 6.68201 2.75011 7.19063 2.75 7.72102V12.279C2.75011 12.8094 2.9609 13.318 3.336 13.693L6.307 16.664C6.68199 17.0391 7.19061 17.2499 7.721 17.25H12.279C12.8094 17.2499 13.318 17.0391 13.693 16.664L16.664 13.693C17.0391 13.318 17.2499 12.8094 17.25 12.279V7.72002C17.2499 7.18963 17.0391 6.68101 16.664 6.30602L13.693 3.33502C13.318 2.95992 12.8094 2.75014 12.279 2.75002Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M10 6.78497V9.25' />
          <path d='M10 13V13.25' />
        </>
      ) : (
        <>
          <path d='M8 5.75V7.25' />
          <path d='M8 10V10.01' />
          <path d='M10.25 2.75H5.75L2.75 5.75V10.25L5.75 13.25H10.25L13.25 10.25V5.75L10.25 2.75Z' />
        </>
      )}
    </>
  );
}
