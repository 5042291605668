import { IconProps } from '@/app/components/Icon';

export function HomeIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2.75 8.25L10 2.75L17.25 8.25V16.25C17.25 16.8023 16.8023 17.25 16.25 17.25H3.75C3.19771 17.25 2.75 16.8023 2.75 16.25V8.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M7.75 12.75V17.25H12.25V12.75H7.75Z' />
        </>
      ) : (
        <>
          <path d='M2.75 6.95V12.25C2.75 12.8023 3.19772 13.25 3.75 13.25H12.25C12.8023 13.25 13.25 12.8023 13.25 12.25V6.95L8 2.75L2.75 6.95Z' />
          <path d='M6.25 13.25V8.75H9.75V13.25' />
        </>
      )}
    </>
  );
}
