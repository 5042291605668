import { IconProps } from '@/app/components/Icon';

export function FolderIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M7.62868 3.75H4.75C3.64543 3.75 2.75 4.64543 2.75 5.75V14.25C2.75 15.3546 3.64543 16.25 4.75 16.25H15.25C16.3546 16.25 17.25 15.3546 17.25 14.25V8.75C17.25 7.64543 16.3546 6.75 15.25 6.75H11.8713C11.4735 6.75 11.092 6.59196 10.8107 6.31066L8.68934 4.18934C8.40804 3.90804 8.0265 3.75 7.62868 3.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M6.52906 3.75H4.25C3.42157 3.75 2.75 4.42157 2.75 5.25V10.75C2.75 11.5784 3.42157 12.25 4.25 12.25H11.75C12.5784 12.25 13.25 11.5784 13.25 10.75V7.75C13.25 6.92157 12.5784 6.25 11.75 6.25H9.97094C9.51526 6.25 9.08429 6.04287 8.79963 5.68704L7.70037 4.31296C7.41571 3.95713 6.98474 3.75 6.52906 3.75Z' />
        </>
      )}
    </>
  );
}
