import { IconProps } from '@/app/components/Icon';

export function CheckmarkShieldIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M17.25 7C17.25 7 14 5.41667 10 2.75C6 5.41667 2.75 7 2.75 7C2.75 14 8.66667 17.25 10 17.25C11.3333 17.25 17.25 14 17.25 7Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M7.75 10.45L9.55 12.25L12.25 7.75' />
        </>
      ) : (
        <>
          <path d='M13.25 5.9L8 2.75L2.75 5.9C2.75 5.9 3 12 7.25 13.25' />
          <path d='M9.75 10.85L11.15 12.25L13.25 8.75' />
        </>
      )}
    </>
  );
}
