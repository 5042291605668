import { IconProps } from '@/app/components/Icon';

export function BitbucketIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M1.81794 1.87505C1.6523 1.87284 1.49419 1.94639 1.38602 2.07595C1.27786 2.20552 1.23072 2.37784 1.25724 2.5467L3.63742 17.4677C3.69864 17.8447 4.01308 18.1218 4.38315 18.1249H15.8019C16.0796 18.1286 16.3181 17.9217 16.3626 17.6386L18.7427 2.54959C18.7693 2.38074 18.7221 2.20841 18.614 2.07884C18.5058 1.94928 18.3477 1.87574 18.1821 1.87794L1.81794 1.87505ZM11.8405 12.6591H8.19594L7.20912 7.33512H12.7236L11.8405 12.6591Z'
            fill='#2684FF'
            stroke='none'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.9861 7.33521H12.7239L11.8408 12.6592H8.19625L3.89282 17.934C4.02922 18.0558 4.20312 18.1235 4.38343 18.1251H15.8049C16.0827 18.1288 16.3212 17.9219 16.3657 17.6387L17.9861 7.33521Z'
            fill='url(#paint0_linear_4294_941)'
            stroke='none'
          />
          <defs>
            <linearGradient
              id='paint0_linear_4294_941'
              x1='19.2028'
              y1='8.82614'
              x2='12.8602'
              y2='17.5471'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0.18' stopColor='#0052CC' />
              <stop offset='1' stopColor='#2684FF' />
            </linearGradient>
          </defs>
        </>
      ) : (
        <>
          <path
            d='M1.45435 1.39286C1.32184 1.3911 1.19535 1.44993 1.10882 1.55358C1.02229 1.65724 0.984574 1.7951 1.00579 1.93018L2.90993 13.867C2.95891 14.1686 3.21046 14.3903 3.50652 14.3928H12.6415C12.8637 14.3957 13.0545 14.2302 13.09 14.0037L14.9942 1.9325C15.0154 1.79741 14.9777 1.65955 14.8912 1.5559C14.8046 1.45225 14.6781 1.39341 14.5456 1.39518L1.45435 1.39286ZM9.4724 10.0201H6.55675L5.7673 5.76092H10.1789L9.4724 10.0201Z'
            fill='#2684FF'
            stroke='none'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.3889 5.76099H10.1791L9.47265 10.0202H6.557L3.11426 14.24C3.22337 14.3374 3.3625 14.3916 3.50675 14.3929H12.6439C12.8662 14.3958 13.057 14.2303 13.0925 14.0038L14.3889 5.76099Z'
            fill='url(#paint0_linear_4294_938)'
            stroke='none'
          />
          <defs>
            <linearGradient
              id='paint0_linear_4294_938'
              x1='15.3622'
              y1='6.95374'
              x2='10.2882'
              y2='13.9305'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0.18' stopColor='#0052CC' />
              <stop offset='1' stopColor='#2684FF' />
            </linearGradient>
          </defs>
        </>
      )}
    </>
  );
}
