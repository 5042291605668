import { IconProps } from '@/app/components/Icon';

export function JwtIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M8.75 2.75H11.25V5.86395C11.25 6.31105 11.792 6.53338 12.106 6.21507L14.2721 4.01906L15.981 5.7279L13.785 7.89403C13.4666 8.20801 13.689 8.75 14.1361 8.75H17.25V11.25H14.136C13.6889 11.25 13.4666 11.792 13.7849 12.106L15.9809 14.2721L14.2721 15.981L12.106 13.785C11.792 13.4667 11.25 13.689 11.25 14.1361V17.25H8.75V14.1361C8.75 13.689 8.20801 13.4666 7.89403 13.785L5.72791 15.9809L4.01907 14.2721L6.21507 12.106C6.53338 11.792 6.31105 11.25 5.86395 11.25H2.75V8.75H5.8639C6.311 8.75 6.53333 8.20801 6.21502 7.89403L4.01903 5.72791L5.72787 4.01907L7.89403 6.21509C8.20801 6.5334 8.75 6.31107 8.75 5.86397V2.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M7.09483 2.75H8.90517V4.05923C8.90517 4.72989 9.71815 5.06338 10.1891 4.58592L11.0936 3.66897L12.331 4.90641L11.4141 5.81088C10.9366 6.28185 11.2701 7.09483 11.9408 7.09483H13.25V8.90517H11.9407C11.2701 8.90517 10.9366 9.71815 11.4141 10.1891L12.331 11.0936L11.0936 12.331L10.1891 11.4141C9.71815 10.9367 8.90517 11.2701 8.90517 11.9408V13.25H7.09483V11.9408C7.09483 11.2701 6.28185 10.9366 5.81088 11.4141L4.90642 12.331L3.66898 11.0936L4.58592 10.1891C5.06338 9.71815 4.72989 8.90517 4.05923 8.90517H2.75V7.09483H4.0592C4.72986 7.09483 5.06335 6.28185 4.58589 5.81088L3.66895 4.90642L4.90639 3.66898L5.81088 4.58594C6.28185 5.0634 7.09483 4.72991 7.09483 4.05925V2.75Z' />
        </>
      )}
    </>
  );
}
