import { useUser } from '@clerk/nextjs';

export const useUnsafeMetadata = () => {
  const { user, isSignedIn } = useUser();

  const shouldShowInfoModal = isSignedIn
    ? (user.unsafeMetadata?.shouldShowInfoModal as string[]) || []
    : [];

  const getShouldShowInfoModalArray = (id: string) => {
    return shouldShowInfoModal.includes(id);
  };

  const removeAppIdFromInfoModalArray = async (appId: string) => {
    const result = shouldShowInfoModal.filter(id => id !== appId);

    await updateUnsafeMetadata('shouldShowInfoModal', result);
  };

  const addAppIdToSeeInfoModal = async (appId: string) => {
    const result = [...shouldShowInfoModal, appId];

    await updateUnsafeMetadata('shouldShowInfoModal', result);
  };

  const getExportInstanceIds = () => {
    return [...((user!.unsafeMetadata?.exportInstanceIds as string[]) || [])];
  };

  const addExportInstanceId = (id: string) => {
    return updateUnsafeMetadata('exportInstanceIds', [
      ...new Set([...getExportInstanceIds(), id].filter(Boolean)),
    ]);
  };

  const removeExportInstanceId = (id: string) => {
    const filtered = [...getExportInstanceIds()].filter(i => i !== id);
    return updateUnsafeMetadata('exportInstanceIds', filtered);
  };

  const updateUnsafeMetadata = async (field: string, value: any) => {
    if (!isSignedIn) {
      return;
    }

    await user.update({
      unsafeMetadata: {
        ...user.unsafeMetadata,
        [field]: value,
      },
    });
  };

  return {
    shouldShowInfoModal: getShouldShowInfoModalArray,
    removeAppIdFromInfoModalArray,
    addAppIdToSeeInfoModal,
    getExportInstanceIds,
    addExportInstanceId,
    removeExportInstanceId,
    updateUnsafeMetadata,
  };
};
