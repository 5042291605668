import { IconProps } from '@/app/components/Icon';

export function ThumbDownIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2.75 10.25H5.75L8.75 17.25H9.25C10.3546 17.25 11.25 16.3546 11.25 15.25V11.25L15.2523 11.2614C16.4495 11.2614 17.3785 10.2167 17.2386 9.0277L16.7092 4.5277C16.5907 3.52048 15.7371 2.76139 14.7229 2.76139L5.75 2.75003H2.75V10.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M5.75 10.25H2.75V2.75003H5.75M5.75 10.25L8.75 17.25H9.25C10.3546 17.25 11.25 16.3546 11.25 15.25V11.25L15.2523 11.2614C16.4495 11.2614 17.3785 10.2167 17.2386 9.0277L16.7092 4.5277C16.5907 3.52048 15.7371 2.76139 14.7229 2.76139L5.75 2.75003M5.75 10.25V2.75003' />
        </>
      ) : (
        <>
          <path d='M5.25 8.25003H2.75V2.75H5.25M5.25 8.25003L7.09484 13.25H7.45691C8.25677 13.25 8.75 12.6016 8.75 11.8017V8.75003H11.8034C12.6704 8.75003 13.3431 8.1569 13.2418 7.29591L12.8584 4.03728C12.7726 3.30791 12.1545 2.75 11.4201 2.75H5.25M5.25 8.25003V2.75' />
        </>
      )}
    </>
  );
}
