import { IconProps } from '@/app/components/Icon';

export function LinkIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M10 3.37258C11.8301 1.54247 14.7973 1.54247 16.6274 3.37258C18.4575 5.20269 18.4575 8.16989 16.6274 10L10 16.6274C8.16989 18.4575 5.20269 18.4575 3.37258 16.6274C1.54247 14.7973 1.54247 11.8301 3.37258 10L10 3.37258Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M8.75 5.41652L10.2084 3.95815C11.8192 2.34728 14.431 2.34728 16.0418 3.95815C17.6527 5.56902 17.6527 8.18076 16.0418 9.79163L14.5835 11.25' />
          <path d='M11.25 14.5835L9.79163 16.0418C8.18076 17.6527 5.56902 17.6527 3.95815 16.0418C2.34728 14.431 2.34728 11.8192 3.95815 10.2084L5.41652 8.75' />
          <path d='M12.25 7.75L7.75 12.25' />
        </>
      ) : (
        <>
          <path d='M7.75 3.7891L7.86522 3.67388C9.09706 2.44204 11.0943 2.44204 12.3261 3.67388C13.558 4.90572 13.558 6.90294 12.3261 8.13478L12.2109 8.25M6.75 9.25L9.25 6.75M8.25 12.2109L8.13478 12.3261C6.90294 13.558 4.90572 13.558 3.67388 12.3261C2.44204 11.0943 2.44204 9.09706 3.67388 7.86522L3.78911 7.75' />
        </>
      )}
    </>
  );
}
