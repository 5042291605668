import { IconProps } from '@/app/components/Icon';

export function SquareCheckIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2 6C2 4.34315 3.34315 3 5 3H15C16.6569 3 18 4.34315 18 6V14C18 15.6569 16.6569 17 15 17H5C3.34315 17 2 15.6569 2 14V6Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M9.25 16.25H4.75C3.64543 16.25 2.75 15.3546 2.75 14.25V5.75C2.75 4.64543 3.64543 3.75 4.75 3.75H15.25C16.3546 3.75 17.25 4.64543 17.25 5.75V8.25' />
          <path d='M6.75 6.75H13.25' />
          <path d='M12.75 14.75L14 16.25L16.25 12.75' />
        </>
      ) : (
        <>
          <path d='M6.25 13.25H4.75C3.64543 13.25 2.75 12.3546 2.75 11.25V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H11.25C12.3546 2.75 13.25 3.64543 13.25 4.75V6.25' />
          <path d='M9.25 11.35L10.65 12.75L12.75 9.25' />
        </>
      )}
    </>
  );
}
