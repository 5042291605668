import { IconProps } from '@/app/components/Icon';

export function EnvelopeIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2.75 14.25V5.75C2.75 4.64543 3.64543 3.75 4.75 3.75H15.25C16.3546 3.75 17.25 4.64543 17.25 5.75V14.25C17.25 15.3546 16.3546 16.25 15.25 16.25H4.75C3.64543 16.25 2.75 15.3546 2.75 14.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M3.25 4.75L10 10.25L16.75 4.75' />
        </>
      ) : (
        <>
          <path d='M3 4.75L8 8.25L13 4.75M4.75 12.25H11.25C12.3546 12.25 13.25 11.3546 13.25 10.25V5.75C13.25 4.64543 12.3546 3.75 11.25 3.75H4.75C3.64543 3.75 2.75 4.64543 2.75 5.75V10.25C2.75 11.3546 3.64543 12.25 4.75 12.25Z' />
        </>
      )}
    </>
  );
}
