import { IconProps } from '@/app/components/Icon';

export function PlusIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M4.75 10H15.25M10 4.75V15.25' />
        </>
      ) : (
        <>
          <path d='M8 3.75V12.25M12.25 8L3.75 8' />
        </>
      )}
    </>
  );
}
