import { IconProps } from '@/app/components/Icon';

export function LockUnderlineIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M17.25 17.25H2.75' />
          <path
            d='M5.75 7.75H7.25H12.75H14.25V12.25C14.25 13.3546 13.3546 14.25 12.25 14.25H7.75C6.64543 14.25 5.75 13.3546 5.75 12.25V7.75Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M7.25 7.75H5.75V12.25C5.75 13.3546 6.64543 14.25 7.75 14.25H12.25C13.3546 14.25 14.25 13.3546 14.25 12.25V7.75H12.75M7.25 7.75V5.5C7.25 3.98122 8.48122 2.75 10 2.75C11.5188 2.75 12.75 3.98122 12.75 5.5V7.75M7.25 7.75H12.75' />
        </>
      ) : (
        <>
          <path d='M6.56818 5.75V4.18182C6.56818 3.39105 7.20923 2.75 8 2.75V2.75C8.79077 2.75 9.43182 3.39105 9.43182 4.18182V5.75M6.56818 5.75H6.25C5.69772 5.75 5.25 6.19772 5.25 6.75V9.25C5.25 9.80228 5.69772 10.25 6.25 10.25H9.75C10.3023 10.25 10.75 9.80228 10.75 9.25V6.75C10.75 6.19772 10.3023 5.75 9.75 5.75H6.56818Z' />
          <path d='M13.25 13.25H2.75' />
        </>
      )}
    </>
  );
}
