import { IconProps } from '@/app/components/Icon';

export function TwitchIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <g stroke='none'>
          <path
            d='M16.4281 9.35719L13.8566 11.9286H11.2852L9.0352 14.1786V11.9286H6.14233V2.28577H16.4281V9.35719Z'
            fill='white'
          />
          <path
            d='M5.49999 1L2.28571 4.21429V15.7857H6.14285V19L9.35713 15.7857H11.9285L17.7142 10V1H5.49999ZM16.4285 9.35714L13.8571 11.9286H11.2856L9.03571 14.1786V11.9286H6.14285V2.28571H16.4285V9.35714Z'
            fill='#9146FF'
          />
          <path
            d='M14.5005 4.53589H13.2148V8.39303H14.5005V4.53589Z'
            fill='#9146FF'
          />
          <path
            d='M10.9649 4.53589H9.6792V8.39303H10.9649V4.53589Z'
            fill='#9146FF'
          />
        </g>
      ) : (
        <g stroke='none'>
          <path
            d='M12.9998 7.50002L10.9997 9.50003H8.99975L7.2497 11.25V9.50003H4.99969V2H12.9998V7.50002Z'
            fill='white'
          />
          <path
            d='M4.50001 1L2 3.50001V12.5001H5.00001V15L7.50002 12.5001H9.49997L14 8.00002V1H4.50001ZM13 7.50002L11 9.50006H8.99996L7.25002 11.2501V9.50006H5.00001V2H13V7.50002Z'
            fill='#9146FF'
          />
          <path
            d='M11.5006 3.75H10.5006V6.75001H11.5006V3.75Z'
            fill='#9146FF'
          />
          <path
            d='M8.75048 3.75H7.75049V6.75001H8.75048V3.75Z'
            fill='#9146FF'
          />
        </g>
      )}
    </>
  );
}
