import { IconProps } from '@/app/components/Icon';

export function FeaturesIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2.75 3.75C2.75 3.19772 3.19772 2.75 3.75 2.75H7.25C7.80228 2.75 8.25 3.19772 8.25 3.75V7.25C8.25 7.80228 7.80228 8.25 7.25 8.25H3.75C3.19772 8.25 2.75 7.80228 2.75 7.25V3.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M2.75 12.75C2.75 12.1977 3.19772 11.75 3.75 11.75H7.25C7.80228 11.75 8.25 12.1977 8.25 12.75V16.25C8.25 16.8023 7.80228 17.25 7.25 17.25H3.75C3.19772 17.25 2.75 16.8023 2.75 16.25V12.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M11.75 12.75C11.75 12.1977 12.1977 11.75 12.75 11.75H16.25C16.8023 11.75 17.25 12.1977 17.25 12.75V16.25C17.25 16.8023 16.8023 17.25 16.25 17.25H12.75C12.1977 17.25 11.75 16.8023 11.75 16.25V12.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M13.9404 2.98179C14.2495 2.67274 14.7505 2.67274 15.0596 2.98179L17.0182 4.9404C17.3273 5.24946 17.3273 5.75054 17.0182 6.0596L15.0596 8.01821C14.7505 8.32727 14.2495 8.32727 13.9404 8.01821L11.9818 6.0596C11.6727 5.75054 11.6727 5.24946 11.9818 4.9404L13.9404 2.98179Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M2.75 3.75C2.75 3.19772 3.19772 2.75 3.75 2.75H5.25C5.80228 2.75 6.25 3.19772 6.25 3.75V5.25C6.25 5.80228 5.80228 6.25 5.25 6.25H3.75C3.19772 6.25 2.75 5.80228 2.75 5.25V3.75Z' />
          <path d='M2.75 10.75C2.75 10.1977 3.19772 9.75 3.75 9.75H5.25C5.80228 9.75 6.25 10.1977 6.25 10.75V12.25C6.25 12.8023 5.80228 13.25 5.25 13.25H3.75C3.19772 13.25 2.75 12.8023 2.75 12.25V10.75Z' />
          <path d='M9.75 10.75C9.75 10.1977 10.1977 9.75 10.75 9.75H12.25C12.8023 9.75 13.25 10.1977 13.25 10.75V12.25C13.25 12.8023 12.8023 13.25 12.25 13.25H10.75C10.1977 13.25 9.75 12.8023 9.75 12.25V10.75Z' />
          <path d='M9.97552 5.04446C9.67483 4.74376 9.67483 4.25624 9.97552 3.95554L10.9555 2.97552C11.2562 2.67483 11.7438 2.67483 12.0445 2.97552L13.0245 3.95554C13.3252 4.25624 13.3252 4.74376 13.0245 5.04446L12.0445 6.02448C11.7438 6.32517 11.2562 6.32517 10.9555 6.02448L9.97552 5.04446Z' />
        </>
      )}
    </>
  );
}
