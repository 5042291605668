import { IconProps } from '@/app/components/Icon';

export function LinkedinIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          d='M16.3374 16.3372H13.6702V12.1604C13.6702 11.1644 13.6525 9.88222 12.2831 9.88222C10.8939 9.88222 10.6813 10.9674 10.6813 12.0879V16.3369H8.01428V7.74765H10.5747V8.92145H10.6106C11.1319 8.0301 12.1048 7.49573 13.1369 7.53405C15.84 7.53405 16.3385 9.3121 16.3385 11.6253L16.3374 16.3372ZM5.00487 6.57353C4.15578 6.57353 3.45676 5.87492 3.45676 5.02583C3.45676 4.17676 4.15546 3.47805 5.00454 3.47805C5.85355 3.47805 6.55216 4.1766 6.55232 5.02551C6.55232 5.87444 5.8538 6.57345 5.00487 6.57353ZM6.3384 16.3372H3.66852V7.74765H6.3384V16.3372ZM17.667 1.00124H2.32822C1.60819 0.99311 1.00878 1.5786 1 2.29862V17.7011C1.00845 18.4215 1.60779 19.0076 2.32822 18.9999H17.667C18.3891 19.009 18.9906 18.4229 19.0003 17.7011V2.29758C18.9906 1.57602 18.3883 0.990613 17.667 1.00011V1.00124Z'
          fill='#0A66C2'
          stroke='none'
        />
      ) : (
        <path
          d='M12.9291 12.9289H10.8546V9.68033C10.8546 8.90563 10.8408 7.90839 9.77574 7.90839C8.69528 7.90839 8.52994 8.7524 8.52994 9.62391V12.9287H6.45555V6.24817H8.44699V7.16113H8.47492C8.88036 6.46785 9.63707 6.05223 10.4398 6.08204C12.5423 6.08204 12.9299 7.46496 12.9299 9.26411L12.9291 12.9289ZM4.1149 5.33497C3.45449 5.33497 2.91082 4.7916 2.91082 4.1312C2.91082 3.47081 3.45425 2.92737 4.11465 2.92737C4.77499 2.92737 5.31835 3.47069 5.31847 4.13096C5.31847 4.79123 4.77517 5.3349 4.1149 5.33497ZM5.15209 12.9289H3.07552V6.24817H5.15209V12.9289ZM13.9632 1.00097H2.03306C1.47304 0.994641 1.00683 1.45002 1 2.01004V13.9897C1.00658 14.55 1.47273 15.0059 2.03306 14.9999H13.9632C14.5249 15.007 14.9927 14.5511 15.0002 13.9897V2.00923C14.9927 1.44802 14.5243 0.992699 13.9632 1.00009V1.00097Z'
          fill='#0A66C2'
          stroke='none'
        />
      )}
    </>
  );
}
