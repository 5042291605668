import { IconProps } from '@/app/components/Icon';

export function PlansIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M2.25 3.75C2.25 3.19771 2.69772 2.75 3.25 2.75H11.75C12.3023 2.75 12.75 3.19772 12.75 3.75V16.25C12.75 16.8023 12.3023 17.25 11.75 17.25H3.25C2.69772 17.25 2.25 16.8023 2.25 16.25V3.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M5.25 11.25H9.75' />
          <path d='M5.25 14.25H9.75' />
          <path
            d='M12.75 4.75H15.75C16.3023 4.75 16.75 5.19772 16.75 5.75V14.25C16.75 14.8023 16.3023 15.25 15.75 15.25H12.75V4.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M9.90625 3.75H11.75C12.3023 3.75 12.75 4.19772 12.75 4.75V11.25C12.75 11.8023 12.3023 12.25 11.75 12.25H9.90625M5.25 7.25H6.75M5.25 10.25H6.75M3.25 13.25H8.75C9.30228 13.25 9.75 12.8023 9.75 12.25V3.75C9.75 3.19772 9.30228 2.75 8.75 2.75H3.25C2.69772 2.75 2.25 3.19772 2.25 3.75V12.25C2.25 12.8023 2.69772 13.25 3.25 13.25Z' />
        </>
      )}
    </>
  );
}
