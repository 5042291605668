'use client';

import { getApplications } from '@/app/api/applications';
import { Application } from '@/app/api/types/applications';
import { Instance } from '@/app/api/types/instances';
import { findApplication, findInstance, getParams } from '@/app/utils/url';
import { useAuth } from '@clerk/nextjs';
import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { usePathname } from 'next/navigation';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type DapiContext = {
  applications: Application[];
  error: Error | null;
  application: Application | undefined;
  instance: Instance | undefined;
  isError: boolean;
  isLoading: boolean;
};
const DapiContext = createContext<DapiContext>({
  application: undefined,
  applications: [],
  error: null,
  instance: undefined,
  isError: false,
  isLoading: false,
});

type Props = PropsWithChildren<{
  initialData: {
    applications: Application[];
    organizationId?: string | null;
    sessionId: string;
  };
}>;
export function DapiProvider({ children, initialData }: Props) {
  const { getToken, sessionId: sessId, orgId } = useAuth();
  const pathname = usePathname()!;

  const sessionId = sessId ?? initialData.sessionId;
  const organizationId =
    orgId === undefined ? initialData.organizationId : orgId;

  const { data, isLoading, isError, error } = useQuery({
    initialData: initialData.applications,
    queryFn: async () =>
      getApplications(sessionId, organizationId, { token: await getToken() }),
    queryKey: ['applications', organizationId],
  });

  const { application: app, instance: ins } = getParams(pathname);
  const application = findApplication(data, app);

  const [current, setCurrent] = useState<{
    application: Application | undefined;
    instance: Instance | undefined;
  }>(
    application
      ? { application, instance: findInstance(application, ins) }
      : { application: undefined, instance: undefined },
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    const { application: app, instance: ins } = getParams(pathname);
    const application = findApplication(data, app);

    // console.log(app, ins, rest, application, data);

    if (!application) {
      // TODO: Fix this once the middleware rules have been set
      // push('/' + (rest ? `?page=/${rest}` : ''));
    } else {
      const instance = findInstance(application, ins);
      const data = { application, instance };
      !isEqual(data, current) && setCurrent({ application, instance });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- No need to add `push` and `current` as they are only side effects
  }, [pathname, data]);

  return (
    <DapiContext.Provider
      value={{
        ...current,
        applications: data,
        error,
        isError,
        isLoading,
      }}
    >
      {children}
    </DapiContext.Provider>
  );
}

export function useDapiContext() {
  return useContext(DapiContext);
}
