import { IconProps } from '@/app/components/Icon';

export function ConnectionsIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <circle
            cx='5'
            cy='5'
            r='2.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M4.75 7.28125V8.25C4.75 9.35457 5.64543 10.25 6.75 10.25H10M10 10.25V12.5M10 10.25H13.25C14.3546 10.25 15.25 9.35457 15.25 8.25V7.28125' />
          <circle
            cx='15'
            cy='5'
            r='2.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <circle
            cx='10'
            cy='15'
            r='2.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <circle cx='4' cy='4' r='1.25' />
          <circle cx='12' cy='4' r='1.25' />
          <circle cx='8' cy='12' r='1.25' />
          <path d='M8 8.25V10.25M8 8.25H6C4.89543 8.25 4 7.35457 4 6.25V5.5M8 8.25H10C11.1046 8.25 12 7.35457 12 6.25V5.5' />
        </>
      )}
    </>
  );
}
