import React from 'react';

const FeatureRequestIcon = () => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
    >
      <g clipPath='url(#clip0_695_2329)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.166 29.25C24.5884 29.25 31.416 23.0939 31.416 15.5C31.416 7.90608 24.5884 1.75 16.166 1.75C7.74367 1.75 0.916016 7.90608 0.916016 15.5C0.916016 18.1379 1.73987 20.6023 3.16824 22.6954C4.02129 23.9454 4.45314 25.5321 3.62994 26.802C3.2394 27.4045 2.80714 27.9651 2.38381 28.4575C1.83536 29.0954 2.15549 30.1443 2.99481 30.2015C5.11837 30.3461 7.6382 30.1939 9.77001 29.3822C10.8569 28.9685 12.0431 28.7836 13.1879 28.9879C14.1511 29.1599 15.1469 29.25 16.166 29.25Z'
          fill='#EEEEF0'
        />
        <path d='M2.38381 28.4575L1.8151 27.9685L1.8151 27.9685L2.38381 28.4575ZM2.99481 30.2015L3.04578 29.4532L3.04578 29.4532L2.99481 30.2015ZM9.77001 29.3822L9.50315 28.6813L9.77001 29.3822ZM13.1879 28.9879L13.0562 29.7263L13.1879 28.9879ZM3.16824 22.6954L2.54874 23.1181L3.16824 22.6954ZM3.62994 26.802L3.0006 26.3941L3.62994 26.802ZM30.666 15.5C30.666 22.6085 24.2491 28.5 16.166 28.5V30C24.9276 30 32.166 23.5793 32.166 15.5H30.666ZM16.166 2.5C24.2491 2.5 30.666 8.39146 30.666 15.5H32.166C32.166 7.42071 24.9276 1 16.166 1V2.5ZM1.66602 15.5C1.66602 8.39146 8.08295 2.5 16.166 2.5V1C7.4044 1 0.166016 7.42071 0.166016 15.5H1.66602ZM3.78774 22.2726C2.43908 20.2963 1.66602 17.9779 1.66602 15.5H0.166016C0.166016 18.2979 1.04066 20.9082 2.54874 23.1181L3.78774 22.2726ZM2.95252 28.9464C3.39406 28.4329 3.84746 27.8453 4.25928 27.21L3.0006 26.3941C2.63133 26.9637 2.22021 27.4973 1.8151 27.9685L2.95252 28.9464ZM3.04578 29.4532C2.99453 29.4497 2.96726 29.4342 2.94855 29.4186C2.92592 29.3997 2.89876 29.3651 2.8804 29.31C2.84152 29.1934 2.86222 29.0515 2.95252 28.9464L1.8151 27.9685C1.35695 28.5014 1.26349 29.2029 1.4574 29.7845C1.65562 30.379 2.17786 30.8976 2.94384 30.9497L3.04578 29.4532ZM9.50315 28.6813C7.51029 29.4401 5.11036 29.5938 3.04578 29.4532L2.94384 30.9497C5.12639 31.0984 7.76611 30.9477 10.0369 30.0832L9.50315 28.6813ZM16.166 28.5C15.1911 28.5 14.2395 28.4138 13.3197 28.2496L13.0562 29.7263C14.0627 29.9059 15.1026 30 16.166 30V28.5ZM10.0369 30.0832C11.029 29.7054 12.0755 29.5512 13.0562 29.7263L13.3197 28.2496C12.0107 28.016 10.6847 28.2315 9.50315 28.6813L10.0369 30.0832ZM2.54874 23.1181C3.33899 24.2762 3.57591 25.5066 3.0006 26.3941L4.25928 27.21C5.33037 25.5577 4.70359 23.6147 3.78774 22.2726L2.54874 23.1181Z' />
        <circle
          cx='9.16602'
          cy='15.5'
          r='1.75'
          fillOpacity={0.15}
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle
          cx='16.166'
          cy='15.5'
          r='1.75'
          fillOpacity={0.15}
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle
          cx='23.166'
          cy='15.5'
          r='1.75'
          fillOpacity={0.15}
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_695_2329'>
          <rect
            width='32'
            height='32'
            fill='white'
            transform='translate(0.166016)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FeatureRequestIcon;
