import { IconProps } from '@/app/components/Icon';

export function BookIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M17.25 4.75V14.25C17.25 14.8023 16.8023 15.25 16.25 15.25H14.1359C13.3836 15.25 12.6361 15.3713 11.9223 15.6092L10 16.25L8.07765 15.6092C7.36389 15.3713 6.61643 15.25 5.86406 15.25H3.75C3.19772 15.25 2.75 14.8023 2.75 14.25V4.75C2.75 4.19772 3.19772 3.75 3.75 3.75H3.8C5.91242 3.75 8.00392 4.1683 9.95385 4.98077L10 5L10.0462 4.98077C11.9961 4.1683 14.0876 3.75 16.2 3.75H16.25C16.8023 3.75 17.25 4.19772 17.25 4.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M10 5V16.25M10 5L9.95385 4.98077C8.00392 4.1683 5.91242 3.75 3.8 3.75H3.75C3.19772 3.75 2.75 4.19772 2.75 4.75V14.25C2.75 14.8023 3.19772 15.25 3.75 15.25H5.86406C6.61643 15.25 7.36389 15.3713 8.07765 15.6092L10 16.25M10 5L10.0462 4.98077C11.9961 4.1683 14.0876 3.75 16.2 3.75H16.25C16.8023 3.75 17.25 4.19772 17.25 4.75V14.25C17.25 14.8023 16.8023 15.25 16.25 15.25H14.1359C13.3836 15.25 12.6361 15.3713 11.9223 15.6092L10 16.25' />
        </>
      ) : (
        <>
          <path d='M8 3.8L2.75 2.75V12.2L8 13.25M8 3.8L13.25 2.75V12.2L8 13.25M8 3.8V13.25' />
        </>
      )}
    </>
  );
}
