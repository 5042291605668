import { IconProps } from '@/app/components/Icon';

export function DropboxIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          d='M10.0116 5.22622L5.50582 8.10062L10.0116 10.9751L5.50582 13.8494L1 10.9595L5.50582 8.08509L1 5.22622L5.50582 2.35181L10.0116 5.22622ZM5.48252 14.7739L9.98834 11.8995L14.4941 14.7739L9.98834 17.6483L5.48252 14.7739ZM10.0116 10.9595L14.5175 8.08509L10.0116 5.22622L14.4941 2.35181L19 5.22622L14.4941 8.10062L19 10.9751L14.4941 13.8494L10.0116 10.9595Z'
          fill='#0061FF'
          stroke='none'
        />
      ) : (
        <path
          d='M8.00903 4.28704L4.50453 6.52269L8.00903 8.75837L4.50453 10.994L1 8.74626L4.50453 6.51061L1 4.28704L4.50453 2.05139L8.00903 4.28704ZM4.48641 11.713L7.99093 9.47734L11.4954 11.713L7.99093 13.9487L4.48641 11.713ZM8.00903 8.74626L11.5136 6.51061L8.00903 4.28704L11.4954 2.05139L15 4.28704L11.4954 6.52269L15 8.75837L11.4954 10.994L8.00903 8.74626Z'
          fill='#0061FF'
          stroke='none'
        />
      )}
    </>
  );
}
