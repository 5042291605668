import { IconProps } from '@/app/components/Icon';

export function BoxLogoIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <path
          d='M18.8413 13.7705C19.066 14.085 19.021 14.4895 18.7514 14.7142C18.4368 14.9389 17.9874 14.894 17.7627 14.6244L16.1898 12.602L14.6618 14.5794C14.4371 14.894 13.9877 14.894 13.6732 14.6693C13.3585 14.4446 13.3136 14.0401 13.5383 13.7255L15.3359 11.3886L13.5383 9.05176C13.3136 8.73718 13.4035 8.28778 13.6732 8.06307C13.9877 7.83838 14.4371 7.92826 14.6618 8.1979L16.1898 10.2202L17.7627 8.28778C17.9874 7.9732 18.3919 7.92826 18.7514 8.15296C19.066 8.37766 19.066 8.82706 18.8413 9.14164L17.0886 11.4336L18.8413 13.7705ZM10.6621 13.5008C9.4937 13.5008 8.54996 12.602 8.54996 11.4336C8.54996 10.3101 9.4937 9.36635 10.6621 9.36635C11.8306 9.36635 12.7743 10.3101 12.7743 11.4336C12.7294 12.602 11.7857 13.5008 10.6621 13.5008ZM4.4604 13.5008C3.29195 13.5008 2.3482 12.602 2.3482 11.4336C2.3482 10.3101 3.29195 9.36635 4.4604 9.36635C5.62884 9.36635 6.57258 10.3101 6.57258 11.4336C6.57258 12.602 5.62884 13.5008 4.4604 13.5008ZM10.6621 8.01814C9.35888 8.01814 8.19044 8.73718 7.60621 9.81575C7.02199 8.73718 5.85355 8.01814 4.50534 8.01814C3.69641 8.01814 2.97737 8.28778 2.39315 8.69224V5.81607C2.39315 5.45655 2.07857 5.14197 1.71904 5.14197C1.31458 5.14197 1 5.45655 1 5.81607V11.4785C1.04494 13.366 2.57291 14.8491 4.4604 14.8491C5.8086 14.8491 6.97704 14.085 7.56127 13.0065C8.1455 14.085 9.31394 14.8491 10.6172 14.8491C12.5496 14.8491 14.1226 13.3211 14.1226 11.3886C14.1675 9.5461 12.5946 8.01814 10.6621 8.01814Z'
          fill='#0061D5'
          stroke='none'
        />
      ) : (
        <>
          <g clipPath='url(#clip0_4321_486)'>
            <path
              d='M15.8589 11.3515C16.0587 11.6311 16.0187 11.9907 15.779 12.1904C15.4994 12.3902 15.0999 12.3502 14.9002 12.1106L13.502 10.3129L12.1439 12.0706C11.9441 12.3502 11.5446 12.3502 11.265 12.1505C10.9854 11.9508 10.9454 11.5912 11.1452 11.3116L12.7431 9.23437L11.1452 7.15713C10.9454 6.87751 11.0254 6.47804 11.265 6.2783C11.5446 6.07857 11.9441 6.15847 12.1439 6.39814L13.502 8.19576L14.9002 6.47804C15.0999 6.19841 15.4595 6.15847 15.779 6.3582C16.0587 6.55793 16.0587 6.9574 15.8589 7.23703L14.301 9.2743L15.8589 11.3515ZM8.58858 11.1119C7.54996 11.1119 6.71107 10.3129 6.71107 9.2743C6.71107 8.27565 7.54996 7.43677 8.58858 7.43677C9.6272 7.43677 10.4661 8.27565 10.4661 9.2743C10.4262 10.3129 9.58727 11.1119 8.58858 11.1119ZM3.07591 11.1119C2.03729 11.1119 1.1984 10.3129 1.1984 9.2743C1.1984 8.27565 2.03729 7.43677 3.07591 7.43677C4.11452 7.43677 4.95341 8.27565 4.95341 9.2743C4.95341 10.3129 4.11452 11.1119 3.07591 11.1119ZM8.58858 6.23836C7.43011 6.23836 6.3915 6.87751 5.87219 7.83623C5.35288 6.87751 4.31426 6.23836 3.11586 6.23836C2.39681 6.23836 1.75766 6.47804 1.23835 6.83756V4.28096C1.23835 3.96139 0.958727 3.68176 0.63915 3.68176C0.279628 3.68176 0 3.96139 0 4.28096V9.31423C0.0399468 10.992 1.39814 12.3103 3.07591 12.3103C4.27432 12.3103 5.31293 11.6311 5.83224 10.6725C6.35155 11.6311 7.39017 12.3103 8.54865 12.3103C10.2663 12.3103 11.6645 10.9521 11.6645 9.23437C11.7044 7.59655 10.3063 6.23836 8.58858 6.23836Z'
              fill='#0061D5'
              stroke='none'
            />
          </g>
          <defs>
            <clipPath id='clip0_4321_486'>
              <rect width='16' height='16' fill='white' />
            </clipPath>
          </defs>
        </>
      )}
    </>
  );
}
