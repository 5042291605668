import { IconProps } from '@/app/components/Icon';

export function LockSlashIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M3.75 15.25V9.5C3.75 9.08579 4.08579 8.75 4.5 8.75H4.75L13.25 17.25H5.75C4.64543 17.25 3.75 16.3546 3.75 15.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M16.25 9.75V15.25C16.25 15.5435 16.1868 15.8222 16.0732 16.0732L8.75 8.75H15.25C15.8023 8.75 16.25 9.19772 16.25 9.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M2.75 2.75L8.75 8.75M17.25 17.25L16.0732 16.0732M8.75 8.75H15.25C15.8023 8.75 16.25 9.19772 16.25 9.75V15.25C16.25 15.5435 16.1868 15.8222 16.0732 16.0732M8.75 8.75L16.0732 16.0732' />
          <path d='M14.25 8.5V7C14.25 4.65279 12.3472 2.75 10 2.75C8.03499 2.75 6.38145 4.08358 5.89505 5.89505M2.75 2.75L5.89505 5.89505M17.25 17.25L5.89505 5.89505' />
        </>
      ) : (
        <>
          <path d='M3.75 7.75V11.25C3.75 12.3546 4.64543 13.25 5.75 13.25H9.25M10.75 7.75H12.25V11.25C12.25 11.5435 12.1868 11.8222 12.0732 12.0732M10.75 7.75V5.5C10.75 3.98122 9.51878 2.75 8 2.75C6.56198 2.75 5.38175 3.85376 5.2603 5.2603M10.75 7.75H7.75M2.75 2.75L5.2603 5.2603M13.25 13.25L12.0732 12.0732M5.2603 5.2603L7.75 7.75M12.0732 12.0732L7.75 7.75' />
        </>
      )}
    </>
  );
}
