import { IconProps } from '@/app/components/Icon';

export function MetamaskIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <g stroke='none'>
          <path
            d='M18.1088 1.72107L11.0847 6.89241L12.3863 3.84355L18.1088 1.72107Z'
            fill='#E27625'
          />
          <path
            d='M1.89111 1.72107L8.85659 6.93931L7.61359 3.84355L1.89111 1.72107Z'
            fill='#E27625'
          />
          <path
            d='M15.5876 13.7172L13.7114 16.5549L17.7218 17.6572L18.871 13.7758L15.5876 13.7172Z'
            fill='#E27625'
          />
          <path
            d='M1.15247 13.7758L2.28992 17.6572L6.28862 16.5549L4.42413 13.7172L1.15247 13.7758Z'
            fill='#E27625'
          />
          <path
            d='M6.07751 8.90939L4.9635 10.5863L8.92702 10.7622L8.79803 6.51721L6.07751 8.90939Z'
            fill='#E27625'
          />
          <path
            d='M13.9342 8.9093L11.1667 6.47021L11.0847 10.7621L15.0482 10.5862L13.9342 8.9093Z'
            fill='#E27625'
          />
          <path
            d='M6.28857 16.555L8.69248 15.4058L6.61691 13.811L6.28857 16.555Z'
            fill='#E27625'
          />
          <path
            d='M11.3193 15.4058L13.7115 16.555L13.3831 13.811L11.3193 15.4058Z'
            fill='#E27625'
          />
          <path
            d='M13.7115 16.5549L11.3193 15.4058L11.5186 16.9536L11.4952 17.6103L13.7115 16.5549Z'
            fill='#D7C1B3'
          />
          <path
            d='M6.28857 16.5549L8.51659 17.6103L8.50486 16.9536L8.69248 15.4058L6.28857 16.5549Z'
            fill='#D7C1B3'
          />
          <path
            d='M8.55177 12.7791L6.57001 12.2045L7.97717 11.5596L8.55177 12.7791Z'
            fill='#2F343B'
          />
          <path
            d='M11.4482 12.7791L12.0346 11.5596L13.4417 12.2045L11.4482 12.7791Z'
            fill='#2F343B'
          />
          <path
            d='M6.28863 16.5549L6.64042 13.7172L4.42413 13.7758L6.28863 16.5549Z'
            fill='#CC6228'
          />
          <path
            d='M13.3713 13.7172L13.7113 16.5549L15.5876 13.7758L13.3713 13.7172Z'
            fill='#CC6228'
          />
          <path
            d='M15.0482 10.5863L11.0847 10.7622L11.4482 12.7791L12.0345 11.5596L13.4417 12.2045L15.0482 10.5863Z'
            fill='#CC6228'
          />
          <path
            d='M6.57002 12.2045L7.97718 11.5596L8.55177 12.7791L8.92702 10.7622L4.9635 10.5863L6.57002 12.2045Z'
            fill='#CC6228'
          />
          <path
            d='M4.9635 10.5863L6.61692 13.8111L6.57002 12.2045L4.9635 10.5863Z'
            fill='#E27625'
          />
          <path
            d='M13.4417 12.2045L13.3831 13.8111L15.0482 10.5863L13.4417 12.2045Z'
            fill='#E27625'
          />
          <path
            d='M8.927 10.7621L8.55176 12.779L9.02081 15.1712L9.12635 12.0285L8.927 10.7621Z'
            fill='#E27625'
          />
          <path
            d='M11.0847 10.7621L10.8853 12.0168L10.9791 15.1712L11.4482 12.779L11.0847 10.7621Z'
            fill='#E27625'
          />
          <path
            d='M11.4482 12.7791L10.9792 15.1712L11.3193 15.4058L13.3831 13.811L13.4417 12.2045L11.4482 12.7791Z'
            fill='#F5841F'
          />
          <path
            d='M6.57001 12.2045L6.61691 13.811L8.69248 15.4058L9.02082 15.1712L8.55177 12.7791L6.57001 12.2045Z'
            fill='#F5841F'
          />
          <path
            d='M11.4951 17.6103L11.5185 16.9536L11.3309 16.8012H8.66903L8.50486 16.9536L8.51659 17.6103L6.28857 16.5549L7.06252 17.1882L8.64558 18.2787H11.3544L12.9374 17.1882L13.7114 16.5549L11.4951 17.6103Z'
            fill='#C0AD9E'
          />
          <path
            d='M11.3192 15.4058L10.9791 15.1713H9.02078L8.69244 15.4058L8.50482 16.9537L8.66899 16.8012H11.3309L11.5185 16.9537L11.3192 15.4058Z'
            fill='#2F343B'
          />
          <path
            d='M18.4137 7.23247L19.0001 4.37123L18.1089 1.72107L11.3193 6.71651L13.9343 8.90934L17.6281 9.98817L18.4372 9.03833L18.0854 8.78035L18.6483 8.27612L18.2144 7.93605L18.7773 7.5139L18.4137 7.23247Z'
            fill='#763E1A'
          />
          <path
            d='M1 4.37135L1.59805 7.23259L1.2228 7.51403L1.78567 7.9479L1.35179 8.27624L1.91466 8.78048L1.56287 9.03846L2.38371 9.98829L6.07752 8.90947L8.69251 6.71663L1.89121 1.72119L1 4.37135Z'
            fill='#763E1A'
          />
          <path
            d='M17.628 9.98825L13.9342 8.90942L15.0482 10.5863L13.3831 13.8111L15.5876 13.7759H18.871L17.628 9.98825Z'
            fill='#F5841F'
          />
          <path
            d='M6.07755 8.90942L2.38374 9.98825L1.15247 13.7759H4.42413L6.61696 13.8111L4.96354 10.5863L6.07755 8.90942Z'
            fill='#F5841F'
          />
          <path
            d='M11.0847 10.7622L11.3192 6.71659L12.3863 3.84363H7.61371L8.69254 6.71659L8.92706 10.7622L9.02087 12.0286V15.1713H10.9792L10.9909 12.0286L11.0847 10.7622Z'
            fill='#F5841F'
          />
        </g>
      ) : (
        <g stroke='none'>
          <path
            d='M14.3068 1.56091L8.84363 5.58306L9.85601 3.21173L14.3068 1.56091Z'
            fill='#E27625'
          />
          <path
            d='M1.69312 1.56091L7.1107 5.61955L6.14393 3.21173L1.69312 1.56091Z'
            fill='#E27625'
          />
          <path
            d='M12.3459 10.8912L10.8867 13.0984L14.0059 13.9557L14.8997 10.9368L12.3459 10.8912Z'
            fill='#E27625'
          />
          <path
            d='M1.11865 10.9368L2.00334 13.9557L5.11344 13.0984L3.66328 10.8912L1.11865 10.9368Z'
            fill='#E27625'
          />
          <path
            d='M4.94915 7.15172L4.0827 8.45596L7.16544 8.59277L7.06511 5.29114L4.94915 7.15172Z'
            fill='#E27625'
          />
          <path
            d='M11.0599 7.15171L8.90747 5.25464L8.84363 8.59275L11.9264 8.45594L11.0599 7.15171Z'
            fill='#E27625'
          />
          <path
            d='M5.11334 13.0983L6.98305 12.2045L5.36872 10.9641L5.11334 13.0983Z'
            fill='#E27625'
          />
          <path
            d='M9.02606 12.2045L10.8866 13.0983L10.6313 10.9641L9.02606 12.2045Z'
            fill='#E27625'
          />
          <path
            d='M10.8866 13.0983L9.02606 12.2045L9.18111 13.4084L9.16287 13.9191L10.8866 13.0983Z'
            fill='#D7C1B3'
          />
          <path
            d='M5.11334 13.0983L6.84624 13.9191L6.83712 13.4084L6.98305 12.2045L5.11334 13.0983Z'
            fill='#D7C1B3'
          />
          <path
            d='M6.87358 10.1615L5.33221 9.71464L6.42668 9.21301L6.87358 10.1615Z'
            fill='#2F343B'
          />
          <path
            d='M9.12634 10.1615L9.58237 9.21301L10.6768 9.71464L9.12634 10.1615Z'
            fill='#2F343B'
          />
          <path
            d='M5.11331 13.0984L5.38693 10.8912L3.66315 10.9368L5.11331 13.0984Z'
            fill='#CC6228'
          />
          <path
            d='M10.6221 10.8912L10.8866 13.0984L12.3459 10.9368L10.6221 10.8912Z'
            fill='#CC6228'
          />
          <path
            d='M11.9264 8.45605L8.84363 8.59286L9.12636 10.1616L9.58239 9.21306L10.6769 9.71469L11.9264 8.45605Z'
            fill='#CC6228'
          />
          <path
            d='M5.33221 9.71469L6.42668 9.21306L6.87358 10.1616L7.16544 8.59286L4.0827 8.45605L5.33221 9.71469Z'
            fill='#CC6228'
          />
          <path
            d='M4.0827 8.45605L5.3687 10.9642L5.33221 9.71469L4.0827 8.45605Z'
            fill='#E27625'
          />
          <path
            d='M10.6769 9.71469L10.6313 10.9642L11.9264 8.45605L10.6769 9.71469Z'
            fill='#E27625'
          />
          <path
            d='M7.16551 8.59277L6.87366 10.1615L7.23848 12.0221L7.32056 9.57779L7.16551 8.59277Z'
            fill='#E27625'
          />
          <path
            d='M8.84365 8.59277L8.6886 9.56867L8.76156 12.0221L9.12638 10.1615L8.84365 8.59277Z'
            fill='#E27625'
          />
          <path
            d='M9.12642 10.1615L8.7616 12.0221L9.02609 12.2045L10.6313 10.9641L10.6769 9.7146L9.12642 10.1615Z'
            fill='#F5841F'
          />
          <path
            d='M5.33221 9.7146L5.3687 10.9641L6.98303 12.2045L7.2384 12.0221L6.87358 10.1615L5.33221 9.7146Z'
            fill='#F5841F'
          />
          <path
            d='M9.16285 13.9191L9.18109 13.4084L9.03517 13.2898H6.96481L6.83712 13.4084L6.84624 13.9191L5.11334 13.0983L5.7153 13.5908L6.94657 14.439H9.05341L10.2847 13.5908L10.8866 13.0983L9.16285 13.9191Z'
            fill='#C0AD9E'
          />
          <path
            d='M9.02602 12.2045L8.76153 12.0221H7.2384L6.98303 12.2045L6.8371 13.4084L6.96478 13.2898H9.03514L9.18107 13.4084L9.02602 12.2045Z'
            fill='#2F343B'
          />
          <path
            d='M14.544 5.84756L15 3.62215L14.3068 1.56091L9.02606 5.44625L11.0599 7.15179L13.9329 7.99088L14.5622 7.25212L14.2886 7.05147L14.7264 6.65928L14.3889 6.39479L14.8267 6.06645L14.544 5.84756Z'
            fill='#763E1A'
          />
          <path
            d='M1 3.62215L1.46515 5.84756L1.17329 6.06645L1.61107 6.40391L1.27362 6.65928L1.7114 7.05147L1.43778 7.25212L2.07622 7.99088L4.94919 7.15179L6.98306 5.44625L1.69316 1.56091L1 3.62215Z'
            fill='#763E1A'
          />
          <path
            d='M13.9329 7.99082L11.06 7.15173L11.9264 8.45597L10.6313 10.9641L12.3459 10.9367H14.8997L13.9329 7.99082Z'
            fill='#F5841F'
          />
          <path
            d='M4.94927 7.15173L2.07631 7.99082L1.11865 10.9367H3.66328L5.36882 10.9641L4.08282 8.45597L4.94927 7.15173Z'
            fill='#F5841F'
          />
          <path
            d='M8.84366 8.59278L9.02607 5.4462L9.85603 3.21167H6.14398L6.98307 5.4462L7.16548 8.59278L7.23844 9.57779V12.0221H8.76157L8.77069 9.57779L8.84366 8.59278Z'
            fill='#F5841F'
          />
        </g>
      )}
    </>
  );
}
